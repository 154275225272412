import { Injectable } from '@angular/core';
import moment from 'moment-timezone';
import { TimezonePayload } from '../interfaces/date-time.interface';
import { constants } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {

    getTimeZoneList(usSpecificTimezoneOnly: boolean): Array<TimezonePayload> {
        let timeZoneList: Array<TimezonePayload> = [];
        let specificTimezone: Array<string>  = constants.timezones;
        moment.tz.names().forEach(function (x) {
            if (x.indexOf('US/') > -1 || !usSpecificTimezoneOnly) {
                if (specificTimezone.indexOf(x) < 0) {
                    timeZoneList.push({
                        id: x + '[' + parseInt(moment().tz(x).format('Z')) + ']',
                        name: x + ' (UTC ' + moment().tz(x).format('Z') + ')',
                        val: x,
                        offset: moment.tz(x).utcOffset(),

                    });
                }
            }
        });

        const transformedTimeZone = specificTimezone.map((zone) => {
            return {
                id: zone + '[' + parseInt(moment().tz(zone).format('Z')) + ']',
                name: zone + ' (UTC ' + moment().tz(zone).format('Z') + ')',
                val: zone,
                offset: moment.tz(zone).utcOffset(),
            };
        });
        timeZoneList = transformedTimeZone.concat(timeZoneList);
        return timeZoneList;
    }

    getCurrentTimezone(): string{
        return moment.tz.guess();
    }
}
