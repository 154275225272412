<div class="mb-5" [ngStyle]="{ 'float': 'right' }">
    <p-button (click)="approveReviewer($event, config.data.reviewer)" [rounded]="true" label="Approve Candidate"></p-button>
</div>
<div class="projects-data" [ngStyle]="{ 'clear': 'both' }">
    <div class="grid grid-nogutter">
        <div class="col-12 p-0">
            <div class="data-table">
                <p-table styleClass="p-datatable-sm p-datatable-sm--normal"
                [value]="credentials" 
                [showCurrentPageReport]="true" 
                [tableStyle]="{ 'min-width': '50rem' }"
                    autoLayout="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>S.No</th>
                            <th>Degree / Description</th>
                            <th>ID / Member No</th>
                            <th>Institution / Certification</th>
                            <th>Type</th>
                            <th>Issue Date</th>
                            <th>Expiration Date</th>
                            <th>Auto Renewal</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-credential let-rowIndex="rowIndex">
                        <tr>
                            <td>{{rowIndex + 1}}</td>
                            <td>{{credential.description || '-'}}</td>
                            <td>{{credential.code || '-'}}</td>
                            <td>{{credential.school || '-'}}</td>
                            <td>{{credential.type}}</td>
                            <td>{{credential.issuedAt ? (credential.issuedAt | date : 'MMM d, y h:mm a' ):'-'}}</td>
                            <td>{{credential.expiresAt ? (credential.expiresAt | date : 'MMM d, y h:mm a' ):'-'}}</td>
                            <td>{{credential.autoRenewal ? 'Yes' : 'No'}}</td>
                            <td>{{credential.status}}</td>
                            <td>
                                <div class="wrap-actions">
                                   @if(credential.type === 'EDUCATION' && config.data.reviewer.permissions.education && config.data.reviewer.tenants.length && config.data.reviewer.tenants[0].firm && config.data.reviewer.tenants[0].firm.isNscEnabled){
                                        <div>
                                            <p-button (onClick)="backgroundCheck('nsc',credential )" [rounded]="true" [text]="true" icon="true">
                                                <img  src="assets/images/nsc-icon.png" class="acc-nsc-check-icon" alt="Image" height="90" />
                                            </p-button>
                                        </div>
                                   } 
                                    
                                        @if(credential.downloadURL){
                                            <p-button (onClick)="downloadDocument(credential.downloadURL)" [rounded]="true" [text]="true" icon="true">
                                                <span class="material-symbols-outlined">
                                                    download
                                                </span>
                                            </p-button>
                                        }
                                        @if(credential.certificate){
                                            <p-button (onClick)="downloadDocument(credential.certificate)" [rounded]="true" [text]="true" icon="true">
                                                <span class="material-symbols-outlined">
                                                    download
                                                </span>
                                            </p-button>
                                        }
                                      
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>