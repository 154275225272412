<div class="grid grid-nogutter">
    <div class="p-0 col-12 mb-5">
        <h3 class="m-0 h4 mb-2">Invoice Reports</h3>
    </div>
    <div class="mb-3 xl:col-3 lg:col-3 col-12 lg:pr-3 xl:pr-3 pr-0">
        <p-multiSelect [options]="projects" [(ngModel)]="selectedProjects" optionLabel="codeName" placeholder="Project" (onChange)="onProjectSelectionChange($event)" [ngClass]="{'ng-dirty ng-invalid': showProjectSelectionError}" />
        @if(showProjectSelectionError){
            <span class="p-error pl-1 text-xs">This is required</span>
        }
    </div>
    <div class="mb-3 xl:col-3 lg:col-3 col-12 lg:pr-3 xl:pr-3 pr-0">
        <p-multiSelect [options]="roles" [(ngModel)]="selectedRoles" optionLabel="roleType" placeholder="Role" (onChange)="onRolesSelectionChange($event)" />
    </div>
    <div class="mb-3 xl:col-3 lg:col-3 col-12 lg:pr-3 xl:pr-3 pr-0">
        <p-multiSelect [options]="reviewers" [(ngModel)]="selectedReviewers" optionLabel="fullName" placeholder="Candidate" />
    </div>
    <div class="mb-3 xl:col-3 lg:col-3 col-12 lg:pr-0 xl:pr-0 pr-0">
        <p-floatLabel>
            <p-multiSelect 
                inputId="float-label" 
                [options]="moreFilters" 
                [(ngModel)]="selectedMoreFilters" 
                optionLabel="label" />
            <label for="float-label">More Filters</label>
        </p-floatLabel>
    </div>
    <div class="mb-3 xl:col-3 lg:col-3 col-12 lg:pr-3 xl:pr-3 pr-0">
        <p-dropdown [options]="timezones" [(ngModel)]="selectedTimezone" optionLabel="val" [placeholder]="isDefaultTimeZone ? 'Default Timezone' : 'Timezone'" [disabled]="isDefaultTimeZone"  />
    </div>
    <div class="mb-3 xl:col-3 lg:col-4 col-12 lg:pr-3 xl:pr-3 pr-0 flex align-items-center gap-2">
        <p-checkbox 
            [(ngModel)]="isDefaultTimeZone" 
            [binary]="true" 
            inputId="default-tz"
            (onChange)="onChangeDefaultTimezoneCheck($event)" />
        <label for="default-tz">Use Default Timezone</label>
    </div>
    <div class="mb-3 xl:col-3 lg:col-5 col-12 lg:pr-0 xl:pr-0 pr-0">
        <div class="date-slider flex align-items-center justify-content-between">
            <p-button type="button" pRipple [text]="true" [rounded]="true" [plain]="true" icon="pi pi-chevron-left"
                class="row-toggler" (click)="slider(false)" />
            <span>
                {{getFormattedDateRange()}}
            </span>
            <p-button type="button" pRipple [text]="true" [rounded]="true" [plain]="true" icon="pi pi-chevron-right"
                class="row-toggler" (click)="slider(true)" />
        </div>
    </div>
    <div class="mb-3 ml-0 lg:ml-auto xl:col-3 lg:col-3 col-12 lg:pr-3 xl:pr-0 pr-0 flex justify-content-start">
        <p-button label="SEARCH" [rounded]="true" size="large" (click)="generateReport()" />
    </div>
    
    <div class="p-0 col-12 mt-4">
        <p-table styleClass="p-datatable-sm p-datatable-sm--normal" [value]="invoiceReports" [paginator]="true" [rows]="2" [tableStyle]="{ 'min-width': '50rem' }"
            [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
            autoLayout="true" [rows]="noOfRows">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="firstName">
                        First Name <p-sortIcon field="firstName" />
                    </th>
                    <th pSortableColumn="lastName">
                        Last Name <p-sortIcon field="lastName" />
                    </th>
                    <th pSortableColumn="projectName">
                        Project <p-sortIcon field="projectName" />
                    </th>
                    <th pSortableColumn="roleName">
                        Role <p-sortIcon field="roleName" />
                    </th>
                    <th pSortableColumn="totalHours">
                        Total Hours <p-sortIcon field="totalHours" />
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-invoiceReport>
                <tr>
                    <td>{{ invoiceReport.firstName }}</td>
                    <td>{{ invoiceReport.lastName }}</td>
                    <td>{{ invoiceReport.projectName }}</td>
                    <td>{{ invoiceReport.roleName }}</td>
                    <td>{{ invoiceReport.totalHours }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>