import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { UserService } from '../../../../../shared/services/user.service';
import { TableModule } from 'primeng/table';
import { FloatLabelModule } from 'primeng/floatlabel';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeadingComponent } from '../../../../../shared/components/heading/heading.component';
import { InputIconModule } from 'primeng/inputicon';
import { IconFieldModule } from 'primeng/iconfield';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
 DynamicDialogModule } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { DynamicTableComponent } from './dynamic-table/dynamic-table.component';
import { DynamicCredentialService } from '../../../../../shared/services/dynamic-credential.service';
import { constants } from '../../../../../shared/constants/constants';
import { DynamicCredential } from '../../../../../shared/interfaces/settings.interface';

@Component({
  selector: 'app-dynamic-credential',
  standalone: true,
  imports: [
    CheckboxModule,
    FloatLabelModule,
    DialogModule,
    ButtonModule,
    FormsModule,
    IconFieldModule,
    InputIconModule,
    HeadingComponent,
    InputTextModule,
    TableModule,
    CommonModule,
    ReactiveFormsModule,
    DynamicDialogModule,
    DynamicTableComponent,
  ],
  providers: [DialogService, DynamicDialogRef, DynamicDialogConfig],
  templateUrl: './dynamic-credential.component.html',
  styleUrl: './dynamic-credential.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class DynamicCredentialComponent implements OnInit {
  dynamicCredential!: DynamicCredential[];
  dynamicCredentialTitle: string = constants.dynamicCredential.title;

  ref: DynamicDialogRef | undefined;
  userFirmId!: string;
  
  constructor(
    private userService: UserService,
    public dialogService: DialogService,
    private router: Router,
    public config: DynamicDialogConfig,
    public dynamicCredentialService: DynamicCredentialService
  ) {}

  ngOnInit(): void  {
    this.userFirmId = this.userService.getUserFirmId();
    this.getDynamicCredential();
  }

  getDynamicCredential(): void {
    this.dynamicCredentialService
      .getFirmDynamicCredential(this.userFirmId)
      .subscribe({
        next: (dynamicCred: {list : DynamicCredential[]}) => {
          this.dynamicCredential = dynamicCred.list;
        },
      });
  }

  handleRefresh(): void  {
    this.getDynamicCredential();
  }

  goToCreate(): void  {
    this.router.navigateByUrl(
      'client/settings/dynamic-credential/create'
    );
  }

}