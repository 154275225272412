import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ChipModule } from 'primeng/chip';
import { IconFieldModule } from 'primeng/iconfield';
import { InputTextModule } from 'primeng/inputtext';
import { InputIconModule } from 'primeng/inputicon';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { ReportService } from '../reports.service';
import { StorageService } from '../../../../../shared/services/storage.service';
import { GridInterface, PayrollFilterPayload } from '../interfaces/reports.interface';
import { BaseReport } from '../base-class/base-report.class';
import { MultiSelectModule } from 'primeng/multiselect';
import moment from 'moment';
import { DateTimeService } from '../../../../../shared/services/date-time.service';
import { CalendarModule } from 'primeng/calendar';
import { FloatLabelModule } from 'primeng/floatlabel';
import { CheckboxModule } from 'primeng/checkbox';
import { UserService } from '../../../../../shared/services/user.service';

@Component({
  selector: 'app-payroll',
  standalone: true,
  imports: [DropdownModule, ButtonModule, CheckboxModule, FormsModule, CalendarModule, FloatLabelModule, ChipModule, IconFieldModule,InputIconModule,InputTextModule,TableModule, CommonModule, MultiSelectModule ],
  providers: [DateTimeService],
  templateUrl: './payroll.component.html',
  styleUrl: './payroll.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class PayrollComponent extends BaseReport implements OnInit {
  payrollReports: Array<GridInterface> = [];
  
  showProjectSelectionError = false;
  
  constructor(
    override reportService: ReportService,
    override storageService: StorageService,
    override dateTimeService: DateTimeService,
    override userService: UserService
  ){
    super(reportService, storageService, dateTimeService, userService);
  }

  ngOnInit() {
    this.initializeReport();
  }

  override async onProjectSelectionChange(e: any): Promise<void>{
    await super.onProjectSelectionChange(e);
    this.showProjectSelectionError = false;
  }

  generateReport(): void{
    if(!this.selectedProjects.length){
      this.showProjectSelectionError = true;
      return;
    }
    const filters: PayrollFilterPayload = {
      emails: '',
      firm: this.sessionUser.firm._id,
      fromDate: moment(this.startDate).format('MM/DD/YYYY'),
      toDate: moment(this.endDate).format('MM/DD/YYYY'),
      isFetchAllRecords: false,
      isSentEmail: false,
      project: this.selectedProjects.map(project => project.id),
      selectedProj: [...this.selectedProjects],
      reviewer: (this.selectedReviewers.length ? this.selectedReviewers : this.reviewers).map(reviewer => reviewer._id),
      role: (this.selectedRoles.length ? this.selectedRoles : this.roles).map(role => role.id),
      timezone: this.isDefaultTimeZone ? this.dateTimeService.getCurrentTimezone() : this.selectedTimezone?.val || '',
      useDefaultTimezone: this.isDefaultTimeZone
    };

    this.reportService.generatePayrollReport(filters).subscribe({
      next: (payrollReports) => {
        this.payrollReports = payrollReports;
        this.payrollReports.forEach(payroll => {
          payroll.showDetail = false;
        });
      }
    })
  }

  showDetail(payroll: GridInterface): void{
    payroll.showDetail = !payroll.showDetail;
  }

  toHours(timestamp: number = 0): string {

    const hours = Math.floor(timestamp / 60 / 60);

    const minutes = Math.floor(timestamp / 60) - (hours * 60);

    const seconds = timestamp % 60;

    let timestring = '';
    timestring += hours ? hours + 'h ' : '';
    timestring += minutes ? minutes + 'min ' : '';
    timestring += seconds ? seconds + 's' : '';
    return timestring;

  }
}
