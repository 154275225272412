import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ListOfValues } from '../interfaces/list-of-values.interface';
import { lastValueFrom } from 'rxjs';
import { HttpBaseService } from './http-base.service';


@Injectable({
  providedIn: 'root'
})
export class ListOfValuesService {

  constructor(
    private http: HttpBaseService
  ) { }

  public getListOfValues(key: string, firmId: string): Promise<Array<ListOfValues>>{
    let params = new HttpParams()
    .set('key', key)
    .set('firm', firmId)
    .set('noCache', new Date().getTime());
    return lastValueFrom(this.http.get('listOfValues', { params }));
  }
}
