import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  constructor(private http: HttpBaseService) { }

  addNotes(reviewerId: string, message: string) {
    return lastValueFrom(this.http.post(`users/${reviewerId}/note`, {
      message:message
    }));
  }

  bulkAdd(notes: Array<any>) {
    return lastValueFrom(this.http.post(`users/notes`, notes));
  }
}
