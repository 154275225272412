import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CredentialsRoutingModule } from './credentials-routing.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CredentialsRoutingModule
  ]
})
export class CredentialsModule { }
