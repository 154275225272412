import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-profile-completion-modal',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './profile-completion-modal.component.html',
  styleUrl: './profile-completion-modal.component.scss'
})
export class ProfileCompletionModalComponent {

  constructor(private ref: DynamicDialogRef, private router:Router) {}

  editProfile(): void {
    this.router.navigateByUrl('/reviewer/profile/main');
    this.ref.close();
  }

  accountSettings(): void {
    this.router.navigateByUrl('/reviewer/settings');
    this.ref.close();
  }

  closeDialog(): void{
    this.ref.close();
  }
}
