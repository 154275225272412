import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FloatLabelModule } from 'primeng/floatlabel';
import moment from 'moment';
import { PeopleService } from '../../../../../../../shared/services/people.service';
import { ToastService } from '../../../../../../../shared/services/toast.service';
import { UserService } from '../../../../../../../shared/services/user.service';
import { environment } from '../../../../../../../../environments/environment';
import { InputTextModule } from 'primeng/inputtext';
import { Location } from '@angular/common';
import { Tooltip, TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-shareable-invite',
  standalone: true,
  imports: [FloatLabelModule, ButtonModule, DynamicDialogModule, ButtonModule, ReactiveFormsModule, InputTextModule, TooltipModule],
  templateUrl: './shareable-invite.component.html',
  styleUrl: './shareable-invite.component.scss'
})
export class ShareableInviteComponent {

  shareInviteForm!: FormGroup;
  inviteLink: string = '';
  copyText = 'Copy';
  @ViewChild(Tooltip) tooltip!: Tooltip;

  constructor(private ref: DynamicDialogRef, 
    private fb: FormBuilder, 
    private peopleService: PeopleService,
    private userService: UserService,
    private toastService: ToastService,
    private location: Location
  ) {

  }

  initializeCopyUrlOption(){
    const user = this.userService.getSessionUser();
    const firmId = user.firm._id;
    this.inviteLink = `${window.location.origin}/reviewer-signup/firm/${firmId}`;
  }

  ngOnInit() {
    this.shareInviteForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
    this.initializeCopyUrlOption();
  }

  onSubmit() {
    if (this.shareInviteForm.valid) {
      const firmId = this.userService.getUserFirmId();
      let data = this.shareInviteForm.value;
      let body = {
        firmId: firmId,
        users: [data]
      };
      this.peopleService.inviteCandidates(body).subscribe((response: any) => {
          let user = response[0];
          let recoverHashUrl = user.recoverHash + ':' + moment().add(1, 'd').unix();
          this.inviteLink = environment.clientHost + 'rp?t=' + recoverHashUrl + '&externalInvite=' + user.externalInvite + '&firm=' + firmId;
          this.toastService.showSuccess('Link Generated Successfully');
          this.shareInviteForm.reset();
      });
    }
  }

  copyLink() {
    navigator.clipboard.writeText(this.inviteLink).then().catch(e => console.log(e));
    this.copyText = 'Copied';
    this.tooltip.activate();
    setTimeout(() => {
      this.copyText = "Copy";
    }, 4000);
  }
}
