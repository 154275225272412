<div class="col-12">
  <div class="border-bottom-1 surface-border">
      <p class="text-sm m-0">
      Welcome to Staftr. To best position yourself to receive job offers please
      make sure to complete your Profile.<br />
      It’s also recommended you download the Staftr mobile app and set your
      preferred notification settings
    </p>
    <div class="flex flex-row align-items-start gap-3 mt-4 mb-4">
      <p-button (click)="editProfile()">EDIT PROFILE</p-button>
      <p-button (click)="accountSettings()">ACCOUNT SETTINGS</p-button>
    </div>
  </div>
</div>
