import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { lastValueFrom } from 'rxjs';
import { Reviewer } from '../interfaces/reviewer.interface';
import { Offers } from '../interfaces/offers.interface';
import { Role } from '../interfaces/roles.interface';
import { constants } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class OfferService {

  constructor(private http: HttpBaseService) { }

  createOffer(
      payload: Offers
  ): Promise<any>{
      return lastValueFrom(this.http.post(`offers/create`, payload));
  }

  calculateOvertime(selectedRole: Role): number {
    let newOverTime: number = selectedRole.overtimeSelected ? +(parseFloat(`${selectedRole.hourlyRate}`) * constants.select.overtimeMultiplier).toFixed(constants.select.defaultRateFixedTo) : 0;

    if(selectedRole.offerSent && newOverTime < (selectedRole.overTimeSaved || 0)){
        newOverTime = selectedRole.overTimeSaved || 0;
    }
    return newOverTime;
  };

  createImmediateOffer(params: any = {}): Promise<any>{
    return lastValueFrom(this.http.post(`offers/createImidiateOffers`, params));
  }

  sendOffers(projectId: string, roleId: string, params: any = {}): Promise<any>{
    return lastValueFrom(this.http.put(`projects/${projectId}/roles/${roleId}/sendOffers`, params));
  }

  resumeOffers(projectId: string, roleId: string, params: any = {}): Promise<any>{
    return lastValueFrom(this.http.put(`projects/${projectId}/roles/${roleId}/resumeOffers`, params));
  }

  pauseOffers(projectId: string, roleId: string): Promise<any>{
    return lastValueFrom(this.http.put(`projects/${projectId}/roles/${roleId}/pauseOffers`));
  }

  cancelOffer(offer: any): Promise<any>{
    return lastValueFrom(this.http.put(`offers/${offer._id}/cancel`, offer));
  }

  acknowledgeEndedPosition(positionId: string): Promise<any> {
    return lastValueFrom(this.http.put(`positions/${positionId}/acknowledgeEndedPosition`));
  }

  cancelAllOutstandingOffers(projectId: string, roleId: string){
    return lastValueFrom(this.http.delete(`projects/${projectId}/roles/${roleId}/cancelOutstandingOffers`));
  }
}
