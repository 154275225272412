import moment from 'moment';
import { ProjectPayload, ReviewersPayload, RolesPayload } from '../interfaces/reports.interface';
import { ReportService } from '../reports.service';
import { StorageService } from '../../../../../shared/services/storage.service';
import { DateTimeService } from '../../../../../shared/services/date-time.service';
import { TimezonePayload } from '../../../../../shared/interfaces/date-time.interface';
import { environment } from '../../../../../../environments/environment';
import { KeyValue } from '@angular/common';
import { UserService } from '../../../../../shared/services/user.service';

export class BaseReport {
    projects: Array<ProjectPayload> = [];
    selectedProjects: Array<ProjectPayload> = [];

    roles: Array<RolesPayload> = [];
    selectedRoles: Array<RolesPayload> = [];

    reviewers: Array<ReviewersPayload> = [];
    selectedReviewers: Array<ReviewersPayload> = [];

    timezones: Array<TimezonePayload> = [];
    selectedTimezone: TimezonePayload | null = null;

    startDate = moment().subtract(1, 'weeks').toDate();
    endDate = moment().toDate();

    sessionUser: any = null;
    isDefaultTimeZone = true;
    rowsPerPageOptions = [5, 10, 20];
    noOfRows = 10;

    constructor(
        protected reportService: ReportService,
        protected storageService: StorageService,
        protected dateTimeService: DateTimeService,
        protected userService: UserService
    ) {

    }

    async initializeReport(): Promise<void> {
        this.sessionUser = this.userService.getSessionUser();
        this.getTimezone();
        this.getProjectsByFirmAndMarket();
        this.setDefaultTimezone(this.isDefaultTimeZone);
    }

    onChangeDefaultTimezoneCheck(e: any): void{
        this.setDefaultTimezone(this.isDefaultTimeZone);
    }

    async getProjectsByFirmAndMarket(): Promise<void> {
        this.projects = await this.reportService.getProjectsByFirmAndMarkets(this.sessionUser.firm._id);
    }

    async onProjectSelectionChange(e: any): Promise<void> {
        this.roles = [];
        this.selectedRoles = [];
        await this.getRolesByProjectIds();
        await this.getReviewersByProjectAndRoleIds();
    }

    async getRolesByProjectIds(): Promise<void> {
        const projectIds = this.selectedProjects.map(project => project.id);
        this.roles = await this.reportService.getRolesByProjectIds(projectIds);
    }

    async onRolesSelectionChange(e: any): Promise<void> {
        await this.getReviewersByProjectAndRoleIds();
    }

    async getReviewersByProjectAndRoleIds(): Promise<void> {
        const projectIds = this.selectedProjects.map(project => project.id);
        const roleIds = this.selectedRoles.map(role => role.id);
        const firmId = this.sessionUser.firm.id;
        const startDate = moment(this.startDate).format('MM/DD/YYYY');
        const endDate = moment(this.endDate).format('MM/DD/YYYY');
        this.reviewers = await this.reportService.getProjectReviewers(projectIds, roleIds, firmId, startDate, endDate);
        this.reviewers.forEach(reviewer => {
            reviewer.fullName = `${reviewer.firstName} ${reviewer.lastName}`;
        });
        this.selectedReviewers = [];
    }

    getTimezone(): Array<TimezonePayload> {
        this.timezones = this.dateTimeService.getTimeZoneList(environment.usSpecificTimezoneOnly);
        return this.timezones;
    }

    setDefaultTimezone(isDefault: boolean): void{
        if(isDefault){
            this.setTimezone(null);
        } else {
            this.setTimezone(this.getTimezone().length ? this.getTimezone()[0] : null);
        }
    }

    setTimezone(timezone: TimezonePayload | null): void{
        this.selectedTimezone = timezone;
    }

    originalOrder = (a: KeyValue<number,any>, b: KeyValue<number,any>): number => {
        return 0;
    }
}
