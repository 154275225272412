import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadingComponent } from '../../../../../shared/components/heading/heading.component';
import { RoleComponent } from './role/role.component';
import { InformationComponent } from './information/information.component';
import { OffersComponent } from './offers/offers.component';
import { ReviewComponent  } from './review/review.component';
import { ProjectDocumentsComponent } from './documents/documents.component';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../../../shared/services/project.service';
import { Project } from '../project.interface';
import { constants } from '../../../../../shared/constants/constants';
import { EditTemplateComponent } from './documents/edit-template/edit-template.component';
import { TabViewModule } from 'primeng/tabview';

@Component({
    selector: 'app-create',
    standalone: true,
    templateUrl: './create.component.html',
    styleUrl: './create.component.scss',
    styles: [
        `
      :host ::ng-deep {
        [pDraggable] {
          cursor: move;
        }
      }
    `,
    ],
    encapsulation: ViewEncapsulation.None,
    imports: [
        TabViewModule,
        ReviewComponent,
        CommonModule,
        HeadingComponent,
        RoleComponent,
        InformationComponent,
        OffersComponent,
        ProjectDocumentsComponent,
        EditTemplateComponent
    ]
})
export class CreateComponent implements OnInit {
  headingButton = {
    url: '/client/project/create',
    label: 'CREATE PROJECT'
  }
  projectId: string | null = null;
  project: Project | null = null;
  activeTabIndex = 0;
  status = constants.projectStatus;
  projectsTypeTitle: string = 'Create Project';
  roleId: any = null;
  isDefaultSelectedRole = true;

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
  ){ }
  
  ngOnInit() {
    this.initializeEdit();
  }

  initializeEdit(): void{
    this.route.paramMap.subscribe(async params => {
      this.projectId = params.get('projectId') || null;
      if(this.projectId){
        await this.getProjectById();
      }
    });
  }

  updateProjectState(): void{
    this.getProjectById();
  }

  updateProjectStateAfterPublish(): void{
    this.getProjectById(false);
  }

  async getProjectById(navigate: boolean = true): Promise<void>{
    this.projectService.getProjectById(this.projectId || '').subscribe({
      next: (project: Project) => {
        this.project = project;
        this.projectsTypeTitle = 'Edit Project - ' + this.project?.codeName;
        if(navigate){
          this.activeTab();
        }
      }
    });
  }

  activeTab(): void{
    switch (this.project?.step) {
      case '2':
        this.activeTabIndex = 1;
        break;
      case '5':
        this.activeTabIndex = 2;
        break;
      case '7':
        this.activeTabIndex = 3;
        break;
      default:
        break;
    }
  }

  changeTab(index: number = 0, isDefault: boolean = true): void {
    this.initializeProjectStep();
    this.activeTabIndex = index;
    this.isDefaultSelectedRole = isDefault;
  }

  gotoRoleEditPage(event: any): void{
    this.changeTab(event.index);
    this.roleId = event.roleId;
  }

  initializeProjectStep(): void{
    this.roleId = null;
  }
}
