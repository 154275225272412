<div>
  <p>
    <span class="font-bold p-0 m-0">You are about to restart: </span
    >{{ projectName }}
  </p>
  <div class="grid grid-nogutter">
    <div class="col-12">
      <p-floatLabel>
        <p-dropdown
          [options]="managerDropdown"
          [(ngModel)]="selectedManager"
          inputId="managers"
          appendTo="body"
          optionLabel="label"
          name="projectOwner"  
          required  
          #projectOwner="ngModel"
        ></p-dropdown>
        <label for="managers">Who is the Project Owner</label>
      </p-floatLabel>
      <div *ngIf="projectOwner.invalid && projectOwner.touched" style="color: #f97066;">
        Project owner is required.
      </div>
    </div>
  </div>
  <div class="flex justify-content-end">
    <p-button [disabled]="projectOwner.invalid" label="Submit" (click)="handleSubmit()" class="mt-4"></p-button>
  </div>
</div>
