import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component'
import {SearchMarketResolver} from './dashboard/people/search-market-resolver';
import { OverviewComponent } from '../client/dashboard/overview/overview.component';
import { ProjectModule } from './dashboard/project/project.module';
import { PeopleModule } from './dashboard/people/people.module';
import { ReportsModule } from './dashboard/reports/reports.module';
import { FirmUsersComponent } from './dashboard/firm-users/firm-users.component';
import { DocumentsComponent } from './dashboard/documents/documents.component';
import { DocuSignTemplatesComponent } from './dashboard/docusign-templates/docusign-templates.component';
import { SettingsModule } from './dashboard/settings/settings.module';
import { MessageModule } from './dashboard/message/message.module';
import { ReviewerProfileComponent } from '../../shared/components/reviewer-profile/reviewer-profile.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      { path: '', redirectTo: 'overview', pathMatch: 'full' },

      {
        path: 'overview',
        component: OverviewComponent,
      },
      {
        path: 'project',
        loadChildren: () => ProjectModule
      },
      {
        path: 'people',
        loadChildren: () => PeopleModule,
        resolve: { searchMarket:  SearchMarketResolver}
      },
      {
        path: 'reports',
        loadChildren: () => ReportsModule
      },
      {
        path: 'firm-users',
        component: FirmUsersComponent
      },
      {
        path: 'documents',
        component: DocumentsComponent
      },
      {
        path: 'docusign-template',
        component: DocuSignTemplatesComponent
      },
      {
        path: 'settings',
        loadChildren: () => SettingsModule
      },
      {
        path: 'messaging',
        loadChildren: () => MessageModule
      },
      {
        path: 'reviewer-profile',
        component: ReviewerProfileComponent
      },
    ]
  }
];



@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientRoutingModule { }
