import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';

@Component({
    providers: [MessageService],
    standalone:true,
    imports:[ButtonModule, DynamicDialogModule, InputSwitchModule, ButtonModule, FormsModule],
    templateUrl: './fire-reviewer.component.html'
})
export class FireReviewerConfirmation implements OnInit {

    endMessage: string = '';
    visible: boolean = false;
    discussions = [];
    isDRSSubmittedChecked = false;
    fullName: string = '';

    constructor(private ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    }

    ngOnInit() {
        this.fullName = this.config.data.fullName;
        this.endMessage='';   
    }

    onValueChange($event:Event){
        this.endMessage = ($event.target as any).value;
    }

    onToggleSwitch() {
        console.log(this.isDRSSubmittedChecked);
    }

    closeDialog(){
        this.ref.close();
    }

    confirm(data:any) {
        this.ref.close({endMessage: data, isDRSSubmittedChecked: this.isDRSSubmittedChecked});
    }

}