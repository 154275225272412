<p-tabView styleClass="tabview-custom">
<p-tabPanel header="Header I">
  <ng-template pTemplate="header">
    <div class="flex align-items-center gap-2">
      <span class="font-bold white-space-nowrap m-0">
        Account Settings
      </span>
    </div>
  </ng-template>
    <div class="cards">
      <div class="grid gap-4 flex-column py-4">
           <form [formGroup]="passwordForm" > 
          <h6 class="m-0 mb-3">Change Password</h6>
          <div class="flex flex-column gap-3 mb-3">
            <p-floatLabel>
                    <p-password  formControlName="currentPassword" [feedback]="false" [toggleMask]="true" styleClass="md:w-30rem w-full" />
              <label for="password">Enter Current Password<span class="required">*</span></label>
                 
            </p-floatLabel>
            <p-floatLabel>
                    <p-password formControlName="newPassword" [feedback]="false" [toggleMask]="true" styleClass="md:w-30rem w-full" />
              <label for="password">Enter New Password<span class="required">*</span></label>
                    
            </p-floatLabel>
            <p-floatLabel>
                    <p-password formControlName="confirmPassword" [feedback]="false" [toggleMask]="true" styleClass="md:w-30rem w-full" />
              <label for="password">Confirm Password<span class="required">*</span></label>
                   
            </p-floatLabel>
          </div>
          <div>
                <p-button label="Update Password" (click)="onPassSubmit()" rounded="true" size="large"  [disabled]="passwordForm.invalid" outlined="true"></p-button>
          </div>
        </form>

        <h6 class="m-0">Notification Setting</h6>
        <div class="flex flex-column gap-3">
               
          <form [formGroup]="notificationSwitches" class=" ">
            <div class="flex gap-3 px-2 mb-3">
              @if (notificationPref) {
                <p-inputSwitch formControlName="email"  (onChange)="updatePreference('email')" [disabled]="getBool(notificationPref!.unAvailableStatus)"/>
              }
              <label for="emailId" class="line-height-2">Email ID</label>
            </div>
            <div class="flex gap-3 px-2 mb-3">
                      <p-inputSwitch formControlName="notification" (onChange)="updatePreference('notification')" inputId="notification" />
                      <label for="notification" class="line-height-2">Push Notifications</label>
            </div>
            <div class="flex gap-3 px-2">
                    <p-inputSwitch formControlName="sms"
                   
                     (onChange)="updatePreference('sms')" inputId="textMessages" />
                    
                

              <div class="p-col-12">
                <div class="mp-form-field">
                  <label>Text Message (SMS)</label>
                  @if(notificationSwitches.value.sms){
                  <div class="flex flex-column md:flex-row gap-3">
                    <p-floatLabel class="mr-8"><span class="mp-form-text p-mr-2 p-col">{{phone.partOne}}-{{phone.partTwo}}-{{phone.partThree}}</span></p-floatLabel>
                    <p-floatLabel><button pButton type="button" icon="pi pi-pencil" label="Edit" class="p-button-primary p-mr-2" (click)="editPhoneNo()"></button></p-floatLabel>
                    <p-floatLabel><button pButton type="button" label="Verify" class="p-button-primary" (click)="sendVerificationPhoneSms()"></button></p-floatLabel>
                  </div>
                  }
                </div>
              </div>
                    
            </div>
          </form>

          <!-- Edit Phone Form -->
                  <form [formGroup]="editPhoneForm" *ngIf="showEditPhoneForm" class="w-100">
            <div>
              <label>Phone No</label>
              <div class="flex flex-column md:flex-row gap-3">
                <p-floatLabel>
                          <p-inputMask styleClass="md:w-20rem w-full" mask="999" formControlName="partOne" placeholder="123"></p-inputMask>
                </p-floatLabel>
                <p-floatLabel>
                          <p-inputMask styleClass="md:w-20rem w-full" mask="999" formControlName="partTwo" placeholder="456"></p-inputMask>
                </p-floatLabel>
                <p-floatLabel>
                          <p-inputMask styleClass="md:w-20rem w-full" mask="9999" formControlName="partThree" placeholder="7890"></p-inputMask>
                </p-floatLabel>
              </div>
            </div>
            <div class="p-grid p-justify-end p-align-center mt-3">
              <button pButton type="button" label="Cancel" class="p-button-secondary mr-2" (click)="editPhoneNo()"></button>
              <button pButton type="button" label="Update" class="p-button-primary" [disabled]="getBool(!editPhoneForm.valid)" (click)="updatePhone()"></button>
            </div>
            <br />
          </form>

          <!-- Verification Phone Form -->
                  <form [formGroup]="verificationPhoneForm" *ngIf="_isShowVerificationPhoneForm" class="mb-3 w-100">
            <div class="p-grid p-nogutter">
              <div class="p-col">
                <div class="mp-form-field w-30rem">
                  <label>Verification Code</label>
                          <p-inputMask mask="9999" formControlName="verificationCode" placeholder="1234"></p-inputMask>
                </div>
              </div>
            </div>
            <div class="p-grid p-justify-end p-align-center mt-3">
              <button pButton type="button" label="Cancel" class="p-button-secondary mr-2" (click)="showVerificationPhoneForm()"></button>
              <button pButton type="button" label="Verify" class="p-button-primary" [disabled]="getBool(!verificationPhoneForm.valid)" (click)="verifyPhone()"></button>
            </div>
            <br />
          </form>
                <form [formGroup] = "unAvailableForm">
            <div class="flex gap-3 px-2 mb-3">
                    <p-inputSwitch inputId="unavailabilityStatus" formControlName="unAvailableStatus"  
                    (onChange)="updatePreference('unAvailableStatus')" />

                    <label for="unavailabilityStatus" class="line-height-2">Unavailability Status</label>
            </div>
            @if(unAvailableForm.value.unAvailableStatus){
            <div class="flex flex-column md:flex-row gap-3 mb-3">
              <p-floatLabel>
                        <p-calendar inputId="startDate" formControlName="startDate"/>
                <label for="startDate">Start Date</label>
              </p-floatLabel>
              <p-floatLabel>
                <p-calendar inputId="endDate" formControlName="endDate" />
                <label for="endDate">End Date</label>
              </p-floatLabel>
                   
            </div>

            <div class="flex flex-column md:flex-row gap-3">
                    <p-button  label="Submit" rounded="true" size="large" outlined="true" (click)="updatePreference('unAvailableStatus')"></p-button>
            </div>
            }
          </form>

                <div class="flex flex-column md:flex-row gap-3 md:gap-6 md:align-items-center">
            <span class="font-bold">Deactivate my {{title}} account</span>
            <p-button
              label="Remove Account"
              (click)="requestAccountDeletion()"
              rounded="true"
              size="large"
              severity="danger"
            ></p-button>
          </div>
        </div>
      </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Header II">
      <ng-template pTemplate="header">
        <div class="flex align-items-center gap-2">
          <span class="font-bold white-space-nowrap m-0">
            Personal Information
          </span>
        </div>
      </ng-template>
      <form [formGroup]="profileForm">
        <div class="grid xl:w-9 w-full pt-4">
          <div class="lg:col-6 col-12">
            <p-floatLabel>
              <input
                formControlName="firstName"
                type="text"
                pInputText
                id="firstname"
              />
              <label for="firstname">First Name<span class="required">*</span></label>
            </p-floatLabel>
          </div>
          <div class="lg:col-6 col-12">
            <p-floatLabel>
              <input
                formControlName="lastName"
                type="text"
                pInputText
                id="lastname"
              />
              <label for="lastname">Last Name<span class="required">*</span></label>
            </p-floatLabel>
          </div>
          <div class="col-12">
            <p-floatLabel>
              <input
                formControlName="email"
                type="email"
                pInputText
                inputId="Email"
              />
              <label for="Email">Email</label>
            </p-floatLabel>
          </div>
        </div>
        <div
          formGroupName="profile"
          class="flex flex-column justify-content-between h-page-half"
        >
          <div formGroupName="contact" class="grid xl:w-9 w-full pt-4">
            <div class="lg:col-6 col-12">
              <div class="flex gap-3">
                <p-floatLabel class="w-full">
                  <input
                    formControlName="home"
                    type="tel"
                    pInputText
                    id="phone"
                  />
                  <label for="phone">Phone</label>
                </p-floatLabel>
                <p-button
                  label="Get Code"
                  rounded="true"
                  size="large"
                  styleClass="white-space-nowrap"
                  [disabled]="isPhoneVerified"
                  (onClick)="sendVerificationCode()"
                ></p-button>
              </div>
            </div>
            @if(!isPhoneVerified){
            <div class="lg:col-6 col-12">
              <div class="flex gap-3">
                <p-floatLabel class="w-full">
                  <input
                    type="text"
                    pInputText
                    id="verificationCode"
                    #verificationCodeInput
                  />
                  <label for="verificationCode">Verification Code</label>
                </p-floatLabel>
                <p-button
                  label="Verify code"
                  rounded="true"
                  size="large"
                  styleClass="white-space-nowrap"
                  (onClick)="verify(verificationCodeInput.value)"
                ></p-button>
              </div>
            </div>
            }
            <div class="lg:col-6 col-12">
              <p-floatLabel>
                <input
                  formControlName="street"
                  type="text"
                  pInputText
                  id="address"
                />
                <label for="address">Address<span class="required">*</span></label>
              </p-floatLabel>
            </div>
            <div class="lg:col-6 col-12">
              <p-floatLabel>
                <input
                  formControlName="city"
                  inputId="city"
                  placeholder="City"
                  pInputText
                />
                <label for="city">City<span class="required">*</span></label>
              </p-floatLabel>
            </div>
            <div class="lg:col-6 col-12">
              <p-floatLabel>
                <input
                  formControlName="zipCode"
                  pInputText
                  id="zip"
                />
                <label for="zipCode">Zip Code<span class="required">*</span></label>
              </p-floatLabel>
            </div>
            <div class="lg:col-6 col-12">
              <p-floatLabel>
                <p-dropdown
                  [options]="stateDropDown"
                  formControlName="state"
                  inputId="states"
                  appendTo="body"
                  optionLabel="name"
                ></p-dropdown>
                <label for="states">State<span class="required">*</span></label>
              </p-floatLabel>
            </div>
          </div>

          <div class="flex gap-3 mt-3">
            <p-button
              label="Back"
              rounded="true"
              outlined="true"
              size="large"
              styleClass="white-space-nowrap"
              (onClick)="onDiscard()"
            ></p-button>
            <p-button
              label="Save"
              rounded="true"
              size="large"
              [disabled]="!profileForm.valid"
              styleClass="white-space-nowrap freshgreen-background"
              (onClick)="onSubmit()"
            ></p-button>
          </div>
        </div>
      </form>
    </p-tabPanel>
  </p-tabView>
<!-- </div> -->

