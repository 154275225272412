@if(responseData.length){
  <p-table styleClass="p-datatable-sm p-datatable-sm--normal" [value]="responseData" dataKey="_id" [tableStyle]="{ 'min-width': '60rem' }"
  [expandedRowKeys]="expandedRows" (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
    <ng-template pTemplate="header">
        <tr>
            <th>
                Role
            </th>
            <th>
                Position
            </th>
            <th>
              Start Date/Time
            </th>
            <th>
                Received
            </th>
            <th style="width:10%;">Action</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" class="border-0" let-responseData let-expanded="expanded">
        <tr>
            <td>{{ responseData.roleType }}</td>
            <td> {{ responseData.positions }} Listed / {{ responseData.positionsFilled }} Filled</td>
            <td>
                @if (responseData.startAt) {
                {{responseData.startAt | date:'h:mma MM/dd/yyyy ' }}
                } @else {
                -
                }
            </td>
            <td>{{ responseData.offerQueuesCount }}</td>
            <td>
                <div class="wrap-actions flex align-items-center gap-2">
                    @if(responseData.offers.viewed.length || responseData.offers.unViewed.length || responseData.offers.rejected.length){
                    <button  pTooltip="Download" 
                    tooltipPosition="top" pButton pRipple type="button" class="p-button-rounded p-button-text">
                        <span class="material-symbols-outlined"> download </span>
                    </button>
                    }
  
                    <p-button [pTooltip]="expanded? 'Close' : 'Expand'" 
                    tooltipPosition="top" type="button" pRipple [pRowToggler]="responseData" [text]="true" [rounded]="true"
                        [plain]="true" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'" class="row-toggler" />
                </div>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-product>
        <tr>
          <td colspan="7" class="p-0">
            <div class="sub-details py-3 px-4">
              <div class="grid grid-nogutter">
                <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                  <span class="text-base block font-medium mt-3">Project Type</span>
                </div>
                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                  @for(job of roleDetailsDisplay?.jobType; track job){
                    <p class="m-0 text-black-alpha-60 font-medium text-sm">
                      {{ job }}
                    </p>
                  }
                </div>
                <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                  <span class="text-base block font-medium mt-3">Education</span>
                </div>
                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                  <span class="text-primary mt-2 block text-xs">Under Graduate</span>
                  @if(roleDetailsDisplay?.underGrad?.length){
                    @for(education of roleDetailsDisplay?.underGrad; track education){
                      <p class="m-0 text-black-alpha-60 font-medium text-sm">{{education.type}}-{{education.major}}</p>
                    }
                  } 
                  @else{
                    <p class="m-0 text-black-alpha-60 font-medium text-sm">Any</p>
                  }      
                </div>
                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                <span class="text-primary mt-2 block text-xs">Graduate</span>
                @if(roleDetailsDisplay?.graduate?.length){
                    @for(education of roleDetailsDisplay?.graduate; track education){
                      <p class="m-0 text-black-alpha-60 font-medium text-sm">{{education.type}}-{{education.major}}</p>
                    }
                  }
                  @else{
                    <p class="m-0 text-black-alpha-60 font-medium text-sm">Any</p>
                  }
                </div>
                <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                  <span class="text-base block font-medium mt-3">Skills and Experience</span>
                </div>
                @if(roleDetailsDisplay?.skillsAndExperience?.length){
                  <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                    @for(skill of roleDetailsDisplay?.skillsAndExperience; track skill){
                      <p class="m-0 text-black-alpha-60 font-medium text-sm">{{skill.val}}</p>
                    }
                  </div>
                }
                @else{
                  <p class="m-0 text-black-alpha-60 font-medium text-sm">Any</p>
                }
                <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                  <span class="text-base block font-medium mt-3">Credentials</span>
                </div>
                @if(roleDetailsDisplay?.credential?.length){
                  <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                    @for(cred of roleDetailsDisplay?.credential; track cred){
                      <p class="m-0 text-black-alpha-60 font-medium text-sm">{{cred.val}}</p>
                    }
                  </div>
                }
                @else{
                  <p class="m-0 text-black-alpha-60 font-medium text-sm">Any</p>
                }
                <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                  <span class="text-base block font-medium mt-3">Timeline and Schedule</span>
                </div>
                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                  <span class="text-primary mt-2 block text-xs">Starts On</span>
                  <p class="m-0 text-black-alpha-60 font-medium text-sm">
                    {{ roleDetails.startAt | date}}
                  </p>
                </div>
                @if(roleDetails.workSchedule.schedule.weekDays.willWork){
                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                  <span class="text-primary mt-2 block text-xs">Monday - Friday</span>
                  <p class="m-0 text-black-alpha-60 font-medium text-sm">
                    Start {{ formatTime(roleDetails.workSchedule.schedule.weekDays.time.start) }}
                  </p>
                  <p class="m-0 text-black-alpha-60 font-medium text-sm">
                    End {{ formatTime(roleDetails.workSchedule.schedule.weekDays.time.end) }}
                  </p>
                </div>
                }
                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                  <span class="text-primary mt-2 block text-xs"
                    >Expected Duration</span>
                  <p class="m-0 text-black-alpha-60 font-medium text-sm">
                    {{ roleDetails.workSchedule.duration }}
                  </p>
                </div>
                @if(roleDetails.workSchedule.duration !== 'Single Day or Shift'){
                  @if(roleDetails.workSchedule.schedule.saturday.willWork){
                    <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                      <span class="text-primary mt-2 block text-xs">Saturday</span>
                      <p class="m-0 text-black-alpha-60 font-medium text-sm">
                        Start {{ formatTime(roleDetails.workSchedule.schedule.saturday.time.start) }}
                      </p>
                      <p class="m-0 text-black-alpha-60 font-medium text-sm">
                        End {{formatTime(roleDetails.workSchedule.schedule.saturday.time.end)}}
                      </p>
                    </div>
                  }
                  @if(roleDetails.workSchedule.workHours){
                    <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                      <span class="text-primary mt-2 block text-xs">Expected hours</span>
                      <p class="m-0 text-black-alpha-60 font-medium text-sm">
                        {{ roleDetails.workSchedule.workHours }}
                      </p>
                    </div>
                  }
                  @if(roleDetails.workSchedule.schedule.sunday.willWork){
                    <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                      <span class="text-primary mt-2 block text-xs">Sunday</span>
                      <p class="m-0 text-black-alpha-60 font-medium text-sm">
                        Start {{ formatTime(roleDetails.workSchedule.schedule.sunday.time.start) }}
                      </p>
                      <p class="m-0 text-black-alpha-60 font-medium text-sm">
                        End
                        {{ formatTime(roleDetails.workSchedule.schedule.sunday.time.end) }}
                      </p>
                    </div>
                  }
                }
                <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                  <span class="text-base block font-medium mt-3">Screening Questions</span>
                </div>
                @if(roleDetails.hasConflict ){  
                  @if(roleDetails.scanForConflicts.specificQuestions){
                    @if(roleDetails.conflictQuestions.length){
                      <p-table [value]="roleDetails.conflictQuestions" class="py-0 pr-4 pl-0 col-12">
                        <ng-template pTemplate="header">
                          <tr class="table-data">
                            <th> 
                              Questions
                            </th>
                            <th class="td-center">
                              Answers
                            </th>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-question>
                          <tr class="table-data surface-100">
                            <td>
                              <div class="inner-wrap p-0">
                                {{ question.title }}
                              </div>
                            </td>
                            <td class="td-center">
                              {{ question?.answer? 'Yes': 'No' }}
                            </td>
                          </tr>
                        </ng-template>
                      </p-table>                      
                    }
                  }
                } @else {
                  <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                    <span class="text-primary mt-2 block text-xs">Conflict Questions</span>
                    <p class="m-0 text-black-alpha-60 font-medium text-sm">No conflict questions</p>
                </div>
              }
                
              </div>
            </div>
          </td>
        </tr>
    </ng-template>
  </p-table>
  
}
@else {
  <span class="text-sm font-medium">No Roles Created</span>
}
