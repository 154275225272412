import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PingService {
  serverPingInterval: any;
  isFetchInProgress = false;
  constructor(private http: HttpBaseService) {

  }

  startPinging() {
    this.serverPingInterval = setInterval(async () => {
      if (!this.isFetchInProgress) {
        this.isFetchInProgress = true;
        await this.pingserver();
        this.isFetchInProgress = false;
      }
    }, 5000);
  }
  stopPinging() {
    clearInterval(this.serverPingInterval);
  }

  pingserver() {
    return lastValueFrom(this.http.get('check'));
  }

}
