import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'listing', pathMatch: 'full' },

      {
        path: 'listing',
        loadComponent:()=> import('./talent-pool-listing/talent-pool-listing.component').then((c)=> c.TalentPoolListingComponent)
      },
      {
        path: 'edit/:talentPoolId',
        loadComponent:()=> import('./create/create.component').then((c)=> c.CreateComponent)
      },
      {
        path: 'create',
        loadComponent:()=> import('./create/create.component').then((c)=> c.CreateComponent)
      },
      {
        path: 'assign/:talentPoolId',
        loadComponent:()=> import('./assign-users/assign-users.component').then((c)=> c.AssignUsersComponent),
      },

];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TalentPoolRoutingModule { }
