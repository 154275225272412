<div header="Archive Project" class="align-items-center">
    <h5 class="block mb-5">Are you sure you want to fire {{fullName}}?</h5>
    <p for="end-reason" >Reason for fire</p>
    <div class="flex align-items-center gap-3 mb-3">
        <textarea [value]="endMessage" (input)="onValueChange($event)"  pInputText id="end-reason" class="flex-auto" autocomplete="off" ></textarea>
    </div>

    @if (discussions.length) {
        <div class="text-center xtra-spacing" layout="row">
            <div flex>
                <p class="font-twntwo">
                    <b>Note: </b> There are unread messages from one or more candidate whose role is about to end.
                    These messages will not be readable if you continue. To read messages click “Cancel” and
                    navigate to the messaging screen.
                </p>
            </div>
        </div>    
    }
   
    <div class="flex justify-content-end gap-2">
        <p-button label="Cancel" severity="secondary" (click)="closeDialog()" />
        <p-button label="OK" (click)="confirm(endMessage)" />
    </div>
</div>