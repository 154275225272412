import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
@Component({
  selector: 'app-tracking-time-modal',
  standalone: true,
  imports: [ButtonModule,FormsModule, RadioButtonModule, DropdownModule],
  templateUrl: './tracking-time-modal.component.html',
  styleUrl: './tracking-time-modal.component.scss'
})
export class TrackingTimeModalComponent {
  selectedRole : any;
  constructor(private ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  closeDialog(){
    this.ref.close();
  }

  confirm() {
    this.ref.close(this.selectedRole);
  }
}
