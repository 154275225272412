<div class="wrap-dashboard">
    <app-heading [title]="'Dashboard Overview'" [button]="headingButton" [showToggle]="true" [isbreadcrumb]="false"></app-heading>
    <div class="wrap-center-body">
        <div class="grid">
            <div class="col-12 p-0 lg:col-12 xl:col-7 sm:col-12 mt-4">
                <div class="wrap-snapshot border-round-2xl mr-0 lg:mr-0 xl:mr-4 md:mr-4 mb-4">
                    <h3 class="h6 mt-0 mb-4">Snapshot</h3>
                    <div class="grid">
                        <div class="col-12 md:col-6" *ngFor="let snapshot of projectSnapshots" routerLink="{{snapshot.route}}">
                            <div class="count flex align-items-center justify-content-between bg-white border-round-xl cp">
                                <span class="material-symbols-outlined {{snapshot.class}}">
                                    {{snapshot.asset}}
                                </span>
                                <div class="wrap-info flex align-items-center justify-content-center flex-column">
                                    <h1 class="h3 mt-0 mb-3">{{snapshot.count}}</h1>
                                    <span class="body1">{{snapshot.title}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 p-0 lg:col-12 xl:col-5 sm:col-12 mt-4">
                <div class="grid grid-nogutter">
                    <div class="col-12 p-0 md:col-12 sm:col-12" *ngFor="let details of projectDetails">
                        <div class="last-project p-0 border-round-2xl bg-white mb-4">
                            <div class="card-head flex align-items-center justify-content-between">
                                <h4 class="h6 m-0">
                                    {{details.heading}}
                                </h4>
                                <p-button [rounded]="true" [text]="true" icon="true" plain="true" (click)="onEdit($event,details.project.project)">
                                    <span class="material-symbols-outlined">
                                        edit
                                    </span>
                                </p-button>
                            </div>
                            <div class="card-body">
                                <span class="time-detail">
                                    {{details.project.updatedAt | date:'MM-dd-yyyy h:mma' }} by
                                    {{details.project.updatedBy.firstName}} {{details.project.updatedBy.lastName}}
                                </span>
                                <div class="wrap-details grid grid-nogutter">
                                    <div class="col-6 p-0">
                                        <div class="client-detail">
                                            <h6 class="m-0">Client name</h6>
                                            <p class="m-0">{{details.project.project.caption}}</p>
                                        </div>
                                    </div>
                                    <div class="col-6 p-0">
                                        <div class="code-detail">
                                            <h6 class="m-0">Code name</h6>
                                            <p class="m-0">{{details.project.project.codeName}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 p-0 md:col-12 sm:col-12">
                <div class="wrap-lower-body border-round-2xl bg-white mb-4 p-4">
                    <div class="grid grid-nogutter">
                        <div class="col-12 sm:col-12 md:col-12">
                            <div class="chart-header">
                                <h3 class="h6 mb-2">Candidate Hours</h3>
                            </div>
                        </div>
                        <div class="col-12 md:col-6 xl:col-8 lg:col-6 sm:col-12 p-0">
                            <div class="wrap-left">
                               <div class="grid grid-nogutter w-full">
                                    <div class="col-12 sm:col-12 md:col-12 lg:col-8 xl:col-8 flex p-0">
                                        <div class="grid grid-nogutter w-full">
                                            <div class="md:pr-1 sm:pr-0 pl-0 col-12 md:col-6 sm:col-12 ">
                                                <p-multiSelect 
                                                    [options]="projects" 
                                                    [(ngModel)]="selectedProjects" 
                                                    optionValue="_id" 
                                                    (onChange)="onProjectChange($event)"
                                                    optionLabel="codeName"
                                                    placeholder="Select a Project" />
                                            </div>
                                            <div class="my-2 md:my-0 md:pl-2 sm:pl-0 col-12 md:col-6 sm:col-12">
                                                <p-multiSelect 
                                                    [options]="projectRoles" 
                                                    [(ngModel)]="selectedRoles" 
                                                    optionValue="_id" 
                                                    (onChange)="onRoleChange($event)"
                                                    optionLabel="roleType"
                                                    placeholder="Select a Role" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-0 col-12 md:col-12 sm:col-12">
                                        <ul class="chips">
                                            <li *ngIf="selectedProjectNames.length"><p-chip label="{{selectedProjectNames}}" [removable]="true" /></li>
                                            <li *ngIf="selectedRoleNames.length"><p-chip label="{{selectedRoleNames}}" [removable]="true" /></li>
                                        </ul>
                                    </div>
                               </div>
                            </div>
                        </div>
                        <div class="col-12 md:col-6 xl:col-4 lg:col-6 sm:col-12 p-0 flex md:justify-content-end justify-content-center my-2 md:my-0">
                            <div class="wrap-right">
                                <ul class="counts m-0 flex gap-3">
                                    <li class="py-2 px-4 border-round-xl">
                                        <span class="h5">{{dailyDuration.hours}}:{{dailyDuration.minutes}}:{{dailyDuration.seconds}}</span>
                                        <p class="m-0">Daily</p>
                                    </li>
                                    <li class="py-2 px-4 border-round-xl">
                                        <span class="h5">{{weekDuration.hours}}:{{weekDuration.minutes}}</span>
                                        <p class="m-0">Weekly</p>
                                    </li>
                                    <li class="py-2 px-4 border-round-xl">
                                        <span class="h5">{{totalDuration.hours}}:{{totalDuration.minutes}}</span>
                                        <p class="m-0">Total</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 sm:col-12 md:col-12" *ngIf="chartLabels.length">
                            <div class="chart">
                                <app-chart [labels]="chartLabels" chartHeight="600px" [datasets]="datasets"></app-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>