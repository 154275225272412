<div class="grid grid-nogutter mb-3">
    <div class="col-12 md:col-6 sm:col-12 sm:mb-2">
      <h1 class="h4 m-0">Credentialing</h1>
    </div>
    <div
      class="col-12 md:col-6 sm:col-12 flex md:justify-content-end justify-content-between mt-3 md:mt-0 align-items-start sm:align-items-center md:gap-4 gap-0"
    >
      <p-button
        label="CREATE"
        [rounded]="true"
        icon="pi pi-plus"
        size="large"
        (onClick)="goToCreate()"
        [ngStyle]="{ 'margin-right': '12px' }"
      />
      <p-button
        label="FAVOURITES"
        [rounded]="true"
        size="large"
        outlined="true"
        severity="primary"
      />
    </div>
  </div>
  <dynamic-table
    [title]="dynamicCredentialTitle"
    [dynamicCredential]="dynamicCredential"
    (refreshEvent)="handleRefresh()"
  ></dynamic-table>
  