import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../../../../shared/services/storage.service';
import { ReviewerService } from '../../../../shared/services/reviewer.service';
import { Reviewer } from '../../../../store/reviewer/reviewer.interface';
import { EmittersService } from '../../../../shared/services/emitters.service';

@Component({
  selector: 'app-education',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './edit-profile-main.component.html',
  styleUrl: './edit-profile-main.component.scss'
})
export class EditProfileMainComponent implements OnInit {

  credentials = [];
  skillsAndExperience = [];
  reviewer!: Reviewer;
  constructor(private router:Router, private storageService: StorageService, private reviewerService: ReviewerService, private eventEmitterService: EmittersService){

  }

  ngOnInit(): void {
    this.reviewer = this.reviewerService.getReviewer();
    this.getCredentials();
    this.getSkillAndExperience();
    this.subscribeEvents();
  }

  subscribeEvents(){
    this.eventEmitterService.tenantEmitter.subscribe(async () => {
      this.getCredentials();
      this.getSkillAndExperience();
    });
  }

  getCredentials(): void{
    this.reviewerService.getFilteredCriteriaBasedUserSkills(this.reviewer._id).subscribe((credentials) => {
      this.credentials = credentials;
    })
  }

  getSkillAndExperience(): void{
    this.reviewerService.getFilteredCriteriaBasedUserSkills(this.reviewer._id).subscribe((skillsAndExperience) => {
      this.skillsAndExperience = skillsAndExperience;
    })
  }

  goto(url:string){
    this.router.navigateByUrl(url);
  }
}
