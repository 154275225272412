<app-heading [title]="'Pending Candidate'" [isbreadcrumb]="false" [showToggle]="false"></app-heading>
<div class="projects-data">
    <div class="grid grid-nogutter">
        <div class="col-12 p-0">
            <div class="data-table">
                <p-table styleClass="p-datatable-sm p-datatable-sm--normal"
                [value]="reviewers" 
                [lazy]="true"
                [rows]="10"
                [paginator]="true"
                [totalRecords]="reviewerCount"
                (onLazyLoad)="getReviewers($event)"
                [showCurrentPageReport]="true" 
                [paginator]="true" 
                [tableStyle]="{ 'min-width': '50rem' }"
                    currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                    autoLayout="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="firstName" style="width:20%">
                                Name <p-sortIcon field="firstName" />
                            </th>
                            <th pSortableColumn="email" style="width:20%">
                                Email <p-sortIcon field="email" />
                            </th>
                            <th>
                                Rating
                            </th>

                            
                            <th>
                                Actions
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-reviewer>
                        <tr>
                            <td><span class="cursor-pointer" (click)="openReviewerDialog(reviewer._id)">
                                {{reviewer.firstName}} {{reviewer.lastName}}
                            </span></td>
                            <td>{{reviewer.email}}</td>
                            <td>
                                <p-rating [(ngModel)]="reviewer.averageRating" [readonly]="true" [cancel]="false" class="cursor-default" />
                            </td>                            
                            <td>
                                <div class="wrap-actions">
            
                                    <p-button (click)="showInfoDialog($event,reviewer)" [rounded]="true" [text]="true" icon="true">
                                        <span class="material-symbols-outlined">
                                            info
                                        </span>
                                    </p-button>
                                        <p-button (click)="approveReviewer($event,reviewer)" [rounded]="true" [text]="true" icon="true">
                                            <span class="material-symbols-outlined">
                                                select_check_box
                                            </span>
                                        </p-button>
                                        <p-button [pTooltip]="'Archive'" tooltipPosition="top" (click)="showDeleteReviewerConfirmation($event,reviewer)" [rounded]="true" [text]="true" icon="true">
                                            <span class="red material-symbols-outlined">
                                                delete
                                            </span>
                                        </p-button>
                                    
                                    
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>


