import { Component, OnInit } from '@angular/core';
import { HeadingComponent } from "../../../../shared/components/heading/heading.component";
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { ReviewerService } from '../../../../shared/services/reviewer.service';
import { DocumentService } from '../../../../shared/services/document-service';
import { DynamicCredentialService } from '../../../../shared/services/dynamic-credential.service';
import { Reviewer } from '../../../../store/reviewer/reviewer.interface';
import { lastValueFrom } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Cases, CriteriaSelectedOption, ResumeGenerator, SelectedCriteria, SelectedCriteries } from '../profile.interface';
import { ReviewerCredential } from '../credentials/credentials.model';
import { ChipModule } from 'primeng/chip';

@Component({
  selector: 'app-resume-generator',
  standalone: true,
  templateUrl: './resume-generator.component.html',
  styleUrl: './resume-generator.component.scss',
  imports: [HeadingComponent, ButtonModule, TableModule, CommonModule, ChipModule]
})
export class ResumeGeneratorComponent implements OnInit {
  dummy!: any[];
  headingButton = {
    url: '/reviewer/profile/education/create',
    label: 'Generate Resume'
  }
  reviewer!: Reviewer;
  cases: Cases = [];
  reviewerCredentials!: ReviewerCredential[];
  reviewerCriteries: SelectedCriteries = [];
  constructor(private reviewerService: ReviewerService,
    private dynamicCredentialService: DynamicCredentialService,
    private documentService: DocumentService
  ) { }



  async ngOnInit() {

    await this.getReviewerInfo();
    await this.getCases();
    await this.getReviewerCredentials();
    await this.getReviewerCriteria();
  }

  async getReviewerCriteria() {
    const reviewerCriteria = await lastValueFrom(this.reviewerService.getReviewerCriteria(this.reviewer._id));
    reviewerCriteria.map((criteria: SelectedCriteria) => {
      if (criteria.criteriaSelectedOption.length > 0) {
        const criteriaSelectedOption: any = [];
        criteria.criteriaSelectedOption.map((option: CriteriaSelectedOption) => {
          criteriaSelectedOption.push(option.val);
        })
        criteria.criteriaSelectedOption = criteriaSelectedOption;
        this.reviewerCriteries.push(criteria);
      }

    })

  }
  async getReviewerInfo() {
    this.reviewer = await lastValueFrom(this.reviewerService.fetchReviewer());
  }
  async getCases() {
    this.cases = await lastValueFrom(this.reviewerService.getCases());
  }
  async getReviewerCredentials(): Promise<void> {
    this.reviewerCredentials = await lastValueFrom(
      this.dynamicCredentialService.getReviewerCredentials(this.reviewer._id)
    );

  }
  generateResume() {
    const payload = {
      userId: this.reviewer._id,
      userName: `${this.reviewer.firstName} ${this.reviewer.lastName}`,
      educations: this.reviewer.profile.educations,
      reviewerCredentials: this.reviewerCredentials,
      reviewerCriteries: this.reviewerCriteries
    }
    this.reviewerService.generateResume(payload).subscribe((resp: ResumeGenerator) => {
      const resumeLink = this.documentService.downloadUserResume();
      window.open(resumeLink, '_self');
    })
  }
}
