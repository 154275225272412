import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { constants, UserRoles } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class ReviewerAuthRoleGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private cookieService:CookieService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let role = this.cookieService.get('role');
    if (this.authService.isAuthenticated() && role === constants.userRoles.reviewer){
        return true;
    }else{
        this.router.navigateByUrl('/login');
        return false;
    }
  
    
  }
}
