import { Component, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { RatingModule } from 'primeng/rating';
import { CommonModule } from '@angular/common';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormsModule } from '@angular/forms';
import { PeopleService } from '../../../../../../../shared/services/people.service';
import { constants } from '../../../../../../../shared/constants/constants';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastService } from '../../../../../../../shared/services/toast.service';

@Component({
  selector: 'app-rate-bulk-freelancers',
  standalone: true,
  imports: [CommonModule, DialogModule, ButtonModule, CheckboxModule, DynamicDialogModule, RatingModule, FormsModule, OverlayPanelModule, TooltipModule],
  templateUrl: './rate-bulk-freelancers.component.html',
  styleUrl: './rate-bulk-freelancers.component.scss',
})
export class RateBulkFreelancersComponent implements OnInit {
  ratingInfo: any = {
    roleReviewers: []
  };
  minRating = constants.stars.minRating;

  constructor(
    private rateProjectRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private peopleService: PeopleService,
    private toastService: ToastService
  ){

  }

  ngOnInit() {
    this.ratingInfo = {...this.config.data, roleReviewers: [this.config.data.reviewers]};
    this.initializeRatingModal();
  }

  initializeRatingModal(){
    this.ratingInfo.roleReviewers.forEach((roleReviewer: any, roleIndex: number) => {
      roleReviewer.selectAll = true;
      roleReviewer.ratingAll = constants.stars.default;
      roleReviewer.roleIndex = roleIndex;
      roleReviewer.reviewers.forEach((reviewer: any, index: number) => {
        reviewer.showComment = false;
        reviewer.rating = constants.stars.default;
        reviewer.selected = true;
        reviewer.workTogether = reviewer.rating >= this.minRating;
        reviewer.reviewerIndex = index;
        reviewer.notes = '';
      });
    });
  }

  countReviewerRatedState(reviewers: Array<any>, isRated: boolean) {
    return reviewers.filter( (reviewer: any) => {
        return reviewer.rated === isRated;
    });
  }

  clickFavourite(roleIndex: number, reviewerIndex: number) {
    if (!this.ratingInfo.roleReviewers[roleIndex].reviewers[reviewerIndex].isRestricted) {
      const isFavourite = this.ratingInfo.roleReviewers[roleIndex].reviewers[reviewerIndex].profile.favorite;
      const reviewerId = this.ratingInfo.roleReviewers[roleIndex].reviewers[reviewerIndex]._id;
        if (isFavourite) {
            this.removeAsFavourite(roleIndex, reviewerIndex, reviewerId);
        }
        else {
            this.setAsFavourite(roleIndex, reviewerIndex, reviewerId);
        }
    }
  }

  onClickRestrict(roleIndex: number, reviewerIndex: number, reviewer: any){
    this.addToRestrictedList(roleIndex, reviewerIndex);
    this.applyWorkTogether(reviewer);
  }

  setAsFavourite(roleIndex: number, reviewerIndex: number, reviewerId: string) {
    const fav = {
      favorite: reviewerId
    };
    this.peopleService.addToFavouriteList(this.ratingInfo.firmId, fav).subscribe({
      next: () => {
        this.ratingInfo.roleReviewers[roleIndex].reviewers[reviewerIndex].profile.favorite = true;
        this.ratingInfo.roleReviewers[roleIndex].reviewers[reviewerIndex].rating = constants.stars.favorite;
      },
    });
  }

  removeAsFavourite(roleIndex: number, reviewerIndex: number, reviewerId: string) {

    this.peopleService.removeFromFavouriteList(this.ratingInfo.firmId, reviewerId).subscribe({
      next: () => {
        this.ratingInfo.roleReviewers[roleIndex].reviewers[reviewerIndex].profile.favorite = false;
      }
    });
  }

  addToRestrictedList(roleIndex: number, reviewerIndex: number){

    this.ratingInfo.roleReviewers[roleIndex].reviewers[reviewerIndex].isRestricted = !this.ratingInfo.roleReviewers[roleIndex].reviewers[reviewerIndex].isRestricted;
    this.ratingInfo.roleReviewers[roleIndex].reviewers[reviewerIndex].readOnlyRating = false;
    if (this.ratingInfo.roleReviewers[roleIndex].reviewers[reviewerIndex].isRestricted) {
        this.removeAsFavourite(roleIndex, reviewerIndex, this.ratingInfo.roleReviewers[roleIndex].reviewers[reviewerIndex]._id);
        this.ratingInfo.roleReviewers[roleIndex].reviewers[reviewerIndex].rating = constants.stars.restricted;
    }
  }

  applyWorkTogether(reviewer: any) {
    if(!reviewer.rated){
      if (reviewer.rating < this.minRating) {
        reviewer.workTogether = false;
      } else {
          if (!reviewer.isRestricted) {
              reviewer.workTogether = true;
          }
      }
    }
  }

  toggleWorkTogether(reviewer: any, roleIndex: number, reviewerIndex: number) {
    reviewer.workTogether = !reviewer.workTogether;
    if(reviewer.workTogether){
      this.ratingInfo.roleReviewers[roleIndex].reviewers[reviewerIndex].isRestricted = false;
    }
  }

  onConfirm(isConfirm: boolean): void{
    if(!isConfirm){
      this.rateProjectRef.close({
        isConfirm
      });
      return
    }

    const roleReviewersRating: any = {
      rate: []
    }; 
    const notes: Array<{ reviewer: string, message: string }> = [];


    this.ratingInfo.roleReviewers.forEach((role: any) => {
      const reviewers = role.reviewers.filter((reviewer: any) => reviewer.selected);
      reviewers.forEach((reviewer: any) => {
        const rate: any = {};
        rate.role = role._id;
        rate.stars = reviewer.rating;
        rate.isRestricted = reviewer.isRestricted;
        rate.rated = reviewer.rated;
        roleReviewersRating.rate.push(rate);
        rate.position = reviewer.position;
        reviewer.note = reviewer.note ? reviewer.note.trim() : "";
        if (reviewer.note) {
            notes.push({ reviewer: reviewer._id, message: reviewer.note });
        }
        rate.workTogether = reviewer.rating < this.minRating ? false : reviewer.workTogether;
      });
    });

    if(!roleReviewersRating.rate.length){
      this.toastService.showError('Please select a reviewer');
      return;
    }

    this.rateProjectRef.close({
      isConfirm, 
      roleReviewersRating,
      notes
    });
  }

  reviewerSelectOnChange(roleIndex: number): void {
    let selectAll = true;

    this.ratingInfo.roleReviewers[roleIndex].reviewers.forEach((reviewer: any) => {
        if (!reviewer.selected) {
            selectAll = false;
            return;
        }
    });

    this.ratingInfo.roleReviewers[roleIndex].selectAll = selectAll;
  }

  selectAllReviewers(roleIndex: number): void {
    const reviewers = this.ratingInfo.roleReviewers[roleIndex].reviewers;

    if (this.ratingInfo.roleReviewers[roleIndex].selectAll) {
        reviewers.forEach((reviewer: any) => {
            if (!reviewer.rated) {
                reviewer.selected = true;
            }
        });
    } else {
        reviewers.forEach((reviewer: any) => {
            if (!reviewer.rated) {
                reviewer.selected = false;
            }
        });
    }
  }

  rateAllReviewersOfRole(roleIndex: number): void {
    const reviewers = this.ratingInfo.roleReviewers[roleIndex].reviewers;
    const ratingAll = this.ratingInfo.roleReviewers[roleIndex].ratingAll;
    const minRating = this.minRating;

    reviewers.forEach((reviewer: any) => {
        if (!reviewer.rated) {
            reviewer.rating = ratingAll;

            if (ratingAll < minRating) {
                reviewer.workTogether = false;
            } else {
                if (!reviewer.isRestricted) {
                    reviewer.workTogether = true;
                }
            }
        }
    });
  }


}
