import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProjectNavigationService {
  private projectStep = {
    current: 0
  };

  constructor() {}

  setCurrentStep(step: number): void {
    this.projectStep.current = step;
  }

  getCurrentStep(): number {
    return this.projectStep.current;
  }

  getProjectStep(): { current: number } {
    return this.projectStep;
  }

}
