import { Injectable } from '@angular/core';
import {HttpBaseService} from './http-base.service';
import { CookieService } from 'ngx-cookie-service';
import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})

export class TimeKeepingService {
  constructor(private http: HttpBaseService) { 
  }

  addTSheet(params: any) {
    params.timezone_abbrevation = moment.tz.guess();
    params.timezone = moment().format('Z');
    return this.http.post('timeSheets', params);
  }

  updateTSheet(params: any) {
      return this.http.put('timeSheets/' + params.id, params);
  }

  getTsheets(params: any) {
      let query = '?';
      if (params && params.role_id) {
          query = '?role_id=' + params.role_id;
      }
      if (params && params.project_id) {
          query += '&project_id=' + params.project_id;
      }
      if(params && params.dateRange){
          query += '&startDate='+ params.dateRange.startDate.toISOString() +'&endDate='+params.dateRange.endDate.toISOString();
      }
      if (params && params.filter) {
          query = query ? query + '&filter=' + params.filter : '?filter=' + params.filter;
      }
      if (params && params.position) {
          query += '&position=' + params.position;
      }
      if (params && params.weekId) {
          query += '&weekId=' + params.weekId;
      }
      return this.http.get( 'timeSheets' + query);
  }

  getTsheetsByFirm(params: any) {
      let payload: any = {};
      if (params && params.firmId) {
          payload.firmId = params.firmId;
      }
      if (params && params.roleIds) {
          payload.roleIds = params.roleIds;
      }
      if (params && params.projectIds) {
          payload.projectIds = params.projectIds;
      }
      if(params && params.dateRange){
          payload.startDate = params.dateRange.startDate.toISOString();
          payload.endDate = params.dateRange.endDate.toISOString();
      }
      return this.http.post( 'timeSheets/byfirm', payload);
  }

  deleteTSheet(params: any) {
      let query = '';
      if (params && params.role_id) {
          query = '?role_id=' + params.role_id;
      }
      if (params && params.weekId) {
          query = '?weekId=' + params.weekId;
      }
      if (params && params.contractorTimekeepingEnabled) {
          query = '?contractorTimekeepingEnabled=' + params.contractorTimekeepingEnabled;
      }
      return this.http.delete('timeSheets/' + params._id + query);
  }

  secondsToH(secs: any) {
      let hours = Math.floor(secs / (60 * 60)).toString();
      if (Number(hours) < 10 && Number(hours) >= 0) {
          hours = '0' + hours;
      }

      if (Number(hours) < 0 || isNaN(Number(hours))  ) {
          hours = '--';
      }
      return hours;
  }

  hoursDecimal(secs: any) {
      return Number((secs/3600).toFixed(2));
  }

  secondsToHM(secs: any) {
      let hours = Math.floor(secs / (60 * 60)).toString();
      let minutes = Math.floor(secs % (60 * 60) / 60).toString();
      if (Number(hours) < 10 && Number(hours) >= 0) {
          hours = '0' + hours;
      }
      if (Number(minutes) < 10 && Number(minutes) >= 0) {
          minutes = '0' + minutes;
      }
      if (Number(hours) < 0 || Number(minutes) < 0 || isNaN(Number(hours)) || isNaN(Number(minutes))) {
          hours = '--';
          minutes = '--';
      }
      return hours + ' : ' + minutes;
  }

  secondsToHMS(secs: any) {
      let hours = Math.floor(secs / (60 * 60)).toString();
      let minutes = Math.floor(secs % (60 * 60) / 60).toString();
      let seconds = Math.floor(secs % 60).toString();;
      if (Number(hours) < 10 && Number(hours) >= 0) {
          hours = '0' + hours;
      }
      if (Number(minutes) < 10 && Number(minutes) >= 0) {
          minutes = '0' + minutes;
      }
      if (Number(seconds) < 10 && Number(seconds) >= 0) {
          seconds = '0' + seconds;
      }
      if (Number(hours) < 0 || Number(minutes) < 0 || Number(seconds) < 0 || isNaN(Number(hours)) || isNaN(Number(minutes)) || isNaN(Number(seconds))) {
          hours = '--';
          minutes = '--';
          seconds = '--';
      }
      return { hours: hours, minutes: minutes, seconds: seconds };
  }

  getTimekeepingAdmin(body: any) {
    return this.http.post('adminTimesheet/list', body);
  }

  addTimekeepingAdmin(params: any) {
    return this.http.post('adminTimesheet', params);
  }

  sendInvoice(params: any){
    return this.http.post('timeSheets/sendInvoiceByContractor', params);
  }

  updateTimekeepingAdmin(params: any) {
    return this.http.put('adminTimesheet/' + params.timeSheetId, params);
  }

  deleteTimekeepingAdmin(id: string) {
    return this.http.delete('adminTimesheet/' + id);
  }

  getInvoicesByWeek(params: any) {
    return this.http.post('timeSheets/getInvoices', params);
  }

  getInvoicesByFilter(params: any) {
    return this.http.post('timeSheets/searchInvoices', params);
  }

  lockInvoices(params: any) {
    return this.http.post('timeSheets/lockInvoices', params);
  }

}
