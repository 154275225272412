import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ChipModule } from 'primeng/chip';
import { IconFieldModule } from 'primeng/iconfield';
import { InputTextModule } from 'primeng/inputtext';
import { InputIconModule } from 'primeng/inputicon';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { HeadingComponent } from '../../../../../shared/components/heading/heading.component';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { SearchMarkets, SearchMarket, SearchReviewerQueryParams, SearchReviewerResponse, Reviewer, WorkedTogetherResponse } from '../people.interface';
import { PeopleService } from '../../../../../shared/services/people.service';
import { ConfigurationService } from '../../../../../shared/services/configuration.service';
import { constants } from '../../../../../shared/constants/constants';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RatingModule } from 'primeng/rating';
import { ConfirmationDialogService } from '../../../../../shared/services/confirmation-dialog.service';
import { ToastService } from '../../../../../shared/services/toast.service';
import { ReviewerDetailDialogService } from '../../../../../shared/services/reviewer-detail-dialog.service';
import { TooltipModule } from 'primeng/tooltip';
import { UserClientVm } from '../../../../../store/user/user.interface';
import { UserService } from '../../../../../shared/services/user.service';
@Component({
  selector: 'app-restricted',
  standalone: true,
  imports: [DropdownModule, ButtonModule, FormsModule, RatingModule, ChipModule, IconFieldModule, InputIconModule, InputSwitchModule , HeadingComponent, InputTextModule, TableModule, CommonModule, TooltipModule],
  templateUrl: './restricted.component.html',
  styleUrl: './restricted.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class RestrictedComponent implements OnInit {
  headingButton = {
    url: '/client/people/invite-candidate',
    label: 'INVITE CANDIDATE',
    icon: 'x',
    color: '#d65289'
  }
  pendingCandidateButton = {
    url: '/client/people/pending-candidates',
    label: 'PENDING CANDIDATES',
    icon: 'x'
  }
  sortings: any = [];
  userFilters: any = [];  
  selectedSortOrder =  {
    name: '',
    value: ''
  }
  reviewerCount: number = 0;
  searchMarkets: any = [];
  reviewers : Reviewer[] = [];
  showMultiTenantFeature : any;
  selectedUserFilter =  { 
    name: 'Active', value: 'active' 
  };
  searchText = '';
  displaySearchTextChip = false;
  searchTextDisplaying = '';
  userObject!: UserClientVm;

  constructor(private route: ActivatedRoute, private peopleService: PeopleService, private configurationService:ConfigurationService,
    private confirmationDialogService: ConfirmationDialogService, private toast: ToastService, private reviewerDetailDialogService:ReviewerDetailDialogService, private userService: UserService
  ) {
  }
  ngOnInit() {
    this.userObject = this.userService.getSessionUser();
    this.showMultiTenantFeature  = this.configurationService.configuration[constants.features.MULTI_TENANT as keyof typeof this.configurationService.configuration];
    this.getSearchMarket();
    this.sortings = constants.sortingFilter
    this.userFilters = [
      { name: 'Active', value: 'active' },
      { name: 'Inactive', value: 'inactive' },
      { name: 'All', value: 'all' },
     
    ];
    this.getPendingReviewers();

  }
  onSortChange(event: any) {
    this.selectedSortOrder = event.value;
    this.getReviewers({first:0,rows:10});
  }
  onFilterChange(event: any) {
    this.selectedUserFilter = event.value;
    this.getReviewers({first:0,rows:10});
  }
  getSearchMarket() {
    const searchMarkets: SearchMarkets = this.route.snapshot.data['searchMarket'];
    searchMarkets.map((d: SearchMarket) => {
      this.searchMarkets.push(d._id);
    })
  }
  onEnter($event: any) {
    if ($event.keyCode === 13) {
      this.onSearchClick();
    }
  }
  onSearchClick() {
      this.getReviewers({ first: 0, rows: 10 });
      this.searchTextDisplaying = this.searchText.slice(0);
      this.displaySearchTextChip = this.searchText === '' ? false : true;
  }
  onRemoveChip(type:string){
    if(type === 'sortOrder'){
      this.selectedSortOrder = {
        name: '',
        value: ''
      };
    }else if(type === 'userFilter'){
      this.selectedUserFilter = {
        name: '',
        value: ''
      };
    }else{
      this.searchText = '';
      this.searchTextDisplaying = '';
      this.displaySearchTextChip = false;
    }
    this.getReviewers({first:0,rows:10});
  }
  getReviewers(event?: any) {
    const offset = event.first;
    const limit = event.rows;
    const queryParams: SearchReviewerQueryParams = {
      sortOrder: constants.sortingOrder.descending,
      skip: offset,
      take: limit,
      markets: this.searchMarkets.join(','),
      userStatus: 'active'
    };

    if (this.selectedSortOrder.value) {
      queryParams.sortBy = this.selectedSortOrder.value;
    }
    if(this.selectedUserFilter.value){
      queryParams.userStatus = this.selectedUserFilter.value;
    }
    if(this.searchText){
      queryParams.search = this.searchText;
    }
    this.peopleService.getRestrictedReviewersrList(queryParams).subscribe((response: WorkedTogetherResponse)=>{
      this.reviewers = response.reviewers;
      this.reviewerCount = response.totalReviewersCount;
    })
  }

  removeFromRestrictedList($event: Event, reviewer: Reviewer) {
    const mesg = `You are about to remove ${reviewer.firstName} ${reviewer.lastName} from Restricted list. Are you sure?`
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message: mesg,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.peopleService.removeFromRestrictedList(reviewer._id).subscribe((response: any) => {
          this.getReviewers({ first: 0, rows: 10 });
          this.toast.showSuccess('Removed from restricted list')
        })
      }
    });

  }
  openReviewerDialog(id:string){
    this.reviewerDetailDialogService.openDialog(id);
  }

  getPendingReviewers() {
    const offset = 0;
    const limit = 10;
    const queryParams: SearchReviewerQueryParams = {
      sortOrder: constants.sortingOrder.descending,
      offset: offset,
      limit: limit,
      searchOn: 'all',
    };
    this.peopleService.getPendingCandidateList(queryParams, this.userObject.firm._id).subscribe((response: SearchReviewerResponse) => {
      this.pendingCandidateButton.label = `PENDING CANDIDATES (${response.count})`;
    })
  }
}