import { Component } from '@angular/core';
import { UserService } from '../../../../../../../shared/services/user.service';
import { PeopleService } from '../../../../../../../shared/services/people.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from '../../../../../../../shared/services/toast.service';
import { saveAs } from 'file-saver';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { environment } from '../../../../../../../../environments/environment';

@Component({
  selector: 'app-bulk-invite',
  standalone: true,
  imports: [
    ButtonModule,
    TableModule
  ],
  templateUrl: './bulk-invite.component.html',
  styleUrl: './bulk-invite.component.scss'
})
export class BulkInviteComponent {

  user: any;
  firmId: string | undefined;
  csvPaginatedData: any[] = [];
  csvOrignalData: any[] = [];
  duplicateCsvUsers: string[] = [];
  pagination = {
    numPerPage: 25,
    currentPage: 1,
    maxSize: 25
  };
  csvData: any[] = [];
  expectedHeaders = ['First Name', 'Last Name', 'Email', 'Phone Number', 'Job type 1'];
  file: File | null = null;
  title = environment.title;
  jobTypeHeaders: Array<string> = [];
  constructor(
    private ref: DynamicDialogRef,
    private userService: UserService,
    private peopleService: PeopleService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.user = this.userService.getSessionUser();
    this.firmId = this.user?.firm?._id;
  }

  uploadCSV(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      this.file = input.files[0];
      this.readFile(this.file);
    }
  }

  bulkInviteCandidateSubmit(): void {
    const users = this.csvOrignalData.filter(user => !this.duplicateCsvUsers.includes(user.email));
    const body = {
      firmId: this.firmId,
      users: users,
      isBulk: true
    };

    this.peopleService.inviteCandidates(body).subscribe({
      next: (response) => {
        this.ref.close({
          isSuccess: true,
          users: response.map((user: any) => {
            const selectedUser = users.find((csvUser: any) => user.email === csvUser.email);
            if(selectedUser && selectedUser.jobTypes.length){
              return {...user, jobTypes: selectedUser.jobTypes};
            }
            return user;
          })
        });
      },
      error: (error) => {
        this.toastService.showError(error?.data?.message ?? 'Error while requesting.');
        this.ref.close(null);
      }
    });
  }

  parseCSV(csvText: string): any[] {
    const lines = csvText.split(/\r?\n|\r/);
    const result: any[] = [];
  
    const headers = this.parseCSVLine(lines[0]);
  
    const staticHeaders = ["First Name", "Last Name", "Email", "Phone Number"];
    this.jobTypeHeaders = headers.filter(header => header.toLowerCase().startsWith("Job Type".toLowerCase()));
  
    for (const header of staticHeaders) {
      if (!headers.includes(header)) {
        this.toastService.showError('Required headers are missing');
        return [];
      }
    }
  
    for (let i = 1; i < lines.length; i++) {
      if (!lines[i].trim()) {
        continue;
      }
      const currentline = this.parseCSVLine(lines[i]);
      
      const obj: any = {};
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }
      result.push(obj);
    }
  
    this.csvOrignalData = [];
    for (const record of result) {
      if (!record["First Name"] || !record["Last Name"] || !record["Email"]) {
        this.toastService.showError('Required fields are missing');
        this.csvOrignalData = [];
        break;
      }
      if (record["Phone Number"] && record["Phone Number"].toString().length > 10) {
        this.toastService.showError('One of the Phone Numbers is more than the allowed length (10 digits)');
        this.csvOrignalData = [];
        break;
      }
  
      const jobTypes: string[] = [];
      for (const jobHeader of this.jobTypeHeaders) {
        if (record[jobHeader]) {
          jobTypes.push(record[jobHeader]);
        }
      }
  
      if (this.csvOrignalData.find((data: any) => data.email === record["Email"]) && jobTypes.length) {
        this.csvOrignalData = this.csvOrignalData.map((csvData: any) => {
          if(record["Email"] === csvData.email){
            return {...csvData, jobTypes}
          }
          return csvData;
        })
      } else if(!(this.csvOrignalData.find((data: any) => data.email === record["Email"]))){
        this.csvOrignalData.push({
          firstName: record["First Name"],
          lastName: record["Last Name"],
          email: record["Email"],
          phone: record["Phone Number"],
          jobTypes: jobTypes
        });
      }
    }

    return this.csvOrignalData;
  }

parseCSVLine(line: string): string[] {
  const result = [];
  let currentField = '';
  let insideQuotes = false;

  for (let i = 0; i < line.length; i++) {
    const char = line[i];

    if (char === '"') {
      if (insideQuotes && line[i + 1] === '"') {
        currentField += '"';
        i++;
      } else {
        insideQuotes = !insideQuotes;
      }
    } else if (char === ',' && !insideQuotes) {
      result.push(currentField.trim());
      currentField = '';
    } else {
      currentField += char;
    }
  }
  result.push(currentField.trim());

  return result;
}

  readFile(file: File): void {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      if (reader.result) {
        this.csvData = this.parseCSV(reader.result as string);
        this.checkDuplication(this.csvData);
        this.csvPaginatedData = this.configureCsvPaginatedData();
      }
    };
  }

  checkDuplication(data: any[]): void {
    const emails = data.map(e => e.email);
    this.peopleService.checkEmailExistence(emails).subscribe(response => {
      if (response && response.data) {
        this.duplicateCsvUsers = response.data;
      }
    });
  }

  configureCsvPaginatedData(): any[] {
    const currentPage = this.pagination.currentPage - 1;
    const perPage = this.pagination.numPerPage;
    return this.csvOrignalData.slice(currentPage * perPage, this.pagination.currentPage * perPage);
  }

  downloadCSVTemplate(): void {
    const csv: string[] = [];
    const headers = this.expectedHeaders.join(',') + '\n';
    csv.push(headers);

    const users = [
      { firstName: 'Test1', lastName: 'User', email: 'test1.user@yopmail.com', phoneNo: '1312313123', 'Job type 1': 'Job type value 1' },
      { firstName: 'Test2', lastName: 'User', email: 'test2.user@yopmail.com', phoneNo: '1231212312', 'Job type 1': 'Job type value 2' }
    ];

    users.forEach(user => {
      const cols = [
        user.firstName,
        user.lastName,
        user.email,
        user.phoneNo,
        user['Job type 1']
      ].join(',') + '\n';

      csv.push(cols);
    });

    const blob = new Blob(csv, { type: "text/plain;charset=utf-8" });
    saveAs(blob, "sampleBulkUpload.csv");
  }

  pageChanged(): void {
    this.csvPaginatedData = this.configureCsvPaginatedData();
  }

  checkIfExist(email: string): boolean {
    return this.duplicateCsvUsers.includes(email);
  }

  cancel(): void {
    this.ref.close();
  }
}
