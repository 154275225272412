<div class="align-items-center">
    @if(!roleStarted){
        <div class="col-12 mb-3 p-0">
            The client has been informed that you are not able to work on Project <b>{{roleEndedInfo?.project?.codeName}}</b> Role <b>{{roleEndedInfo?.role?.roleType}}</b>. You will now be eligible to receive new offers.
        </div>
    }

    @if(roleStarted && !hasTsheet){
        <div class="col-12 mb-3 p-0">
            <p>Thank you for your efforts on Project: <b>{{roleEndedInfo?.project?.codeName}}</b> Role: <b>{{roleEndedInfo?.role?.roleType}}</b>. The client has been informed that you have stopped working on the project. After validation, please rate the project.</p>
        </div>
    }

    @if(roleStarted && hasTsheet){
        <div class="col-12 mb-3 p-0">
            <p>Thank you for your efforts on Project: <b>{{roleEndedInfo?.project?.codeName}}</b> Role: <b>{{roleEndedInfo?.role?.roleType}}</b>. The client has been informed that you have stopped working on the project. After verification of your hours, please rate the project.</p>
        </div>
        <div class="col-12 mb-3 p-0">
            <p>Do you certify that your time on Project: <b>{{roleEndedInfo?.project?.codeName}}</b> Role: <b>{{roleEndedInfo?.role?.roleType}}</b> is accurate and complete?</p>
        </div>
    }

    @if(roleStarted && hasTsheet){
        <div class="flex justify-content-end gap-2">
            <p-button label="NO" (click)="confirm(false)" />
            <p-button label="YES" (click)="confirm(true)" />
        </div>
    }

    @if(!roleStarted || !hasTsheet){
        <div class="flex justify-content-end gap-2">
            <p-button label="OK" (click)="confirm(true)" />
        </div>
    }
    
</div>
