import { Component, OnInit } from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'job-type-modal',
  providers: [DialogService],
  standalone: true,
  imports: [
    FloatLabelModule,
    DialogModule,
    ButtonModule,
    FormsModule,
    InputTextModule,
    CommonModule,
    ReactiveFormsModule,
    DynamicDialogModule,
    DropdownModule,
  ],
  templateUrl: './create-job-type.component.html',
})
export class CreateEditJobTypeModalComponent implements OnInit {
  createForm!: FormGroup;
  buttonText: string = '';
  statusOptions = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ];

  constructor(
    public ref: DynamicDialogRef,
    private fb: FormBuilder,
    public dialogService: DialogService,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit() {
    this.createForm = this.fb.group({
      name: ['', [Validators.required]],
      description: [''],
    });
    this.buttonText = this.config?.data?.buttonText;

    if(this.config.header === "Edit") {
      this.createForm.controls['name'].setValue(this.config?.data?.name);
    this.createForm.controls['description'].setValue(this.config.data.description);
    }
  }

  onSubmit() {
    if (this.createForm.valid) {
      let jobType = this.createForm.value;
      this.ref.close(jobType);
    }
  }

  closeDialog() {
    this.ref.close();
  }
}
