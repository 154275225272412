import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import {StorageService} from '../services/storage.service';
import {constants} from '../constants/constants';
import { UserService } from '../services/user.service';
/**
 * Interceptor that adds an Authorization header to requests that are authenticated and target the API URL.
 *
 * @param request The request object.
 * @param next The next interceptor in the chain.
 *
 * @returns The next Observable.
 */
export const tokenInterceptor: HttpInterceptorFn = (request, next) => {

    const storage = inject(StorageService);
    const userService = inject(UserService);
    const token = storage.getToken();
    const isRequestAuthorized = token ? true : false;
    const currentUrl = request.url.split('api/')[1];
    
    if (isRequestAuthorized && !constants.publicRoutes.urls.includes(currentUrl)) {
        let tenantId = '';

        if(storage.getRole() == constants.userRoles.reviewer){
            tenantId = storage.getTenant()?.firm?._id || '';
        }
        else if(storage.getRole() == constants.userRoles.firmManager || constants.userRoles.projectManager || constants.userRoles.projectOwner){
            tenantId = userService.getUserFirmId() || '';
        }

        const clonedRequest = request.clone({
            setHeaders: {
                'X-Api-Token': `${token}`,
                'X-Tenant-Id':  `${tenantId}`
            }
        });
        return next(clonedRequest);
    }

    return next(request);
};