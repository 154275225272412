import { Component, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { RatingModule } from 'primeng/rating';
import { CommonModule } from '@angular/common';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormsModule } from '@angular/forms';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-cancel-offer-modal',
  standalone: true,
  imports: [CommonModule, DialogModule, ButtonModule, DynamicDialogModule, RatingModule, FormsModule, OverlayPanelModule, TooltipModule],
  templateUrl: './cancel-modal.component.html',
  styleUrl: './cancel-modal.component.scss',
})
export class CancelOfferModalComponent implements OnInit {

  offer: any = null;

  constructor(
    private cancelConfirmationRef: DynamicDialogRef,
    public config: DynamicDialogConfig
  ){ }

  ngOnInit() {
    this.offer = this.config.data;
  }

  onConfirm(isConfirm: boolean): void{
    this.cancelConfirmationRef.close(isConfirm);
  }
}
