import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EducationComponent } from './education.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '',
    component: EducationComponent
  },
  {
    path: 'create',
    loadComponent: () =>
      import('./add-school/add-school.component').then((c) => c.AddSchoolComponent),
    
  },
  {
    path: 'edit/:id',
    loadComponent: () =>
      import('./add-school/add-school.component').then((c) => c.AddSchoolComponent),
    
  },
  ];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EducationRoutingModule { }
