import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { Template } from '../../message.interface';
import { MessagesService } from '../../../../../../shared/services/messages-service.service';
import { ToastService } from '../../../../../../shared/services/toast.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-add-template',
  standalone: true,
  imports: [InputTextareaModule, InputTextModule, FloatLabelModule, FormsModule, ReactiveFormsModule, ButtonModule, TableModule, CommonModule],
  templateUrl: './add-template.component.html',
  styleUrl: './add-template.component.scss'
})
export class AddTemplateComponent {
  templateForm!: FormGroup;
  template: Template[] = [];
  buttonText = 'Create Template';

  constructor(
    private fb: FormBuilder,
    public ref: DynamicDialogRef,
    public messageService: MessagesService,
    public toastService: ToastService
  ){}

  ngOnInit(): void {
    this.templateForm = this.fb.group({
      subject: ['', [Validators.required]],
      content: ['', [Validators.required]],
      _id: ['']
    });
    this.getTemplates();
    
  }

  getTemplates(): void{
    this.messageService.getAllMessageTemplates().subscribe((res) => {
      this.template = res;
    })
  }

  onSubmit(): void {
    if (this.templateForm.valid) {
      let formValues = {
        ...this.templateForm.value
      }
      if(formValues._id){
        let payload = {
          subject: formValues.subject,
          content: formValues.content
        }
        this.messageService.updateMessageTemplate(formValues._id || "", payload ).subscribe((res) =>{
          this.toastService.showSuccess("Template edited.");
          this.buttonText = 'Create Template';
          this.getTemplates();
          this.templateForm.controls['subject'].enable();
          this.templateForm.reset();
        })
      }
      else{
        let payload = {
          subject: formValues.subject,
          content: formValues.content
        }
        this.messageService.createMessageTemplate(payload).subscribe({
          next: (res) => {
            this.getTemplates();
            this.toastService.showSuccess("New template added.")
            this.templateForm.reset();
          },
          error: (err) => {
          }
        })
      }
    }
  }

  closeDialog() {
    this.ref.close();
  }

  onEdit(temp: Template): void {
    this.buttonText = 'Edit Template';
    this.templateForm.controls['subject'].setValue(temp.subject);
    this.templateForm.controls['content'].setValue(temp.content);
    this.templateForm.controls['_id'].setValue(temp._id);
    this.templateForm.controls['subject'].disable();
  }

  onDelete(temp: Template): void{
    this.messageService.deleteMessageTemplate(temp._id || '').subscribe((res) => {
      this.toastService.showSuccess('Template deleted.');
      this.getTemplates();
    })
  }
}
