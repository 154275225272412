<div class="w-full">
  <form [formGroup]="createForm">
    <div class="flex flex-column gap-2">
      @if(reviewerName){
        <div>
          Candidate:  {{reviewerName}}
        </div>
      }
     @if(message){
        <div>{{message}}</div>
      }
      @if(isStartedRole){
      <div class="grid">
        <div class="lg:w-6 col-6 mt-3">
          <div class="mb-2">
            Please select a new start date for the candidate role.
          </div>
         
          <p-floatLabel class="h-full">
            <p-calendar [minDate]="minDate"
            inputId="start-date-selector" formControlName="positionStarted" placeholder="mm/dd/yyyy" [autofocus]="false" />
            <label for="start-date-selector">Start Date</label>
          </p-floatLabel>
        </div>
        <div class="lg:w-6 col-6">
          <div class="flex gap-4 align-items-center">
            <label for="time" class="text-sm font-medium block">Start Time</label>
            <p-calendar inputId="calendar-timeonly" formControlName="positionStarted" [autofocus]="true"  [timeOnly]="true" hourFormat="12"
            inline="true" styleClass="slider-style" />
          </div>
        </div>
      </div>
    }
    </div>

    <div class="flex gap-4 align-items-center" [ngClass]="{'justify-content-between':isStartedRole,'justify-content-end':!isStartedRole}">
      <!-- <div>
        Please click Yes to continue. If not, please click no
      </div> -->

      @if(isStartedRole){
      <div class="flex gap-2">
        Do you want to send an offer with this start date to {{reviewerName}} ?
      </div>  }
      <div class="flex gap-2" [ngClass]="{'mt-4':!isStartedRole}">
        <p-button label="YES" size="large" [rounded]="true" (onClick)="closeDialog(true)"
          [ngStyle]="{'margin-right': '10px'}" styleClass="freshgreen-background" />
        <p-button label="NO" size="large" [rounded]="true" (onClick)="closeDialog()" severity="danger" />
      </div>
    </div>
  </form>
</div>