import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { constants } from '../../../../../../../shared/constants/constants';
import { ToastService } from '../../../../../../../shared/services/toast.service';
@Component({
    providers: [MessageService],
    standalone:true,
    imports:[ButtonModule,DynamicDialogModule,RadioButtonModule, FormsModule],
    templateUrl: './presumptive-approval.component.html'
})
export class PresumptiveApprovalModalComponent  {
    endMessage:string='';
    visible: boolean = false;
   
    constructor(private ref: DynamicDialogRef, public config: DynamicDialogConfig, private toast: ToastService) {}

    onValueChange($event:Event){
        this.endMessage = ($event.target as any).value;
    }

    closeDialog(){
        this.ref.close();
    }

    approve() {
      
        if(!this.endMessage){
            return this.toast.showError("Please write comments.")
        }
        this.ref.close({approve:true,deny:false,reason: this.endMessage});
    }
    deny() {
      
        if(!this.endMessage){
            return this.toast.showError("Please write comments.")
        }
        this.ref.close({approve:false,deny:true,reason: this.endMessage});
    }

}
