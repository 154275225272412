import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { routes } from './app.routes';
import { AuthInterceptor } from '../app/shared/interceptors/auth.interceptor';
import { tokenInterceptor } from '../app/shared/interceptors/token.interceptor';
import { refreshTokenInterceptor } from '../app/shared/interceptors/refreshToken.interceptor';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { provideStore } from '@ngrx/store';
import { sessionUserReducer, metaReducerLocalStorage } from './store/user/user.reducer';
import { reviewerReducer } from './store/reviewer/reviewer.reducer';
import { DialogService } from 'primeng/dynamicdialog';
import { firebaseProviders } from './firebase.init';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([tokenInterceptor, AuthInterceptor, refreshTokenInterceptor])),
    MessageService,
    ConfirmationService,
    CookieService,
    BrowserAnimationsModule,
    LoadingBarService,
    DialogService,
    firebaseProviders,
    AngularFireAuthModule,
    provideStore({ sessionUser: sessionUserReducer, reviewer:reviewerReducer },{ metaReducers: [ metaReducerLocalStorage ] })

]
};