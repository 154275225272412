<div class="grid grid-nogutter mb-3">
    <div class="col-12 md:col-6 sm:col-12 sm:mb-2">
        <h1 class="h4 m-0">Resume Generator</h1>
    </div>
    <div
        class="col-12 md:col-6 sm:col-12 flex md:justify-content-end justify-content-between mt-3 md:mt-0 align-items-start sm:align-items-center  md:gap-4 gap-0 ">
        <p-button label="Generate Resume" [rounded]="true" icon="pi pi-plus" size="large"
            (onClick)="generateResume()" />
    </div>
</div>

@if(reviewer && reviewer.firstName){

<div class="flex flex-column gap-4">
    <div class="flex flex-column gap-2">
        <h6 class="m-0">Information</h6>
        <div class="border-1 surface-border py-2 px-3">
            <div class="grid grid-nogutter">
                <div class="col-12 lg:col">
                    <div class="flex flex-column">
                        <span class="text-xs text-black-alpha-60">Name</span>
                        <span class="text-primary-900">{{reviewer.firstName}} {{reviewer.lastName}}</span>
                    </div>
                </div>
                <!--  <div class="col-12 lg:col">
                            <div class="flex flex-column">
                                <span class="text-xs text-black-alpha-60">Bar Membershhip</span>
                                <span class="text-primary-900">Alaska</span>
                            </div>
                        </div> -->
            </div>
        </div>
    </div>
    <div class="flex flex-column gap-2">
        <h6 class="m-0">Education</h6>
        <div class="border-1 border-bottom-none surface-border">
            @for (education of reviewer.profile.educations; track education) {
            <div class="border-bottom-1 surface-border py-2 px-3 align-items-center flex justify-content-between">
                <div class="flex flex-column">
                    <span class="text-sm text-black-alpha-60 font-medium">{{education.school.name}}</span>
                    <span class="text-primary-900">{{education.degree.type}} / {{education.degree.major}}</span>
                    <span class="text-sm">{{education.school.startDate | date:'yyyy'}} -- {{education.school.endDate |
                        date:'yyyy'}}</span>
                </div>
            </div>
            }

        </div>
    </div>

    <div class="flex flex-column gap-2">
        <h6 class="m-0">Credentials</h6>
        <div class="border-1 border-bottom-none surface-border">
            @for(creds of reviewerCredentials; track creds){
            <div class="border-bottom-1 surface-border py-2 px-3 align-items-center flex justify-content-between">
                <div class="flex flex-column">
                    <span class="text-sm text-black-alpha-60 font-medium">{{ creds.name }}</span>
                    <span class="text-primary-900">{{creds.secondaryOptions}}</span>
                    <span class="text-sm">{{ creds.issuedAt || "-" | date : "MM/dd/yyyy" }} -- {{ creds.expiresAt || "-"
                        | date : "MM/dd/yyyy" }}</span>
                </div>
            </div>
            }

        </div>
    </div>

    <div class="flex flex-column gap-2">
        <h6 class="m-0">Skills and Experience</h6>
        <div class="border-1 border-bottom-none surface-border">
            @for(creds of reviewerCriteries; track creds){
            <div class="border-bottom-1 surface-border py-2 px-3 align-items-center flex justify-content-between">
                <div class="flex flex-column">
                    <span class="text-sm text-black-alpha-60 font-medium">{{ creds.criteriaName }}</span>
                    <div class="flex mt-2">
                        @for(option of creds.criteriaSelectedOption; track option){
                        <span class="p-1">
                            <p-chip label="{{ option }}" />
                        </span>
                        }
                    </div>
                </div>
            </div>
            }
        </div>
    </div>

    </div>
}