import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {ListingComponent} from './listing/listing.component';
@Component({
  selector: 'app-education',
  standalone: true,
  imports: [CommonModule, ListingComponent],
  templateUrl: './education.component.html',
  styleUrl: './education.component.scss'
})
export class EducationComponent {

}
