<div class="w-full view-reviewer-document-modal">
    <div class="flex flex-column gap-4">
        <div>
            <h4 class="h6 m-0">
                <b>Documents</b>
            </h4>
        </div>
        <div class="col-12 grid">
            <div class="list-none m-0 contact-list completed-wrapper">
                @for(key of documentKey(); track key) {
                    <div class="px-4 hover:bg-primary-50 hover:text-primary-900 flex list-item" >
                        <div style="display: flex; align-items: center;">
                            {{reviewerInfo?.docs?.blank[key].docTitle}}
                        </div>
                        <p-button
                            [rounded]="true"
                            [text]="true"
                            icon="true"
                            (onClick)="getDocumentPathById(key)"
                            pTooltip="Download"
                            tooltipPosition="top">
                            <span class="material-symbols-outlined">download</span>
                        </p-button>
                        
                        <div class="px-4 hover:bg-primary-50 hover:text-primary-900 flex list-item download-wrapper" >
                            @if(reviewerInfo?.docs?.filled?.[reviewerInfo._id || '']?.[key]){
                                <div class="flex" ng-if="vm.selectedPosition.docs.filled[vm.user.userId][docId]">
                                    <p-button
                                        [text]="true"
                                        icon="true"
                                        (onClick)="getDocumentPathById(reviewerInfo?.docs?.filled[reviewerInfo._id || ''][key]._id)"
                                        [pTooltip]="'Download ' + reviewerInfo?.docs?.filled[reviewerInfo._id || ''][key].title"
                                        tooltipPosition="top" class="download-wrapper">
            
                                        <span class="material-symbols-outlined">download</span>
                                    </p-button>
                                    <p-button
                                        [text]="true"
                                        icon="true"
                                        [disabled]="true"
                                        tooltipPosition="top" class="download-wrapper">
                                        <i class="pi pi-check-circle" style="    color: green; font-weight: bold; margin-top: 5px;"></i>
                                    </p-button>
                                </div>
                            }
                        </div>
                    </div>
                    
                    <p-divider />
                }
            </div>
        </div>
    </div>
</div>
