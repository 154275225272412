import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DividerModule } from 'primeng/divider';
import { TooltipModule } from 'primeng/tooltip';
import { ScrollerModule } from 'primeng/scroller';
@Component({
  selector: 'app-permanently-add-confirmation',
  standalone: true,
  imports: [ButtonModule,FormsModule, RadioButtonModule, DropdownModule, DividerModule, TooltipModule, ScrollerModule],
  templateUrl: './permanently-add-confirmation-modal.component.html',
  styleUrl: './permanently-add-confirmation-modal.component.scss'
})
export class PermanentlyAddConfirmationComponent {
  constructor(private ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  closeDialog(isConfirm = false){
    this.ref.close(isConfirm);
  }
}
