import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { HttpBaseService } from './http-base.service';

import { Company } from '../interfaces/company.interface';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private http: HttpBaseService
  ) { }

  public getCompanies(): Promise<Array<Company>>{
    const params = new HttpParams()
    .set('offset', 0)
    .set('limit', 0)
    .set('noCache', new Date().getTime());
    return lastValueFrom(this.http.get('companies', { params }));
  }
}
