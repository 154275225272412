import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ChipModule } from 'primeng/chip';
import { IconFieldModule } from 'primeng/iconfield';
import { InputTextModule } from 'primeng/inputtext';
import { InputIconModule } from 'primeng/inputicon';
import { FloatLabelModule } from 'primeng/floatlabel';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox'
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { BaseReport } from '../base-class/base-report.class';
import moment from 'moment';
import { ReportService } from '../reports.service';
import { StorageService } from '../../../../../shared/services/storage.service';
import { DateTimeService } from '../../../../../shared/services/date-time.service';
import { InvoiceReportPayload, InvoiceReportsReponse } from '../interfaces/reports.interface';
import { UserService } from '../../../../../shared/services/user.service';

@Component({
  selector: 'app-invoice',
  standalone: true,
  imports: [CalendarModule,CheckboxModule,OverlayPanelModule,FloatLabelModule,MultiSelectModule,DropdownModule, ButtonModule, FormsModule, ChipModule, IconFieldModule,InputIconModule,InputTextModule,TableModule, CommonModule ],
  templateUrl: './invoice.component.html',
  styleUrl: './invoice.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class InvoiceComponent extends BaseReport implements OnInit {
  invoiceReports: Array<InvoiceReportsReponse> = [];
  showProjectSelectionError = false;

  selectedMoreFilters: any[] = [];
  moreFilters = [
    { label: 'Daily hours', value: false },
    { label: 'Hourly rate', value: false },
    { label: 'Show only submitted invoice', value: false }
  ];

  constructor(
    override reportService: ReportService,
    override storageService: StorageService,
    override dateTimeService: DateTimeService,
    override userService: UserService
  ){
    super(reportService, storageService, dateTimeService, userService);
  }

  ngOnInit() {
    this.initializeReport();
    this.generateWeekDateAsPerFirm();
  }

  override async onProjectSelectionChange(e: any): Promise<void>{
    await super.onProjectSelectionChange(e);
    this.showProjectSelectionError = false;
  }

  slider(isNext: boolean): void{
    let md;
    if (isNext) {
      md = moment(this.startDate).add(7, 'day');
    } else {
      md = moment(this.startDate).subtract(7, 'day');
    }

    this.startDate = md.toDate();
    this.endDate = md.add(6, 'day').toDate();
  }

  generateWeekDateAsPerFirm(): void {
    const day = 'Monday';
    let md = moment();
    while (md.format('dddd').toLowerCase() !== day.toLowerCase()) {
        md = md.subtract(1, 'day');
    }
    this.startDate = md.toDate();
    this.endDate = md.add(6, 'day').toDate();
  }

  getFormattedDateRange(): string{
    return `${moment(this.startDate).format('MM/DD/YYYY')} - ${moment(this.endDate).format('MM/DD/YYYY')}`
  }

  generateReport(): void{
    if(!this.selectedProjects.length){
      this.showProjectSelectionError = true;
      return;
    }
    const filters: InvoiceReportPayload = {
      emails: "",
      firm: this.sessionUser.firm._id,
      fromDate: moment(this.startDate).format('MM/DD/YYYY'),
      includeDailyTotals: this.getSelectedFilterFlagByFilterName('Daily hours'),
      includeHourlyRate: this.getSelectedFilterFlagByFilterName('Hourly rate'),
      invoiceWeekId: moment(this.startDate).add(2, 'days').isoWeek(),
      isFetchAllRecords: false,
      isSentEmail: false,
      project: this.selectedProjects.map(project => project.id),
      reviewer: this.selectedReviewers.map(reviewer => reviewer._id),
      role: this.selectedRoles.map(role => role.id),
      selectedProj: {...this.selectedProjects},
      showOnlySubmitted: this.getSelectedFilterFlagByFilterName('Show only submitted invoice'),
      timezone: this.isDefaultTimeZone ? this.dateTimeService.getCurrentTimezone() : this.selectedTimezone?.val || '',
      toDate: moment(this.endDate).format('MM/DD/YYYY'),
      user: this.sessionUser
    };

    this.reportService.generatePayrollReport(filters).subscribe({
      next: (invoiceReports: Array<InvoiceReportsReponse>) => {
        this.invoiceReports = invoiceReports;
      }
    });

  }

  onMoreFilterSelectionChange(selectedOptions: any[]): void{
    this.selectedMoreFilters = this.moreFilters.map(item => selectedOptions.includes(item));
  }

  getSelectedFilterFlagByFilterName(filterName: string): boolean {
    const selectedFilter = this.selectedMoreFilters.find(filter => filter.label === filterName);
    return !!selectedFilter;
  }
}
