<div class="align-items-center rating-bulk-container">
    <div class="col-12 mb-3 p-0">
        <div class="flex  flex-column align-items-start justify-content-center gap-2">
            <span class="font-medium text-sm">For Project <b>{{ratingInfo?.codeName}}</b></span>
        </div>
    </div>
    @if(ratingInfo.roleReviewers.length){
        @for (role of ratingInfo.roleReviewers; track role._id) {
            <div class="col-12  flex flex-column border-1 surface-border overflow-auto font-bold">
                {{role.roleType}}
            </div>
            <div class="col-12  flex flex-column border-1 surface-border overflow-auto">
                @if(countReviewerRatedState(role.reviewers, false).length){
                    <div class="flex align-items-center gap-3">
                        <p-checkbox inputId="{{role._id}}" [binary]="true" (onChange)="selectAllReviewers(role.roleIndex)" [(ngModel)]="role.selectAll" />
                        <label for="{{role._id}}" class="cursor-pointer flex gap-2 align-items-center">
                            <span class="font-medium py-3 uppercase text-primary line-height-1 text-sm">
                                Rating Required
                            </span>
                        </label>
                        <p-rating (onRate)="rateAllReviewersOfRole(role.roleIndex)" [(ngModel)]="role.ratingAll" [cancel]="false" pTooltip="Overall Rating" tooltipPosition="top" class="p-rating--sm all-rating" />
                    </div>
                    @for(reviewer of countReviewerRatedState(role.reviewers, false); track reviewer._id){
                        <div [style]="{'min-width': '600px'}">
                            <div class="align-items-center border-top-1 flex justify-content-between surface-border text-sm">
                                <div class="flex align-items-center gap-3 col-3">
                                    <p-checkbox [binary]="true" (onChange)="reviewerSelectOnChange(role.roleIndex)" inputId="{{reviewer._id}}" [(ngModel)]="reviewer.selected" />
                                    <label for="{{reviewer._id}}" class="cursor-pointer flex gap-2 align-items-center">
                                        <span class="white-space-nowrap">{{reviewer.firstName}} {{reviewer.lastName}}</span>
                                    </label>
                                </div>
                                <div class="flex gap-1">
                                    <p-button [rounded]="true" [text]="true" icon="true" severity="secondary"
                                        (click)="$event.stopPropagation();op.toggle($event)" pTooltip="Add Notes" tooltipPosition="top"
                                        pTooltip="Add notes">
                                        <span class="material-symbols-outlined">
                                            rate_review
                                        </span>
                                    </p-button>
                                    <p-overlayPanel #op showCloseIcon="true" baseZIndex="99">
                                        <div class="flex flex-column gap-3 w-25rem">
                                            <textarea [(ngModel)]="reviewer.note" rows="5" maxlength="200" cols="30" pInputTextarea
                                                autofocus="true" placeholder="Add a note of maximum 200 characters"></textarea>
                                        </div>
                                    </p-overlayPanel>
                                    <p-button (click)="clickFavourite(role.roleIndex, reviewer.reviewerIndex)" [rounded]="true" [text]="true" icon="true" severity="secondary"
                                        [pTooltip]="!reviewer.profile.favorite ? 'Add to favorite list' : 'Remove from favorite list'"
                                        tooltipPosition="top">
                                        <span class="material-symbols-outlined " [ngStyle]="{'color': reviewer.profile.favorite ? '#311B92' : ''}" >
                                            bookmark_star
                                        </span>
                                    </p-button>
                                    <p-button (click)="onClickRestrict(role.roleIndex, reviewer.reviewerIndex, reviewer)" [rounded]="true" [text]="true" icon="true"
                                        severity="secondary"
                                        [pTooltip]="reviewer.isRestricted ? 'Remove from Restricted list' : 'Move to Restricted list'"
                                        tooltipPosition="top">
                                        <span class="material-symbols-outlined" [ngStyle]="{'color': reviewer.isRestricted ? 'red' : '#311B92'}">
                                            block
                                        </span>
                                    </p-button>
                                    <p-button (click)="toggleWorkTogether(reviewer, role.roleIndex, reviewer.reviewerIndex)" [disabled]="reviewer.rating < minRating" [rounded]="true"
                                        [text]="true" icon="true" severity="secondary"
                                        [pTooltip]="!reviewer.workTogether ? 'Add candidate to work together list' : 'Remove candidate from work together list'"
                                        tooltipPosition="top" [disabled]="reviewer.rating < minRating">
                                        <span class="material-symbols-outlined" [ngClass]="{'freshgreen': reviewer.workTogether}">
                                            group
                                        </span>
                                    </p-button>
                                </div>
                                <div>
                                    @if(reviewer.positionStatus === 'QUIT' || reviewer.positionStatus === 'FIRED' || reviewer.positionStatus === 'COMPLETED'){
                                        @if(reviewer.positionStatus === 'COMPLETED'){
                                            <span>Ended {{reviewer.positionFinishedAt | date : 'MM/dd/yyyy' : 'UTC'}}</span>
                                        }
                                        @if(reviewer.positionStatus === 'QUIT'){
                                            <span>Quit {{reviewer.positionFinishedAt | date : 'MM/dd/yyyy' : 'UTC'}}</span>
                                        }
                                        @if(reviewer.positionStatus === 'FIRED'){
                                            <span>Fired {{reviewer.positionFinishedAt | date : 'MM/dd/yyyy' : 'UTC'}}</span>
                                        }
                                    }
                                    @if(reviewer.positionStatus !== 'QUIT' && reviewer.positionStatus !== 'FIRED' && reviewer.positionStatus !== 'COMPLETED'){
                                        <span>Ended {{reviewer.positionFinishedAt | date : 'MM/dd/yyyy'}}</span>
                                    }
                                </div>
                                <div class="flex align-items-center">
                                    <p-rating (onRate)="applyWorkTogether(reviewer)" [readonly]="reviewer.readOnlyRating" [(ngModel)]="reviewer.rating" [cancel]="false"
                                        pTooltip="Overall Rating" tooltipPosition="top" class="p-rating--sm" />
                                </div>
                            </div>
                
                        </div>
                    }
                }
    
                @if(countReviewerRatedState(role.reviewers, true).length){
                    <span class="font-medium py-3 uppercase text-primary line-height-1 text-sm">
                        Already Rated
                    </span>
                    
                    @for(reviewer of countReviewerRatedState(role.reviewers, true); track reviewer._id){
                        <div [style]="{'min-width': '600px'}">
                            <div class="align-items-center border-top-1 flex justify-content-between surface-border text-sm">
                                <div class="flex align-items-center gap-3 col-3">
                                    <label class="cursor-pointer flex gap-2 align-items-center">
                                        <span class="white-space-nowrap">{{reviewer.firstName}} {{reviewer.lastName}}</span>
                                    </label>
                                </div>
                                <div class="flex gap-1">
                                    <p-button disabled="true" [rounded]="true" [text]="true" icon="true" severity="secondary"
                                        [pTooltip]="!reviewer.profile.favorite ? 'Add to favorite list' : 'Remove from favorite list'"
                                        tooltipPosition="top">
                                        <span class="material-symbols-outlined " [ngStyle]="{'color': reviewer.profile.favorite ? '#311B92' : ''}">
                                            bookmark_star
                                        </span>
                                    </p-button>
                                    <p-button disabled="true" [rounded]="true" [text]="true" icon="true"
                                        severity="secondary"
                                        tooltipPosition="top">
                                        <span class="material-symbols-outlined" [ngStyle]="{'color': reviewer.isRestricted ? 'red' : '#311B92'}">
                                            block
                                        </span>
                                    </p-button>
                                    <p-button disabled="true" [rounded]="true"
                                        [text]="true" icon="true" severity="secondary">
                                        <span class="material-symbols-outlined" [ngClass]="{'freshgreen': !reviewer.workTogether}">
                                            group
                                        </span>
                                    </p-button>
                                </div>
                                <div>
                                    @if(reviewer.positionStatus === 'QUIT' || reviewer.positionStatus === 'FIRED' || reviewer.positionStatus === 'COMPLETED'){
                                        @if(reviewer.positionStatus === 'COMPLETED'){
                                            <span>Ended {{reviewer.positionFinishedAt | date : 'MM/dd/yyyy' : 'UTC'}}</span>
                                        }
                                        @if(reviewer.positionStatus === 'QUIT'){
                                            <span>Quit {{reviewer.positionFinishedAt | date : 'MM/dd/yyyy' : 'UTC'}}</span>
                                        }
                                        @if(reviewer.positionStatus === 'FIRED'){
                                            <span>Fired {{reviewer.positionFinishedAt | date : 'MM/dd/yyyy' : 'UTC'}}</span>
                                        }
                                    }
                                    @if(reviewer.positionStatus !== 'QUIT' && reviewer.positionStatus !== 'FIRED' && reviewer.positionStatus !== 'COMPLETED'){
                                        <span>Ended {{reviewer.positionFinishedAt | date : 'MM/dd/yyyy'}}</span>
                                    }
                                </div>
                                <div class="flex align-items-center">
                                    <p-rating [readonly]="true" [(ngModel)]="reviewer.ratingOnRole.stars" [cancel]="false" class="p-rating--sm" />
                                </div>
                            </div>
                
                        </div>
                    }
                }
        
            </div>
        }
    } @else {
        <div class="col-12 mb-3 p-0">
            <div class="flex  flex-column align-items-start justify-content-center gap-2">
                <span class="font-medium text-sm">No roles in this project <b>{{ratingInfo?.codeName}}</b></span>
            </div>
        </div>
    }
    <div class="flex justify-content-end gap-2 mb-3 mt-3">
        <p-button label="Cancel" [text]="true" [outlined]="true" [size]="'large'" (click)="onConfirm(false)" />
        <p-button [disabled]="!ratingInfo.roleReviewers.length" label="Submit Rating" [size]="'large'" (click)="onConfirm(true)" />
    </div>
</div>
