import { Component, OnInit } from '@angular/core';
import { HeadingComponent } from "../../../../shared/components/heading/heading.component";
import { ButtonModule } from 'primeng/button';
import {DocumentService} from '../../../../shared/services/document-service';
import { Documents, UploadEvent, Document } from '../profile.interface';
import { FileUploadModule } from 'primeng/fileupload';
import { constants } from '../../../../shared/constants/constants';
import {ToastService} from '../../../../shared/services/toast.service';
import { CookieService } from 'ngx-cookie-service';
import { TooltipModule } from 'primeng/tooltip';

@Component({
    selector: 'app-resume',
    standalone: true,
    templateUrl: './resume.component.html',
    styleUrl: './resume.component.scss',
    imports: [HeadingComponent, ButtonModule, FileUploadModule, TooltipModule]
})
export class ResumeComponent implements OnInit {
    document:Documents = [];
    resume!:any;
    certification!:any;
    additionalDocuments: Documents = [];
    constructor(private cookieService: CookieService, private documentService: DocumentService, private toast: ToastService) { }

    isMobile = this.cookieService.get('isMobile');

    ngOnInit(): void {
        this.getDocuments();
        
    }
    getDocuments(){
        this.additionalDocuments = [];
        this.certification = null;
        this.resume = null;
        this.documentService.getDocuments().subscribe((resp:Documents)=>{
            this.filterDocumentData(resp)
        })
    }

    downloadDocument(data:Document){
        if(data.url)
            window.open(data.url, '_self');
    }
    filterDocumentData(data:Documents){
        if(data.length > 0){
            data.map((d:Document)=>{
                switch (d.documentType) {
                    case constants.documentTypes.resume:
                        this.resume = d;
                        this.resume.url= this.documentService.getDocumentPathById(this.resume._id);
    
                        break;
    
                    case constants.documentTypes.certification:
                        this.certification = d;
                        this.certification.url = this.documentService.getDocumentPathById(this.certification._id);
                        break;
    
                    case constants.documentTypes.additionalDocument:
                        const data = d;
                        data.url =  this.documentService.getDocumentPathById(d._id) 
                        this.additionalDocuments.push(data);
                        break;
                }
            })
           
        }
    }

    onUploadDocument(event: any, type:string): void {
        console.log(event.target.files[0]);
        const file = event.target.files[0];
        let opts = { documentType: type};
        this.uploadDocument(file,opts);
    }
    editDocument(event: any, type:string, data:Document){
        const addDocument = { type: type, file:event.target.files[0]};
        this.deleteDocment(data._id,true,addDocument)
    }
    deleteDocment(id:string,edit?:boolean, data?:any){
        this.documentService.deleteDocument(id).subscribe((d:any)=>{
            if(edit){
                let opts = { documentType: data.type};
                this.uploadDocument(data.file,opts);
            }else{
                this.toast.showSuccess('Document deleted successfully');
                this.getDocuments();
            }
        })
    }

    uploadDocument(file:any,opts:any){
        this.documentService.uploadDocument(file,opts).subscribe((d:any)=>{
            this.toast.showSuccess('Document uploaded successfully');
            this.getDocuments();
        })
    }
}
