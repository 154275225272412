<div class="align-items-center">
    <div class="col-12 mb-3 p-0">
        Are you sure you want to quit postion?
    </div>

    <div class="col-12 mb-3 p-0">
        <textarea rows="5" maxlength="200" (keydown)="onReasonTextChange($event)" [(ngModel)]="reason" cols="30" pInputTextarea [autoResize]="true" placeholder="Write reason..."></textarea>
        <div class="flex">
            <p style="margin-left: auto;">
                {{reason.length}} / 200
            </p>
        </div>
    </div>

    <div class="flex justify-content-end gap-2">
        <p-button label="CANCEL" (click)="confirm(false)" />
        <p-button [disabled]="isExceedLength" label="OK" (click)="confirm(true)" />
    </div>
    
</div>
