import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PayrollComponent } from './payroll/payroll.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { AllWorkingReviewersComponent } from './all-working-reviewers/all-working-reviewers.component';

const routes: Routes = [
  { path: '', redirectTo: 'payroll', pathMatch: 'full' },

      {
        path: 'payroll',
        component: PayrollComponent
      },
      {
        path: 'invoice',
        component: InvoiceComponent
      },
      {
        path: 'all-working-reviewers',
        component: AllWorkingReviewersComponent
      },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
