import { Component, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { RatingModule } from 'primeng/rating';
import { CommonModule } from '@angular/common';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormsModule } from '@angular/forms';
import { PeopleService } from '../../../../../../../shared/services/people.service';
import { constants } from '../../../../../../../shared/constants/constants';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-rate-freelancers',
  standalone: true,
  imports: [CommonModule, DialogModule, ButtonModule, DynamicDialogModule, RatingModule, FormsModule, OverlayPanelModule, TooltipModule],
  templateUrl: './rate-freelancers.component.html',
  styleUrl: './rate-freelancers.component.scss',
})
export class RateFreelancersComponent implements OnInit {
  visible: boolean = false;
  dummy!: any[];
  ratingInfo: any;
  rating = 3;
  isRestricted = false;
  isFavourite = false;
  readOnlyRating = false;
  minRating = constants.stars.minRating;
  workTogether = true;
  notes = '';


  constructor(
    private rateProjectRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private peopleService: PeopleService
  ){

  }

  ngOnInit() {
    this.ratingInfo = this.config.data;
    this.isFavourite = this.ratingInfo.firmFavorites.findIndex((fav: string) => fav === this.ratingInfo.reviewerId) !== -1;
  }

  showDialog($event: Event) {
    $event.stopPropagation();
    this.visible = true;
  }

  clickFavourite() {
    if (!this.isRestricted) {
        if (this.isFavourite) {
            this.removeAsFavourite();
        }
        else {
            this.setAsFavourite();
        }
    }
  }

  onClickRestrict(){
    this.addToRestrictedList();
    this.applyWorkTogether();
  }

  setAsFavourite() {
    const fav = {
      favorite: this.ratingInfo.reviewerId
    };
    this.peopleService.addToFavouriteList(this.ratingInfo.firmId, fav).subscribe({
      next: () => {
        this.isFavourite = true;
        this.rating = constants.stars.favorite
      },
    });
  }

  removeAsFavourite() {
    this.peopleService.removeFromFavouriteList(this.ratingInfo.firmId, this.ratingInfo.reviewerId).subscribe({
      next: () => {
        this.isFavourite = false
      }
    });
  }

  addToRestrictedList(){
    this.isRestricted = !this.isRestricted;
    this.readOnlyRating = false;
    if (this.isRestricted) {
        this.removeAsFavourite();
        this.rating = constants.stars.restricted;
    }
  }

  applyWorkTogether() {
    if (this.rating < this.minRating) {
        this.workTogether = false;
    } else {
        if (!this.isRestricted) {
            this.workTogether = true;
        }
    }
  }

  toggleWorkTogether() {
    this.workTogether = !this.workTogether;
    if(this.workTogether){
        this.isRestricted = false;
    }
  }

  onConfirm(isConfirm: boolean): void{
    this.rateProjectRef.close({
      isConfirm, 
      ratingInfo: {
        ...this.ratingInfo, 
        rating: this.rating, 
        isRestricted: this.isRestricted, 
        workTogether: this.rating < this.minRating ? false : this.workTogether,
        notes: (this.notes || '').trim()
      }
    });
  }
}
