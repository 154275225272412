import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChipModule } from 'primeng/chip';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TabViewModule } from 'primeng/tabview';
import { InputTextModule } from 'primeng/inputtext';
import { FloatLabelModule } from 'primeng/floatlabel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { CommonModule, Location } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { constants } from '../../../../../../shared/constants/constants';
import { Project, ProjectOwner, ProjectOwnerReponse } from '../../project.interface';
import { UserService } from '../../../../../../shared/services/user.service';

import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { CompanyService } from '../../../../../../shared/services/company.service';
import { FirmService } from '../../../../../../shared/services/firm.service';
import { ProjectService } from '../../../../../../shared/services/project.service';
import { ListOfValuesService } from '../../../../../../shared/services/list-of-values.service';
import { ListOfValues } from '../../../../../../shared/interfaces/list-of-values.interface';
import { HelperService } from '../../../../../../shared/services/helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from '../../../../../../shared/interfaces/company.interface';



@Component({
  selector: 'app-project-info',
  standalone: true,
  imports: [
    DragDropModule,
    ChipModule,
    TabViewModule,
    DropdownModule,
    CalendarModule,
    CheckboxModule,
    InputTextareaModule,
    FormsModule,
    RadioButtonModule,
    FloatLabelModule,
    InputTextModule,
    InputSwitchModule,
    MultiSelectModule,
    CommonModule,
    ReactiveFormsModule,
    AutoCompleteModule
  ],
  templateUrl: './information.component.html',
  styleUrl: './information.component.scss'
})
export class InformationComponent implements OnInit {
  @Input()
  id: string | null = null;
  @Input()
  project: Project | null = null;
  @Output() 
  updateProjectState: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  changeTab: EventEmitter<number> = new EventEmitter<number>();

  user: any;
  projectInfoForm!: FormGroup;
  cyberSelected = false;
  notifications = {
    projectStaffed: true
  };

  companies: Array<Company> = [];
  filteredCompanies: Array<Company> = [];
  selectedCompany: Company | null = null;

  projectOwners: Array<ProjectOwner> = [];

  projectsAdverseParties: Array<Company> = [];

  communicationOwners: Array<ListOfValues> = [];
  reviewerPlatforms: Array<ListOfValues> = [];

  projectTypes: Array<string> = [constants.projectType.LITIGATION_INVESTIGATION, constants.projectType.CYBER];


  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private projectService: ProjectService,
    private companyService: CompanyService,
    private firmService: FirmService,
    private listOfValueService: ListOfValuesService,
    private helperService: HelperService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ){

  }

  async ngOnInit() {
    this.initializeSessionUser();
    this.initializeForm();
    await this.getCompanies();
    this.getListOfFirmUsers();
    await this.getCommunicationPlatforms();
    await this.getReviewerPlatforms();
    this.onChangeFormValue();
    this.initializeEdit();
  }

  async initializeEdit(): Promise<void>{
    this.route.paramMap.subscribe(params => {
      this.id = params.get('projectId') || null;
      if(this.id){
        this.initializeFormValuesAsPerExistingProject(this.project);
      }
    });
  }

  initializeForm(): void{
    this.projectInfoForm = this.fb.group({
      backgroundCheckRequired: [ false],
      civilLitigationScreeningRequired: [false],
      owner: [ null, [Validators.required]],
      codeName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\-\s]{0,99}$/)]],
      client: [null],
      hasConflict: [false],
      projectType: [this.projectTypes.find(type => type === constants.projectType.LITIGATION_INVESTIGATION)],
      notifications: [this.notifications],
      tsheet: this.fb.group({
        isEnabled: [this.user.firm.payroll && this.user.firm.payroll.enabled]
      }),
      scanForConflicts: [{
          fullCompanyNames: false,
          specificQuestions: false
      }],
      adverseParties: [[]],
      conflictQuestions: [[]],
      newAdverseParties: [[]],
      relativity: [{
        instance: '',
        workspace: '',
        hasWorkspace: false
      }],
      contractorTimekeepingEnabled: [true],
      selectedCommunicationPlatforms: [null],
      communicationPlatformUrl: [''],
      communicationPlatform: [{
        platformId: '',
        name: '',
        url: '',
      }],
      selectedReviewerPlatform: [null],
      reviewerPlatformUrl: [''],
      reviewerPlatform:[{
        platformId: '',
        name: '',
        url: '',
      }],
      isPresumptiveEnabled: [false],
      firm: [this.user.firm],
      step: [2]
    });
  }

  initializeFormValuesAsPerExistingProject(project: Project | null): void {
    const existingProjectOwner = this.projectOwners.find(owner => owner._id === project?.owner);
    const existingCommunicationPlatform = this.communicationOwners.find(commOwner => commOwner._id === project?.communicationPlatform?.platformId);
    const existingReviewerPlatform = this.reviewerPlatforms.find(reviewerPlatform => reviewerPlatform._id === project?.reviewerPlatform?.platformId);
    const client = this.companies.filter((company: any) => company._id === project?.client)[0]?.name || project?.client
    this.projectInfoForm.patchValue({
      backgroundCheckRequired: project?.backgroundCheckRequired || false,
      civilLitigationScreeningRequired: project?.civilLitigationScreeningRequired || false,
      owner: existingProjectOwner || null,
      codeName: project?.codeName || '',
      client,
      hasConflict: project?.hasConflict || false,
      notifications: project?.notifications || this.notifications,
      projectType: project?.projectType || constants.projectType.LITIGATION_INVESTIGATION,
      tsheet: {
        isEnabled: project?.tsheet?.isEnabled !== undefined ? project?.tsheet?.isEnabled : (this.user.firm.payroll && this.user.firm.payroll.enabled)
      },
      scanForConflicts: {
          fullCompanyNames: project?.scanForConflicts?.fullCompanyNames || false,
          specificQuestions: project?.scanForConflicts?.specificQuestions || false
      },
      conflictQuestions: project?.conflictQuestions && project?.conflictQuestions?.length ? project?.conflictQuestions : [],
      newAdverseParties: [],
      relativity: {
        instance: project?.relativity?.instance || '',
        workspace: project?.relativity?.workspace || '',
        hasWorkspace: project?.relativity?.hasWorkspace || false
      },
      contractorTimekeepingEnabled: project?.contractorTimekeepingEnabled !== undefined ? project?.contractorTimekeepingEnabled : true,
      selectedCommunicationPlatforms: existingCommunicationPlatform || null,
      communicationPlatformUrl: project?.communicationPlatform?.url || '',
      communicationPlatform: {
        platformId: project?.communicationPlatform?.platformId || '',
        name : project?.communicationPlatform?.name || '',
        url: project?.communicationPlatform?.url || '',
      },
      selectedReviewerPlatform: existingReviewerPlatform || null,
      reviewerPlatformUrl: project?.reviewerPlatform.url || '',
      reviewerPlatform:{
        platformId: project?.reviewerPlatform.platformId || '',
        name : project?.reviewerPlatform.name || '',
        url: project?.reviewerPlatform.url || '',
      },
      isPresumptiveEnabled: project?.isPresumptiveEnabled || false,
      firm: project?.firm || this.user.firm,
      step: project?.step || 2,
    });


  }

  onChangeFormValue(): void{
    this.projectInfoForm.get('selectedCommunicationPlatforms')?.valueChanges.subscribe({
      next: () => {
        this.setCommunicationPlatform();
      }
    });

    this.projectInfoForm.get('selectedReviewerPlatform')?.valueChanges.subscribe({
      next: () => {
        this.setReviewerPlatform();
      }
    });

    this.projectInfoForm.get('communicationPlatformUrl')?.valueChanges.subscribe({
      next: () => {
        this.setCommunicationPlatform();
      }
    });

    this.projectInfoForm.get('reviewerPlatformUrl')?.valueChanges.subscribe({
      next: () => {
        this.setReviewerPlatform();
      }
    });
  }

  setCommunicationPlatform(): void{
    const selectedCommunicationPlatform = this.getFormValuesByProperty('selectedCommunicationPlatforms');
    const communicationUrl = this.getFormValuesByProperty('communicationPlatformUrl');

    const communicationPlatform: any = this.getFormValuesByProperty('communicationPlatform');
    communicationPlatform.platformId = selectedCommunicationPlatform?._id;
    communicationPlatform.url = communicationUrl;

    this.setFormValueByProperty('communicationPlatform', communicationPlatform, false);
  }

  setReviewerPlatform(): void{
    const selectedreviewerPlatform = this.getFormValuesByProperty('selectedReviewerPlatform');
    const reviewerUrl = this.getFormValuesByProperty('reviewerPlatformUrl');
    const reviewerPlatform: any = this.getFormValuesByProperty('reviewerPlatform');
    reviewerPlatform.platformId = selectedreviewerPlatform?._id;
    reviewerPlatform.url = reviewerUrl;
    this.setFormValueByProperty('reviewerPlatform', reviewerPlatform, false);
  }

  initializeSessionUser(): any{
    this.user = this.userService.getSessionUser();
  }

  getFormValuesByProperty(property: string): any{
    return this.projectInfoForm.controls[property].getRawValue();
  }

  setFormValueByProperty(property: string, value: any, emitEvent = true): void{
    this.projectInfoForm.controls[property].setValue(value, { emitEvent });
  }

  async getCompanies(): Promise<void>{
    this.companies = await this.companyService.getCompanies();
    this.projectsAdverseParties = [...this.companies]
  }

  getListOfFirmUsers(): void{
    this.firmService.getListOfFirmUsers(this.user.firm._id, undefined, undefined, undefined).subscribe({
      next: (reponse: ProjectOwnerReponse) => {
        reponse.users.forEach(user => {
          user.name = `${user.firstName} ${user.lastName}`;
        });
        this.projectOwners = reponse.users;
        const projectOwner = this.projectOwners.find(owner => this.user._id === owner._id);
        if(!this.id){
          this.setFormValueByProperty('owner', projectOwner);
        }
      }
    });
  } 

  async getCommunicationPlatforms(): Promise<void>{
    this.communicationOwners = await this.listOfValueService.getListOfValues('COMMUNICATION_PLATFORM', this.user.firm._id);
  }

  async getReviewerPlatforms(): Promise<void>{
    this.reviewerPlatforms = await this.listOfValueService.getListOfValues('REVIEWER_PLATFORM', this.user.firm._id);
  }

  findCompanies(event: AutoCompleteCompleteEvent): void{
    this.filteredCompanies = this.companies.filter((company: any) => (company.name.toLowerCase()).includes(event.query.toLowerCase()));
  }

  onRemoveAdverseParty(index: number): void{
    const adverseParties = this.getFormValuesByProperty('adverseParties') as Array<Project>;
    adverseParties.splice(index, 1);
    this.setFormValueByProperty('adverseParties', [...adverseParties]);
  }

  isValidURL(url: string): boolean{
    return this.helperService.isValidUrl(url);
  }

  get form(): { [key: string]: AbstractControl } {
    return this.projectInfoForm.controls;
  }

  isCommunicationAndReviewerUrlValid(): boolean{
    if(this.user.firm.isCommunicationPlatformEnabled){
      if(this.getFormValuesByProperty('communicationPlatform')?.platformId){
        const value = this.getFormValuesByProperty('communicationPlatformUrl');
        const communicationFormProperty = this.form['communicationPlatformUrl'];
        return !((
          (communicationFormProperty?.dirty || communicationFormProperty?.touched) && !value) ||
          (communicationFormProperty?.dirty || communicationFormProperty?.touched) && value && !this.isValidURL(value)
        );
      }

      if(this.getFormValuesByProperty('reviewerPlatformUrl')?.platformId){
        const value = this.getFormValuesByProperty('reviewerPlatformUrl');
        const reviewerFormProperty = this.form['reviewerPlatformUrl'];
        return !((
          (reviewerFormProperty?.dirty || reviewerFormProperty?.touched) && !value) ||
          (reviewerFormProperty?.dirty || reviewerFormProperty?.touched) && value && !this.isValidURL(value)
        );
      }
    }

    return true;
  }

  transformProjectRequestPayload(): Project{
    const {
      selectedCommunicationPlatforms,
      selectedReviewerPlatform,
      ...projectPayload
    } = this.projectInfoForm.value;
   return {
    ...projectPayload,
    owner: this.projectInfoForm.value?.owner ? this.projectInfoForm.value?.owner?._id : null,
    client: this.projectInfoForm.value?.client,
    firm: this.projectInfoForm.value?.firm ? this.projectInfoForm.value?.firm?.id : this.user.firm._id,
   };
   
  }

  isAdverseAndClientIsSame(): boolean{
    const client = this.getFormValuesByProperty('client');
    const adverseParties = this.getFormValuesByProperty('adverseParties');
    const isSame = adverseParties.find((party: any) => party._id === client._id);
    return !!isSame;
  }

  onSubmit(): void {
    if(!this.projectInfoForm.valid || !this.isCommunicationAndReviewerUrlValid()) {
      return;
    }

    if(this.id){
      this.updateProject();
    } else {
      this.createProject();
    }
  }

  createProject(): void {
    if(!this.projectInfoForm.valid || !this.isCommunicationAndReviewerUrlValid()){
      return;
    }

    const project: Project = this.transformProjectRequestPayload();
    this.projectService.createProject(project).subscribe({
      next: (project: Project) => {
        const projecId = project._id;
        this.router.navigate([`/client/project/${projecId}/edit`]);
      }
    });
  }

  updateProject(): void{
    if(!this.projectInfoForm.valid || !this.isCommunicationAndReviewerUrlValid()){
      return;
    }

    const project: Project = this.transformProjectRequestPayload();
    this.projectService.updateProject(project, this.id || '').subscribe({
      next: () => {
        this.updateProjectState.emit();
        this.changeTab.emit(1);
      }
    });
  }

  onCancel(): void {
    this.location.back();
  }
}
