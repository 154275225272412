<div class="align-items-center">

    @if(roleEndedInfo.position.presumptive && roleEndedInfo.position.presumptive.status === presumptiveStatus.DENIED){
        <div class="col-12 mb-3 p-0">
            Placement Update
        </div>
    }

    @if(!roleEndedInfo.position.presumptive || (roleEndedInfo.position.presumptive && roleEndedInfo.position.presumptive.status !== presumptiveStatus.DENIED)){
        <div class="col-12 mb-3 p-0">
            Invoicing and Timekeeping
        </div>
    }

    @if(!autoQuit){
        @if(roleEndedInfo.position.presumptive && roleEndedInfo.position.presumptive.status === presumptiveStatus.DENIED){
            <div class="col-12 mb-3 p-0">
                <p>Thank you for submitting your profile for project: <b>{{roleEndedInfo?.project?.codeName}}</b>. Unfortunately the client will not be needing you on this project at this time.</p>
            </div>
        }
        @if(!roleEndedInfo.position.presumptive || (roleEndedInfo.position.presumptive && roleEndedInfo.position.presumptive.status !== presumptiveStatus.DENIED)){
            <div class="col-12 mb-3 p-0">
                <p>
                    To become eligible for new projects please verify that your time is accurate and submit your final invoice.
                </p>
            </div>
        }
    }@else{
        <div class="col-12 mb-3 p-0">
            <p>
                To become eligible for new projects please verify that your time is accurate and submit your final invoice.
            </p>
        </div>
    }

    @if(endMessage.length){
        <div class="col-12 mb-3 p-0">
            <p><b> {{reasonTitle}} </b> <br /> {{endMessage}}</p>
        </div>
    }

    @if(hasTsheet && !roleEndedInfo.position.project.contractorTimekeepingEnabled){
        <div class="col-12 mb-3 p-0">
            Do you certify that your time on Project <b style="color:rgba(0, 0, 0, 0.87) !important;">{{roleEndedInfo?.project?.codeName}}</b> Role <b>{{roleEndedInfo?.role?.roleType}}</b> is accurate and complete?
        </div>
    }

    @if(hasTsheet && roleEndedInfo.position.project.contractorTimekeepingEnabled){
        <div class="col-12 mb-3 p-0">
            Please verify your hours and submit an invoice before leaving project <b style="color:rgba(0, 0, 0, 0.87) !important;">{{roleEndedInfo?.project?.codeName}}</b>
        </div>
    }

    <div class="flex justify-content-end gap-2">
        @if(hasTsheet){
            <p-button [label]="roleEndedInfo.position.project.contractorTimekeepingEnabled ? 'Review Time in Invoice Generator' : 'NO'" (click)="confirm(false)" />
            <p-button  label="YES" (click)="confirm(true)" />
        } @else {
            <p-button label="OK" (click)="confirm(true)" />
        }
    </div>
</div>
