import { Component } from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
@Component({
  selector: 'app-conflict-questions-modal',
  standalone: true,
  imports: [ButtonModule, RadioButtonModule],
  templateUrl: './conflict-questions-modal.component.html',
  styleUrl: './conflict-questions-modal.component.scss'
})
export class ConflictQuestionsModalComponent {
  constructor(private conflictQuestionRef: DynamicDialogRef, public config: DynamicDialogConfig) { }
  currentIndex = 0;
  conflictQuestions: any = {};

  selectAnswer(answer: boolean) {
    if (answer === this.config.data.conflictQuestions[this.currentIndex].answer) {
      const conflictQuestionsLength = this.config.data.conflictQuestions.length - 1;
      if (conflictQuestionsLength > this.currentIndex) {
        this.currentIndex++;
      } else {
        this.conflictQuestionRef.close({ isOfferAccepted: true });
      }
    } else {
      this.conflictQuestions = { question: JSON.stringify(this.config.data.conflictQuestions[this.currentIndex]) };
      this.conflictQuestionRef.close({ isOfferAccepted: false, conflictReasons: this.conflictQuestions });
    }


  }
}
