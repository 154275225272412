import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { TagModule } from 'primeng/tag';
import { ChipModule } from 'primeng/chip';
import { FileUploadModule } from 'primeng/fileupload';

import { HeadingComponent } from '../../../../shared/components/heading/heading.component';
import { DynamicCredentialService } from '../../../../shared/services/dynamic-credential.service';
import { ReviewerService } from '../../../../shared/services/reviewer.service';
import { lastValueFrom } from 'rxjs';
import { Reviewer } from '../../../../store/reviewer/reviewer.interface';
import { DocumentService } from '../../../../shared/services/document-service';
import { ConfirmationDialogService } from '../../../../shared/services/confirmation-dialog.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { FirmCredential, Id, ReviewerCredential } from './credentials.model';
import { EmittersService } from '../../../../shared/services/emitters.service';
import { Router } from '@angular/router';

interface UploadEvent {
  originalEvent: Event;
  files: File[];
}

@Component({
  selector: 'app-credentials',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    FloatLabelModule,
    CalendarModule,
    InputTextModule,
    DropdownModule,
    HeadingComponent,
    MultiSelectModule,
    AccordionModule,
    TagModule,
    FileUploadModule,
    ChipModule,
  ],
  templateUrl: './credentials.component.html',
  styleUrl: './credentials.component.scss',
})
export class CredentialsComponent implements OnInit {
  userFirmId: string = '';
  dynamicCredentials!: Id[];
  headingButton = {
    url: '/reviewer/profile/credentials/create',
    label: 'Add credentials',
    color: '#d65289'
  };
  reviewer!: Reviewer;
  reviewerCredentials!: ReviewerCredential[];
  secondaryOptions!: string[];

  constructor(
    private dynamicCredentialService: DynamicCredentialService,
    private reviewerService: ReviewerService,
    private documentService: DocumentService,
    private confirmationDialogService: ConfirmationDialogService,
    private toastService: ToastService,
    private emitterService: EmittersService,
    private router: Router
  ) {
    this.emitterService.tenantEmitter.subscribe((event)=>{
      this.ngOnInit();
    });
  }

  async ngOnInit(): Promise<void> {
    this.userFirmId = this.reviewerService.getCurrentTenant();
    await this.getReviewerInfo();
    await this.getDynamicCredentials();
    await this.getReviewerCredentials();
    this.parseSecondaryOptions();
    this.appendDownloadUrlArray();
  }

  async getDynamicCredentials(): Promise<void> {
    this.dynamicCredentials = await lastValueFrom(
      this.reviewerService.getFilteredCredentialBasedUserSkills(this.reviewer._id)
    );
  }

  async getReviewerCredentials(): Promise<void> {
    this.reviewerCredentials = await lastValueFrom(
      this.dynamicCredentialService.getReviewerCredentials(this.reviewer._id)
    );
  }

  async getReviewerInfo(): Promise<void> {
    this.reviewer = await lastValueFrom(this.reviewerService.fetchReviewer());
  }

  parseSecondaryOptions(): void {
    this.reviewerCredentials.forEach((cred: ReviewerCredential) => {
      if (cred.secondaryOptions && cred.secondaryOptions.trim() !== '') {
        cred.secondaryOptionsArray = cred.secondaryOptions.split(',');
      }
    });
  }

  appendDownloadUrlArray(): void {
    this.reviewerCredentials.forEach((cred: ReviewerCredential) => {
      if (cred.certificate) {
        const downloadURL = this.documentService.getDocumentPathById(
          cred.certificate._id
        );
        if (downloadURL) {
          cred.certificate.downloadURL = downloadURL;
        }
      }
    });
  }

  updateReviewerCredentials(reviewerCreds: ReviewerCredential[]): void {
    this.dynamicCredentialService
      .updateReviewerDynamicCredentials({
        reviewerId: this.reviewer._id,
        credentials: reviewerCreds,
      })
      .subscribe({
        next: (res) => {
          this.toastService.showSuccess('updated credentials.');
          this.ngOnInit();
        },
      });
  }

  editMode(event: any, creds: ReviewerCredential): void {
    this.router.navigateByUrl(`/reviewer/profile/credentials/edit/${creds._id}`)
  }

  onDelete($event: Event, cred: ReviewerCredential): void{
    $event.stopPropagation();
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message:
        'You are about to delete the credentials of ' +
        cred?.name +
        '. Are you sure?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        let reviewerCreds: ReviewerCredential[] = [];
        this.reviewerCredentials.forEach((credential: ReviewerCredential) => {
          if (cred.name !== credential.name) {
            reviewerCreds = reviewerCreds.concat(credential);
          }
        });
        reviewerCreds.forEach((obj: ReviewerCredential) => {
          if (obj.secondaryOptionsArray) {
            delete obj.secondaryOptionsArray;
          }
        });
        this.updateReviewerCredentials(reviewerCreds);
      },
    });
  }

  onUpload(event: any) {
    // this.messageService.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded with Basic Mode' });
  }
}
