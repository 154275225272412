import { Component } from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';
@Component({
  selector: 'app-quit-position-modal',
  standalone: true,
  imports: [ButtonModule, RadioButtonModule, DynamicDialogModule, FormsModule, ReactiveFormsModule, InputTextareaModule],
  templateUrl: './quit-confirmation-modal.component.html',
  styleUrl: './quit-confirmation-modal.component.scss'
})
export class QuitConfirmationModalComponent {

  reason = '';
  maxLength = 200;
  isExceedLength = false;

  constructor(private quitConfirmationModal: DynamicDialogRef, public config: DynamicDialogConfig) { }

  onReasonTextChange(event: any){
    if(this.reason.length > 200){
      this.isExceedLength = true;
    } else {
      this.isExceedLength = false;
    }
  }

  confirm(isConfirm: boolean) {
    this.quitConfirmationModal.close({isConfirm, reason: this.reason});
  }
}
