import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { lastValueFrom } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Role } from '../interfaces/roles.interface';
import { constants } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private http: HttpBaseService) { }

  getActiveRolesByProjectId(projectId: string): Promise<Array<Role>>{
    const params = new HttpParams()
    .set('status', constants.roleStatus.active)
    .set('noCache', new Date().getTime());
    return lastValueFrom(this.http.get(`projects/${projectId}/roles`, { params }));
  }

  getRolesByProjectId(projectId: string): Promise<Array<Role>>{
    const params = new HttpParams()
    .set('noCache', new Date().getTime());
    return lastValueFrom(this.http.get(`projects/${projectId}/roles`, { params }));
  }

  getRoleByProjectAndRoleId(projectId: string, roleId: string = ''): Promise<Role>{
    const params = new HttpParams()
    .set('noCache', new Date().getTime());
    return lastValueFrom(this.http.get(`projects/${projectId}/roles/${roleId}`, { params }));
  }
}
