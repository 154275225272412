import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DynamicCriteriaComponent } from './dynamic-criteria/dynamic-criteria.component';
import { DynamicCredentialComponent } from './dynamic-credential/dynamic-credential.component';
import { SearchMarketComponent } from './search-markets/search-markets.component';
import { JobTypesComponent } from './job-types/job-types.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

const routes: Routes = [
  { path: '', redirectTo: 'dynamic-criteria', pathMatch: 'full' },
  {
    path: 'dynamic-criteria',
    component: DynamicCriteriaComponent
  },
  {
    path: 'dynamic-credential',
    component: DynamicCredentialComponent
  },
  {
    path: 'search-markets',
    component: SearchMarketComponent
  },
  {
    path: 'job-types',
    component: JobTypesComponent
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent
  },
  {
    path: 'dynamic-criteria/create',
    loadComponent:()=> import('./create-dynamic-criteria/create-dynamic-criteria.component').then((c)=> c.CreateDynamicCriteriaComponent)
  },
  {
    path: 'dynamic-criteria/edit/:id',
    loadComponent:()=> import('./create-dynamic-criteria/create-dynamic-criteria.component').then((c)=> c.CreateDynamicCriteriaComponent)
  },
  {
    path: 'dynamic-credential/create',
    loadComponent:()=> import('./create-edit-dynamic-credential/create-edit-dynamic-credential.component').then((c)=> c.CreateDynamicCredentialComponent)
  },
  {
    path: 'dynamic-credential/edit/:id',
    loadComponent:()=> import('./create-edit-dynamic-credential/create-edit-dynamic-credential.component').then((c)=> c.CreateDynamicCredentialComponent)
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
