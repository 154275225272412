import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { AuthService } from '../services/auth.service';
import { Observable, catchError, mergeMap, switchMap, throwError } from 'rxjs';
import moment from 'moment';
import { jwtDecode } from "jwt-decode";
import { environment } from '../../../environments/environment'
import { Router } from '@angular/router';
import {constants} from '../constants/constants';

/**
 * Interceptor that adds an Authorization header to requests that are authenticated and target the API URL.
 *
 * @param request The request object.
 * @param next The next interceptor in the chain.
 *
 * @returns The next Observable.
 */
export const refreshTokenInterceptor: HttpInterceptorFn = (request, next) => {
    const storage = inject(StorageService);
    const currentUrl = request.url.split('api/')[1];
    if (isRefreshTokenApiRequired() && !constants.publicRoutes.urls.includes(currentUrl)) {
        return handleTokenRefresh(request, next)
    }
    return next(request);

};


function handleTokenRefresh(request: HttpRequest<any>, next: HttpHandlerFn,): any {
    // Call refresh token API using AuthService or a separate service
    const auth = inject(AuthService);
    const storage = inject(StorageService);
    return auth.refreshToken()
        .pipe(
            catchError(refreshError => {
                // Handle refresh token error (e.g., logout user)
                auth.logout();
                return throwError(() => refreshError);

            }),
            mergeMap((refreshTokenResp: any) => {
                const data = refreshTokenResp
                data['role'] = storage.getCookieValueByKey('role');
                storage.setUserToken(refreshTokenResp)
                const clonedRequest = request.clone({
                    setHeaders: {
                        'X-Api-Token': `${data.accessToken}`,
                    },
                });

                return next(clonedRequest);
            })
        );
}
function isRefreshTokenApiRequired(): boolean {
    const storage = inject(StorageService);
    const token = storage.getToken();
    const tokenExp = storage.getCookieValueByKey('tokenExpiry');
    const lastActivity = new Date().setSeconds(0);
    const timeDiff = getTimeDiff(new Date(lastActivity), true);

    if (!token) {
        return false;
    }
    const decoded = jwtDecode(token);
    const exp = decoded.exp ? decoded.exp : 0;
    const iat = decoded.iat ? decoded.iat : 0;
    const tokenExpiresIn = exp - iat;

    if (timeDiff >= tokenExpiresIn) {
        return false;
    }
    else if (getTimeDiff(new Date(tokenExp), false) >= environment.tokenRefreshBefore) {
        return true;
    } else {
        return false;
    }
}


function getTimeDiff(time: any, isSecond: any) {
    return moment().diff(time, isSecond ? 'seconds' : 'minutes');
}