import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  configuration = {
    "_id": "6644bbba4339c1658d62cfba",
    "tenantName": "mplace",
    auth0: {
        "enabled": true,
        "modifiedAt": "2024-05-17T13:34:51.758Z"
    },
    dynamicRoleCriteria: {
        "enabled": true,
        "modifiedAt": "2024-05-17T13:34:55.315Z"
    },
    dynamicCredential: {
        "enabled": true,
        "modifiedAt": "2024-05-17T13:34:58.041Z"
    },
    multiTenant: {
        "enabled": true,
        "modifiedAt": "2024-05-17T13:35:01.017Z"
    },
    updatedAt: "2024-05-17T13:35:09.641Z",
    searchMarket: {
        "enabled": true,
        "modifiedAt": "2024-05-17T13:35:03.929Z"
    },
    jobType: {
        "enabled": true,
        "modifiedAt": "2024-05-17T13:35:06.775Z"
    },
    reviewerAutoApproval: {
        "enabled": true,
        "modifiedAt": "2024-05-17T13:35:09.640Z"
    }
}
  constructor(private http:HttpBaseService) { }

  getConfiguration(){
    return this.http.get('configurations');
  }

}
