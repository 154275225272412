import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {LayoutComponent} from '../layout/layout.component'
@Component({
  selector: 'app-reviewer-dashboard',
  standalone: true,
  imports: [LayoutComponent,RouterOutlet],
  templateUrl: './reviewer.component.html'
})
export class ReviewerDashboardComponent {

}