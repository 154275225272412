<div header="Role Selection" class="align-items-center">
    <div class="col-12 mb-3 p-0">
        <p-dropdown inputId="role" [(ngModel)]="selectedRole" [options]="config.data.roles"
        optionLabel="name" placeholder="Select Role" appendTo="body" />
    </div>
   
    <div class="flex justify-content-end gap-2">
        <p-button label="Cancel" severity="secondary" (click)="closeDialog()" />
        <p-button [disabled]="!selectedRole" label="OK" (click)="confirm()" />
    </div>
</div>