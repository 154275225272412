import { Component, ViewEncapsulation, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ChipModule } from 'primeng/chip';
import { IconFieldModule } from 'primeng/iconfield';
import { InputTextModule } from 'primeng/inputtext';
import { InputIconModule } from 'primeng/inputicon';
import { ButtonModule } from 'primeng/button';
import { Table, TableLazyLoadEvent, TableModule } from 'primeng/table';
import { HeadingComponent } from '../../../../../shared/components/heading/heading.component';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchMarkets, SearchMarket, SearchReviewerQueryParams, SearchReviewerResponse, Reviewer, UpdateUserStatus } from '../people.interface';
import { PeopleService } from '../../../../../shared/services/people.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ConfigurationService } from '../../../../../shared/services/configuration.service';
import { constants } from '../../../../../shared/constants/constants';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConfirmationDialogService } from '../../../../../shared/services/confirmation-dialog.service';
import { ToastService } from '../../../../../shared/services/toast.service';
import { UserService } from '../../../../../shared/services/user.service';
import { DeleteUserByFirm, UserClientVm } from '../../../../../store/user/user.interface';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {DeleteUserConfirmation} from './modals/delete/delete-user-confirmation.component'
import { ReviewerDetailDialogService } from '../../../../../shared/services/reviewer-detail-dialog.service';
import { RatingModule } from 'primeng/rating';
import { TooltipModule } from 'primeng/tooltip';
import { EmittersService } from '../../../../../shared/services/emitters.service';
import { Subscription } from 'rxjs';
import { TagModule } from 'primeng/tag';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'app-all',
  standalone: true,
  imports: [DropdownModule, ButtonModule, FormsModule, RatingModule, ChipModule, IconFieldModule, InputIconModule, InputSwitchModule, HeadingComponent, InputTextModule, TableModule, CommonModule, TooltipModule, TagModule, DialogModule],
  templateUrl: './all.component.html',
  styleUrl: './all.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AllComponent implements OnInit, OnDestroy {
  headingButton = {
    url: '/client/people/invite-candidate',
    label: 'INVITE CANDIDATE',
    icon: 'x',
    color: '#D65289'
  }
  pendingCandidateButton = {
    url: '/client/people/pending-candidates',
    label: 'PENDING CANDIDATES',
    icon: 'x'
  }
  sortings: any = [];
  userFilters: any = [];
  selectedSortOrder = {
    name: '',
    value: ''
  };
  reviewerCount: number = 0;
  searchMarkets: any = [];
  reviewers: Reviewer[] = [];
  showMultiTenantFeature: any;
  selectedUserFilter = {
    name: '',
    value: ''
  };
  searchText = '';
  displaySearchTextChip = false;
  searchTextDisplaying = '';
  userObject!: UserClientVm;
  ref: DynamicDialogRef | undefined;
  @ViewChild('reviewerTable') reviewerTable: Table | undefined;
  reviewerListEmitterSubscription: Subscription | undefined;
  openUnarchiveModal = false;
  selectedReviewer: any = null;
  constructor(private route: ActivatedRoute, private peopleService: PeopleService, private configurationService: ConfigurationService,
    private confirmationDialogService: ConfirmationDialogService, private toast: ToastService, private userService: UserService,
    public dialogService: DialogService, private projectService:ProjectService,private reviewerDetailDialogService:ReviewerDetailDialogService,
    private router:Router, private emitterService: EmittersService
  ) {
  }

  ngOnInit() {
    this.showMultiTenantFeature = this.configurationService.configuration[constants.features.MULTI_TENANT as keyof typeof this.configurationService.configuration];
    this.userObject = this.userService.getSessionUser();
    this.getSearchMarket();

    this.sortings = constants.sortingFilter;
    this.userFilters = [
      { name: 'All', value: 'all'},
      { name: 'Currently Working', value: 'currentlyWorking' },
      { name: 'Active', value: 'active' },
      { name: 'Inactive', value: 'inactive' },
      { name: 'Invited', value: 'invited' },
      { name: 'Archive', value: 'archive' },
    ];
    this.selectedUserFilter = this.userFilters.find((userFilter: any) => userFilter.value === 'all');
    this.subscribeEvent();
    this.getPendingReviewers();
  }

  subscribeEvent(): void{
    this.reviewerListEmitterSubscription = this.emitterService.reviewerListEmitter.subscribe(() => {
      this.reloadReviewerTable();
    })
  }

  ngOnDestroy(): void {
    this.reviewerListEmitterSubscription && this.reviewerListEmitterSubscription.unsubscribe();
  }

  reloadReviewerTable(){
    if (this.reviewerTable) {
      const event: TableLazyLoadEvent = {
        first: 0,
        rows: this.reviewerTable.rows
      };
      this.getReviewers(event);
    }
  }

  activateUser($event: Event, reviewer: any) {
    const mesg = `Are you sure you want to activate ${reviewer.firstName} ${reviewer.lastName} ? This will count against your total allotment of active users.`
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message: mesg,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        const data: UpdateUserStatus = {
          userId: reviewer._id,
          status: !reviewer.status
        }
        this.peopleService.updateUserStatusByFirm(data).subscribe({
          next: (resp) => {
            reviewer.status = resp.status;
            this.toast.showSuccess('User activated successfully');
          },
          error: (error) => {
            reviewer.status = !reviewer.status;
            console.error('Error occurred:', error);
          }
        });
      },
    });
  }
  onSortChange(event: any) {
    this.selectedSortOrder = event.value;
    this.getReviewers({ first: 0, rows: 10 });
  }
  onFilterChange(event: any) {
    this.selectedUserFilter = event.value;
    this.getReviewers({ first: 0, rows: 10 });
  }
  getSearchMarket() {
    const searchMarkets: SearchMarkets = this.route.snapshot.data['searchMarket'];
    searchMarkets.map((d: SearchMarket) => {
      this.searchMarkets.push(d._id);
    })
  }
  onEnter($event: any) {
    if ($event.keyCode === 13) {
      this.onSearchClick();
    }
  }
  onSearchClick() {
      this.getReviewers({ first: 0, rows: 10 });
      this.searchTextDisplaying = this.searchText.slice(0);
      this.displaySearchTextChip = this.searchText === '' ? false : true;
  }

  isAdminApprovalPending(reviewer: Reviewer): boolean{
    const currentTenant = reviewer.tenants.find(tenant => tenant.firm === this.userObject.firm._id);
    return currentTenant.reviewerStatus && currentTenant.reviewerStatus === constants.userVerificationStatus.pendingAdminVerification;
  }

  onRemoveChip(type: string) {
    if (type === 'sortOrder') {
      this.selectedSortOrder = {
        name: '',
        value: ''
      };
    } else if (type === 'userFilter') {
      this.selectedUserFilter = {
        name: '',
        value: ''
      };
    } else {
      this.searchText = '';
      this.searchTextDisplaying = '';
      this.displaySearchTextChip = false;
    }
    this.getReviewers({ first: 0, rows: 10 });
  }
  getReviewers(event?: any) {
    if(!event.sortField){
      const offset = event.first;
      const limit = event.rows;
      const queryParams: SearchReviewerQueryParams = {
        sortOrder: constants.sortingOrder.descending,
        offset: offset,
        limit: limit,
        markets: this.searchMarkets.join(','),
        searchOn: 'all',
        userStatus: 'all'
      };
  
      if (this.selectedSortOrder.value) {
        queryParams.sortBy = this.selectedSortOrder.value;
      }
  
      if (this.selectedUserFilter.value) {
        queryParams.userStatus = this.selectedUserFilter.value;
      }
      if (this.searchText) {
        queryParams.search = this.searchText;
      }
      this.peopleService.searchReviewers(queryParams).subscribe((response: SearchReviewerResponse) => {
        this.reviewers = response.users;
        this.reviewerCount = response.count;
        this.filterData();
      })
    }else{
        this.sortData(event.sortField, event.sortOrder);
    }
    
  }
  sortData(field: 'fullName' | 'email' | 'averageRating',sortOrder:number){
    if(field){
      this.reviewers.sort((a, b) => {
        let valueA = a[field] ?? ''; // Optional chaining and nullish coalescing
        let valueB = b[field] ?? ''; // Optional chaining and nullish coalescing

  
        let comparison = 0;
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        }
        return sortOrder === -1 ? comparison * -1 : comparison;
      });
    }
   
  }
  filterData(){
    this.reviewers.map((resp:Reviewer)=>{
      resp.fullName = `${resp.firstName} ${resp.lastName}`;
    })
  }

  moveToRestrictedList($event: Event, reviewer: Reviewer) {
    const [action, preposition] = reviewer.isRestricted ? ['remove', 'from'] : ['add', 'to'];

    const mesg = `You are about to ${action} ${reviewer.firstName} ${reviewer.lastName} ${preposition} your Restricted list. Are you sure?`;
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message: mesg,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        if(!reviewer.isRestricted){
          this.peopleService.moveToRestrictedList(reviewer._id).subscribe((response: any) => {
            this.toast.showSuccess('Moved to Restricted list');
            reviewer.isRestricted = true;
          });
        }else{
          this.peopleService.removeFromRestrictedList(reviewer._id).subscribe((response: any) => {
            this.getReviewers({ first: 0, rows: 10 });
            this.toast.showSuccess('Removed from restricted list')
          })
        }
      }
    });

  }
  moveToWorkedTogetherList($event: Event, reviewer: Reviewer) {
    const [action, preposition] = reviewer.workedTogether ? ['remove', 'from'] : ['add', 'to'];
    const mesg = `You are about to ${action} ${reviewer.firstName} ${reviewer.lastName} ${preposition} your Worked Together list. Are you sure?`;
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message: mesg,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        if(reviewer.workedTogether){
          this.peopleService.removeFromWorkedTogetherList(reviewer._id).subscribe((resp: any) => {
            this.toast.showSuccess('Removed from Worked Together list');
            reviewer.workedTogether = false;
          })
        }else {
          this.peopleService.moveToWorkedTogetherList(reviewer._id).subscribe((response: any) => {
            this.toast.showSuccess('Moved to Worked Together list');
            reviewer.workedTogether = true;
          })
        }
      }
    });

  }
  approveReviewer($event: Event, reviewer: Reviewer) {
    const mesg = `You are about to approve ${reviewer.firstName} ${reviewer.lastName}. Are you sure?`
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message: mesg,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.userService.approveUser(reviewer._id).subscribe((response: any) => {
          this.getReviewers({ first: 0, rows: 10 });
          this.toast.showSuccess('Reviewer Approve Successfully')
        })
      }
    });
  }
  fireAndQuit($event: Event, reviewer: Reviewer) {
    const data = {
      heading: `You are about to fire ${reviewer.firstName} ${reviewer.lastName}`,
      from: 'fireAndQuit',
      textAreaHeading: 'Reason'
    }
    $event.stopPropagation();
      this.ref = this.dialogService.open(DeleteUserConfirmation, {
          data: data,
          header: 'Confirmation',
          width: '30vw',
          contentStyle: { overflow: 'auto' },
          breakpoints: {
              '960px': '75vw',
              '640px': '90vw'
          }
      });
      this.ref.onClose.subscribe((data: DeleteUserByFirm) => {
          if (data) {
             this.projectService.fireReviewerFromProject(reviewer._id, reviewer.positionId ? reviewer.positionId : '',data.reason,reviewer.isDRSSubmitted).subscribe((response: any) => {
              this.getReviewers({ first: 0, rows: 10 });
              this.toast.showSuccess('User Fire Successfully')
            }) 
          }
          
      });
  }
 
  showDeleteReviewerConfirmation($event:Event,reviewer:Reviewer) {
    const isArchive = this.selectedUserFilter && this.selectedUserFilter.value === 'archive';
    if(isArchive){
      this.selectedReviewer = reviewer;
      this.openUnarchiveModal = true;
      return;
    }
    const data = {
      heading: `Are you sure you want to ${!isArchive ? 'archive' : 'unarchive'}? ${reviewer.firstName} ${reviewer.lastName}`,
      from: 'deleteReviewer',
      textAreaHeading: 'Comment'
    }
    $event.stopPropagation();
      this.ref = this.dialogService.open(DeleteUserConfirmation, {
          data: data,
          header: 'Confirmation',
          width: '30vw',
          contentStyle: { overflow: 'auto' },
          breakpoints: {
              '960px': '75vw',
              '640px': '90vw'
          }
      });

      this.ref.onClose.subscribe((data: DeleteUserByFirm) => {
          
          if (data) {
            const payload: DeleteUserByFirm = {
              reason : data.reason,
              comments: data.comments,
              firm : this.userObject.firm._id
            }
            this.userService.deleteUserByFirm(reviewer._id, payload).subscribe((response: any) => {
              this.getReviewers({ first: 0, rows: 10 });
              this.toast.showSuccess('User archived successfully')
            })
          }
          
      });
  }

  async confirmUnarchiveUser(isConfirm = false): Promise<void> {
    if(!isConfirm){
      this.openUnarchiveModal = false;
      this.selectedReviewer = null;
      return;
    }
    await this.userService.restoreUserByFirm(this.selectedReviewer?._id || '');
    this.openUnarchiveModal = false;
    this.selectedReviewer = null;
    this.reloadReviewerTable();

  }

  isReviewerApproveDisable(reviewer: Reviewer) {
    const tenant = reviewer.tenants.find(tenant => tenant.firm === this.userObject.firm._id);
    return (tenant && tenant.reviewerStatus !== constants.userVerificationStatus.pendingAdminVerification);

  }

  openReviewerDialog(id:string){
    this.reviewerDetailDialogService.openDialog(id);
  }

  favouriteUser($event: Event, reviewer: Reviewer) {
    if(reviewer.isFavourite){
      this.removeAsFavourite(reviewer);
    }else{
      this.setAsFavourite(reviewer);
    }
  }

  removeAsFavourite(reviewer: Reviewer){
    this.peopleService.removeFromFavouriteList(this.userObject.firm._id,reviewer._id).subscribe((resp:any)=>{
      this.toast.showSuccess('Removed to Favourite List');
      reviewer.isFavourite = false;
    })
  }

  setAsFavourite(reviewer: Reviewer){
    const fav = { "favorite": reviewer._id };
    this.peopleService.addToFavouriteList(this.userObject.firm._id,fav).subscribe((resp:any)=>{
      this.toast.showSuccess('added to Favourite List');
      reviewer.isFavourite = true;
    })
  }

  goToProjectDetails(projectId:string){
    this.router.navigateByUrl(`/client/project/started/${projectId}/details`);
  }

  getPendingReviewers() {
    const offset = 0;
    const limit = 10;
    const queryParams: SearchReviewerQueryParams = {
      sortOrder: constants.sortingOrder.descending,
      offset: offset,
      limit: limit,
      searchOn: 'all',
    };
    this.peopleService.getPendingCandidateList(queryParams, this.userObject.firm._id).subscribe((response: SearchReviewerResponse) => {
      this.pendingCandidateButton.label = `PENDING CANDIDATES (${response.count})`;
    })
  }
}