<div>
    @if (selectedPools.length > 0) {
      <div>
        <p-floatLabel class="h-full">
          <strong>SELECTED POOLS:</strong> 
            {{getCommaSeparatedNamesOfSelectedPool()}}
        </p-floatLabel>
      </div>  
    }
    <div class="pt-10">
      <p-inputSwitch inputId="switch1" (onChange)="onToggleChange($event)" />
      <label class="pl-10" for="switch1">View My Talent Pools</label>
    </div>
    <div class="pt-10">
      <p-multiSelect 
          [options]="filteredPools" 
          [(ngModel)]="selectedPools" 
          optionLabel="name" 
          placeholder="Select talent pool"
          (onChange)="onChangeTanentPools()" />
    </div>
    
    <div class="flex justify-content-end pt-10">
      <p-button
        label="Done"
        size="large"
        [rounded]="true"
        [outlined]="true"
        (onClick)="closeDialog()"
        [ngStyle]="{'margin-right': '10px'}"      />
    </div>
</div>
  