<div class="grid grid-nogutter">
    <div class="p-0 col-12 mb-5">
        <h3 class="m-0 h4 mb-2">Payroll Reports</h3>
    </div>
    <div class="xl:col-3 lg:col-4 col-12 lg:pr-3 xl:pr-3 pr-0 mb-3">
        <p-multiSelect [options]="projects" [(ngModel)]="selectedProjects" optionLabel="codeName" placeholder="Project" (onChange)="onProjectSelectionChange($event)" [ngClass]="{'ng-dirty ng-invalid': showProjectSelectionError}"/>
        
        @if(showProjectSelectionError){
            <span class="p-error pl-1 text-xs">This is required</span>
        }
    </div>
    <div class="xl:col-3 lg:col-4 col-12 lg:pr-3 xl:pr-3 pr-0 mb-3">
        <p-multiSelect [options]="roles" [(ngModel)]="selectedRoles" optionLabel="roleType" placeholder="Role" (onChange)="onRolesSelectionChange($event)" />
    </div>
    <div class="xl:col-3 lg:col-4 col-12 lg:pr-3 xl:pr-3 pr-0 mb-3">
        <p-dropdown [options]="timezones" [(ngModel)]="selectedTimezone" optionLabel="val" [placeholder]="isDefaultTimeZone ? 'Default Timezone' : 'Timezone'" [disabled]="isDefaultTimeZone" />
    </div>
    <div class="xl:col-3 lg:col-4 col-12 lg:pr-0 xl:pr-0 pr-0 mb-3 flex align-items-center gap-3">
        <p-checkbox 
            [(ngModel)]="isDefaultTimeZone" 
            [binary]="true" 
            inputId="default-tz"
            (onChange)="onChangeDefaultTimezoneCheck($event)" />
        <label for="default-tz">Use Default Timezone</label>
    </div>
    <div class="xl:col-3 lg:col-4 col-12 lg:pr-3 xl:pr-3 pr-0 mb-3">
        <p-floatLabel class="h-full">
            <p-calendar 
                inputId="start-date-selector"
                [maxDate]="endDate"
                [(ngModel)]="startDate" />
            <label for="start-date-selector">Start Date</label>
        </p-floatLabel>
    </div> 
    <div class="xl:col-3 lg:col-4 col-12 lg:pr-3 xl:pr-3 pr-0 mb-3">
        <p-floatLabel class="h-full">
            <p-calendar 
                inputId="end-date-selector" 
                [(ngModel)]="endDate"/>
            <label for="end-date-selector">End Date</label>
        </p-floatLabel>
    </div>
    <div class="xl:col-3 lg:col-4 col-12 lg:pr-0 xl:pr-3 pr-0 mb-3">
        <p-multiSelect [options]="reviewers" [(ngModel)]="selectedReviewers" optionLabel="fullName" placeholder="Candidate" />
    </div>
    <div class="xl:col-3 lg:col-4 col-12 lg:pr-3 xl:pr-0 pr-0 mb-3 flex justify-content-start pl-0 lg:pl-3 xl:pl-0">
        <p-button label="GO" [rounded]="true" size="large" (click)="generateReport()"/>
    </div>

    <div class="p-0 col-12 mt-4">
        <p-table styleClass="p-datatable-sm p-datatable-sm--normal" [value]="payrollReports" [paginator]="true" [rows]="2" [tableStyle]="{ 'min-width': '50rem' }"
            [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
            autoLayout="true" [rows]="noOfRows">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="reviewerName">
                        Candidate <p-sortIcon field="reviewerName" />
                    </th>
                    <th pSortableColumn="projectName">
                        Project/Role <p-sortIcon field="projectName" />
                    </th>
                    <th pSortableColumn="totalHours">
                        Total Regular Hours <p-sortIcon field="totalHours" />
                    </th>
                    <th pSortableColumn="totalOT">
                        Total OT Hours <p-sortIcon field="totalOT" />
                    </th>
                    <th pSortableColumn="payrollTimezone || selectedTimezone?.val">
                        Payroll Timezone <p-sortIcon field="payrollTimezone || selectedTimezone?.val" />
                    </th>
                    <th style="width: 8%;">
                        Actions
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product>
                <tr>
                    <td>{{ product.reviewerName }}</td>
                    <td>{{ product.projectName }} / {{product.roleName}}</td>
                    <td>{{ product.totalHours }}</td>
                    <td>{{ product.totalOT }}</td>
                    <td>{{ product.payrollTimezone  || selectedTimezone?.val }}</td>
                    <td>
                        <div class="wrap-actions flex align-items-center gap-2">
                            <p-button 
                                type="button" 
                                pRipple 
                                [pRowToggler]="product" 
                                [text]="true"
                                [rounded]="true" 
                                [plain]="true" 
                                [icon]="product.showDetail ? 'pi pi-chevron-up' : 'pi pi-chevron-down'" 
                                class="row-toggler"
                                (click)="showDetail(product)" />
                        </div>
                        
                    </td>
                </tr>
                @if(product.showDetail){
                    <tr class="sub-grid">
                        <td class="wrapper-td surface-100" colspan="6">
                            @for (item of product.details | keyvalue: originalOrder; track item) {
                                <div>
                                    <span class="block font-bold my-2 text-left text-sm w-full">{{item.key}}</span>
                                    <p-table [value]="item.value"> 
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th pSortableColumn="code">
                                                    S.No <p-sortIcon field="code" />
                                                </th>
                                                <th pSortableColumn="name">
                                                    Time In <p-sortIcon field="name" />
                                                </th>
                                                <th pSortableColumn="date">
                                                    Time Out <p-sortIcon field="date" />
                                                </th>
                                                <th pSortableColumn="hours">
                                                    Duration <p-sortIcon field="hours" />
                                                </th>
                                                <th pSortableColumn="timezone">
                                                    Notes <p-sortIcon field="timezone" />
                                                </th>
                                                <th style="width: 8%;">
                                                    Timezone
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-productDetails let-rowIndex="rowIndex">
                                            <tr>
                                                <td>
                                                    {{rowIndex + 1}}
                                                </td>
                                                <td>
                                                    {{productDetails.start}}
                                                </td>
                                                <td>
                                                    {{productDetails.end}}
                                                </td>
                                                <td>
                                                    {{toHours(productDetails.duration || 0) }}
                                                </td>
                                                <td>
                                                    {{productDetails.notes || 'N/A'}}
                                                </td>
                                                <td>
                                                    {{productDetails.timezoneAbbrevation}}
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                            }
                        </td>
                    </tr>
                }
            </ng-template>
        </p-table>
    </div>
</div>