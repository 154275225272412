import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { AuthService } from '../../../../../shared/services/auth.service';
import { StorageService } from '../../../../../shared/services/storage.service';
import { ToastService } from '../../../../../shared/services/toast.service';
@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [
    FloatLabelModule,
    FormsModule,
    InputTextModule,
    CommonModule,
    ReactiveFormsModule,
    PasswordModule,
    ButtonModule,

  ],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ChangePasswordComponent implements OnInit {
  createForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private storageService: StorageService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.createForm = this.fb.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });

  }

  onSubmit(){
    let { currentPassword, newPassword, confirmPassword} =  this.createForm.value;
    this.createForm.reset({
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    })
    this.authService.changePassword(currentPassword, newPassword, confirmPassword).subscribe({
      next: (res) => {
        this.storageService.setUserToken(res);
        this.toastService.showSuccess(' Password changed successfully.');
      },
    });
  }
}
