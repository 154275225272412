import { Component, OnInit } from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { constants } from '../../../../shared/constants/constants';
@Component({
  selector: 'app-quit-position-modal',
  standalone: true,
  imports: [ButtonModule, RadioButtonModule, DynamicDialogModule],
  templateUrl: './quit-position-modal.component.html',
  styleUrl: './quit-position-modal.component.scss'
})
export class QuitPositionModalComponent implements OnInit {
  roleEndedInfo: any = {
    position: {},
    project: {},
    role: {}
  }
  presumptiveStatus = constants.presumptiveStatus;

  reasonTitle = '';
  endMessage = '';
  autoQuit = false;
  roleStarted = false;
  hasTsheet = false;
  constructor(private quitPositionModalRef: DynamicDialogRef, public config: DynamicDialogConfig) { }
  
  ngOnInit(): void {
    this.roleEndedInfo = this.config.data;
    this.roleStarted = this.roleEndedInfo.role.isStarted;
    this.hasTsheet = this.roleEndedInfo.position.tsheets && !!this.roleEndedInfo.position.tsheets.isEnabled;
  }

  confirm(isConfirm: boolean) {
    this.quitPositionModalRef.close(isConfirm);
  }
}
