import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { lastValueFrom } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Reviewer, ReviewersCount } from '../interfaces/reviewer.interface';
import { constants } from '../constants/constants';
import { TalentPool } from '../interfaces/talent-pool.interface';

@Injectable({
  providedIn: 'root'
})
export class ReviewerService {

  constructor(private http: HttpBaseService) { }

  getReviewersByProjectId(
    projectId: string, 
    roleId: string | null,
    limit: number = 0,
    offset: number = 0,
    sortOrder: string = constants.sortingOrder.descending,
    poolIds: Array<string> = [],
    reviewerType: string = '',
    searchText: string = '',
    type: string = ''
  ): Promise<{
    reviewers: Array<Reviewer>,
    role: string
}>{
    let url = `projects/${projectId}/reviewersForRole/${roleId}?limit=${limit}&offset=${offset}&sortOrder=${sortOrder}&noCache=${new Date().getTime()}`;
    poolIds.forEach(pool => {
      url += `&talentPoolIds=${pool}`;
    });
    if(reviewerType){
      url += `&reviewerType=${reviewerType}`;
    }
    if(searchText){
      url += `&searchText=${searchText}`;
    }
    if(type){
      url += `&type=${type}`;
    }
    return lastValueFrom(this.http.get(url));
  }

  getShortlistedReviewersByProjectId(
    projectId: string, 
    roleId: string | null,
    limit: number = 0,
    offset: number = 0,
    sortOrder: string = constants.sortingOrder.descending,
    poolIds: Array<string> = [],
    reviewerType: string = '',
    searchText: string = '',
    type: string = ''
  ): Promise<{
    reviewers: Array<Reviewer>,
    count: number
}>{
    let url = `projects/${projectId}/roles/${roleId}/reviewers?limit=${limit}&offset=${offset}&sortOrder=${sortOrder}&noCache=${new Date().getTime()}`;
    poolIds.forEach(pool => {
      url += `&talentPoolIds=${pool}`;
    });
    if(reviewerType){
      url += `&reviewerType=${reviewerType}`;
    }
    if(searchText){
      url += `&searchText=${searchText}`;
    }
    if(type){
      url += `&type=${type}`;
    }
    return lastValueFrom(this.http.get(url));
  }

  getOfferQueueReviewers(
    projectId: string, 
    roleId: string | null,
    limit: number = 0,
    offset: number = 0,
    sortOrder: string = constants.sortingOrder.descending,
    poolIds: Array<string> = [],
    reviewerType: string = '',
    searchText: string = '',
    type: string = ''
  ): Promise<{
    reviewers: Array<Reviewer>,
    role: string
}>{
    let url = `projects/${projectId}/roles/${roleId}/reviewers?limit=${limit}&offset=${offset}&sortOrder=${sortOrder}&noCache=${new Date().getTime()}`;
    poolIds.forEach(pool => {
      url += `&talentPoolIds=${pool}`;
    });
    if(reviewerType){
      url += `&reviewerType=${reviewerType}`;
    }
    if(searchText){
      url += `&searchText=${searchText}`;
    }
    if(type){
      url += `&type=${type}`;
    }
    return lastValueFrom(this.http.get(url));
  }

  getReviewersCount(
    projectId: string, 
    roles: Array<string> = [],
    poolIds: Array<string> = [],
    reviewerType: string = '',
    searchText: string = '',
    sortOrder: string = constants.sortingOrder.descending,
  ): Promise<Array<ReviewersCount>>{
    const params: any = {roles};
    if(searchText){
      params.params = {
        reviewerType: reviewerType || null,
        role: roles,
        searchText: searchText,
        sortOrder, 
        talentPoolIds: poolIds
      }
    }
    const url = `projects/${projectId}/roles/reviewersCounts`;
    return lastValueFrom(this.http.put(url, params));
  }
}
