import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { lastValueFrom } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Reviews } from '../interfaces/reviews.interface';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {

  constructor(private http: HttpBaseService) { }

getReviewsByProjectId(
    projectId: string
): Promise<Reviews>{
    const params = new HttpParams()
    .set('noCache', new Date().getTime());
    return lastValueFrom(this.http.get(`projects/${projectId}/finalReview`, { params }));
  }
}
