import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StopwatchService {
  private timer$!: Observable<number> | null;
  private timerSubscription: any;
  private stopwatchState$: BehaviorSubject<string>;
  private secondsCounter: number;
  intervalId: any | null = null;
  constructor() {
    this.stopwatchState$ = new BehaviorSubject('stopped');
    this.secondsCounter = 0;
  }


  getStopwatchState(): Observable<string> {
    return this.stopwatchState$.asObservable();
  }

  startTime(){
    this.intervalId = setInterval(() => {
      this.stopwatchState$.next('running');
    }, 1000);
  }
 
  stopTime(){
    clearInterval(this.intervalId);
    this.intervalId = null;
  }
}
