import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';

import { HeadingComponent } from '../../../../shared/components/heading/heading.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactInformationComponent } from './contact-information/contact-information.component';
import { Reviewer } from '../../../../store/reviewer/reviewer.interface';

@Component({
  selector: 'app-general',
  standalone: true,
  templateUrl: './general.component.html',
  styleUrl: './general.component.scss',
  imports: [
    CommonModule,
    ButtonModule,
    FloatLabelModule,
    CalendarModule,
    InputTextModule,
    DropdownModule,
    HeadingComponent,
    MultiSelectModule,
    FormsModule,
    ReactiveFormsModule,
    ContactInformationComponent,
  ],
})
export class GeneralComponent {
  constructor() {}

  onSubmit(updateReviewerPayload: Reviewer): void {
    console.log(updateReviewerPayload);
  }
}
