import { Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FloatLabelModule } from "primeng/floatlabel";
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { ReactiveFormsModule , FormsModule } from '@angular/forms';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { TableModule , TableRowCollapseEvent, TableRowExpandEvent } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ChipModule } from 'primeng/chip';
import { Project } from '../../project.interface';
import { Reviews } from '../../../../../../shared/interfaces/reviews.interface';
import { ReviewsService } from '../../../../../../shared/services/reviews.service';
import { DocumentService } from '../../../../../../shared/services/document-service';
import { RolesService } from '../../../../../../shared/services/roles.service';
import { Role } from '../../../../../../shared/interfaces/roles.interface';
import { HelperService } from '../../../../../../shared/services/helper.service';
import { ProjectService } from '../../../../../../shared/services/project.service';
import { CompanyService } from '../../../../../../shared/services/company.service';


interface Column {
  field: string;
  header: string;
}
@Component({
  selector: 'app-review',
  standalone: true,
  imports: [   
    DragDropModule,
    ReactiveFormsModule,
    OverlayPanelModule,
    CheckboxModule,
    DialogModule,
    CommonModule,
    TabViewModule,
    TableModule,
    InputNumberModule,
    FormsModule,
    IconFieldModule,
    InputIconModule,
    RadioButtonModule,
    ButtonModule,
    InputTextModule,
    InputSwitchModule,
    ChipModule,
    DropdownModule,
    FloatLabelModule,
],
templateUrl: './review.component.html',
styleUrl: './review.component.scss',
  styles: [
    `
      :host ::ng-deep {
        [pDraggable] {
          cursor: move;
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})


export class ReviewComponent implements OnInit {
  @Input()
  id: string | null = null;
  @Input()
  project: Project | null = null;
  @Output() 
  changeTab: EventEmitter<{index: number}> = new EventEmitter<{index: number}>();
  @Output()
  gotoRoleEditPage: EventEmitter<{roleId: string, index: number}> = new EventEmitter<{roleId: string, index: number}>();
  reviews: Reviews | null = null;
  roles: Array<Role> = [];
  pdfSource: {url: string} | null = null;
  roleDetailsDisplay!:any;
  roleDetails!: any;
  expandedRows:{ [key: string]: boolean }  = {};
  companies: any = [];

  constructor(
    private reviewService: ReviewsService,
    private documentService: DocumentService,
    private roleService: RolesService,
    public helperService: HelperService,
    private projectService: ProjectService,
    private companyService: CompanyService
  ){ }

  async ngOnInit() {
    this.getReviewDetailsByProjectId();
    this.getRolesByProjectId();
    this.companies = await this.companyService.getCompanies();
  }

  onRowExpand(event: TableRowExpandEvent) {
    this.projectService.getRoleDetails(this.id || '',event.data._id).subscribe((response)=>{
      this.roleDetails = response;
      this.roleDetailsDisplay = {
        jobType: this.roleDetails?.jobType,
        degrees: this.roleDetails?.degrees,
        graduate: this.roleDetails?.degrees.filter((degree: any) => degree.graduate),
        underGrad: this.roleDetails?.degrees.filter((degree: any) => !degree.graduate),
        skillsAndExperience: this.roleDetails?.criteria
        .flatMap((item: any) => item.labels.values)
        .filter((value: any) => value.selected)
        .map((value: any) => value.val),
        credential: this.roleDetails?.credential,
      }
    });
    this.expandedRows = {};
    this.expandedRows[event.data._id] = true;
  }
  onRowCollapse(event: TableRowCollapseEvent) {}

  async getReviewDetailsByProjectId(): Promise<void>{
    this.reviews = await this.reviewService.getReviewsByProjectId(this.id || '');
    this.reviews?.documents.forEach(document => {
      document.url = this.documentService.getDocumentPathById(document._id);
    })
  }

  async getRolesByProjectId(): Promise<void>{
    this.roles = await this.roleService.getRolesByProjectId(this.id || '');
  }

  viewDocument(templateId: string, documentId: string, documentName: string): void{
    this.documentService.getDocuSignDocument(templateId, documentId).subscribe({
      next: async (res) => {
        const url = URL.createObjectURL(new Blob([res]));
        const response = await fetch(url);
        const blob = await response.blob();
        const pdfContent = await this.documentService.getFileReaderObject(blob);

        const uri = `data:application/octet-stream,${window.escape(pdfContent)}` ;
        this.pdfSource = {url: uri};
        let downloadLink = document.createElement("a");
        downloadLink.href = uri;
        downloadLink.download = documentName.indexOf(".pdf")>-1?documentName:documentName+".pdf";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    });
  }

  getSearchMarketNames(searchMarkets: Array<any> = []): string{
    return this.getCommaSeparatedValueFromList(searchMarkets.map(market => market.name));
  }

  getBarMemberships(barMemberships: Array<any> = []): string{
    return this.getCommaSeparatedValueFromList(barMemberships.map(market => market.name));
  }

  getCommaSeparatedValueFromList(barMemberships: Array<string> = []): string{
    return barMemberships.join(', ').replace(/,([^,]*)$/, ' and$1')
  }

  filterListByPropertyAndValue(list: Array<any>, property: any, value: any): Array<any>{
    return list.filter(item => list[property] === value);
  }

  changeTabIndex(index: number): void{
    this.changeTab.emit({index});
  }

  gotoRoleEdit(index: number, roleId: string): void{
    this.gotoRoleEditPage.emit({index, roleId});
  }

  captionName(caption: any): string {
    return this.companies.filter((company: any) => company._id === caption)[0]?.name || caption
  }
}
