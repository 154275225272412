import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { UserService } from '../../../../../shared/services/user.service';
import { TableModule } from 'primeng/table';
import { FloatLabelModule } from 'primeng/floatlabel';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeadingComponent } from '../../../../../shared/components/heading/heading.component';
import { InputIconModule } from 'primeng/inputicon';
import { IconFieldModule } from 'primeng/iconfield';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
 DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmationDialogService } from '../../../../../shared/services/confirmation-dialog.service';
import { ToastService } from '../../../../../shared/services/toast.service';
import { CreateEditJobTypeModalComponent } from './modals/create/create-job-type.component';
import { JobTypeService } from '../../../../../shared/services/job-type.service';
import { TooltipModule } from 'primeng/tooltip';
import { TagModule } from 'primeng/tag';
@Component({
  selector: 'app-job-types',
  standalone: true,
  imports: [
    CheckboxModule,
    FloatLabelModule,
    DialogModule,
    ButtonModule,
    FormsModule,
    IconFieldModule,
    InputIconModule,
    HeadingComponent,
    InputTextModule,
    TableModule,
    CommonModule,
    ReactiveFormsModule,
    DynamicDialogModule,
    TooltipModule,
    TagModule
  ],
  templateUrl: './job-types.component.html',
  styleUrl: './job-types.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService, DynamicDialogRef, DynamicDialogConfig],
})
export class JobTypesComponent implements OnInit {
  jobTypes: any;
  createForm!: FormGroup;
  ref: DynamicDialogRef | undefined;
  user: any;
  row: any;
  constructor(
    private userService: UserService,
    public dialogService: DialogService,
    private confirmationDialogService: ConfirmationDialogService,
    private toastService: ToastService,
    public config: DynamicDialogConfig,
    private jobTypeService: JobTypeService
  ) {}

  ngOnInit() {
    this.user = this.userService.getSessionUser();
    this.getFirmJobType();
  }

  getFirmJobType() {
    this.jobTypeService.getFirmJobType(this.user.firm._id, true).subscribe({
      next: (jobTypes) => {
        this.jobTypes = jobTypes;
      },
    });
  }
  getModalConfig(modalType: string, data?: any) {
    return {
      header: modalType,
      width: '30vw',
      contentStyle: { overflow: 'hidden' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
      data: {
        ...data,
        buttonText: modalType,
      },
    };
  }

  onArchive($event: Event, jobTypes: any) {
    $event.stopPropagation();
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message:
        'You are about to ' + (!jobTypes.isArchived ? 'deactivate ' : 'activate ') + jobTypes.name + '.',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        if(!jobTypes.isArchived){
          this.jobTypeService.archiveJobType(jobTypes._id).subscribe({
            next: (JobTypes) => {
              this.getFirmJobType();
            },
          });
        } else {
          this.jobTypeService.unarchiveJobType(jobTypes._id).subscribe({
            next: (JobTypes) => {
              this.getFirmJobType();
            },
          });
        }
      },
    });
  }

  onDelete($event: Event, jobTypes: any) {
    $event.stopPropagation();
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message: 'Are you sure you want to delete ' + jobTypes?.name + '?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.jobTypeService.deleteJobType(jobTypes?._id).subscribe({
          next: (JobTypes) => {
            this.getFirmJobType();
          },
        });
      },
    });
  }

  showCreateJobTypeModal($event: Event, jobType?: any) {
    $event.stopPropagation();
    this.ref = this.dialogService.open(
      CreateEditJobTypeModalComponent,
      this.getModalConfig('Create')
    );

    this.ref.onClose.subscribe((data) => {
      if (data) {
        this.jobTypeService
          .createJobType({
            ...data,
            isGlobal: false,
            firm: this.user.firm._id,
          })
          .subscribe({
            next: (res) => {
              this.getFirmJobType();
              this.toastService.showSuccess(' Created successfully.');
            },
          });
      }
    });
  }

  showEditJobTypeModal($event: Event, jobType?: any) {
    $event.stopPropagation();
    this.ref = this.dialogService.open(
      CreateEditJobTypeModalComponent,
      this.getModalConfig('Edit', jobType)
    );

    this.ref.onClose.subscribe((data) => {
      if (data) {
        this.jobTypeService
          .updateJobType(jobType._id, {
            ...data,
            isGlobal: false,
            firm: this.user.firm._id,
            id: jobType._id
          })
          .subscribe({
            next: (res) => {
              this.getFirmJobType();
              this.toastService.showSuccess(' Updated successfully.');
            },
          });
      }
    });
  }
}
