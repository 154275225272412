import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {LayoutComponent} from '../../layout/layout.component'
@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [LayoutComponent,RouterOutlet],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {

}
