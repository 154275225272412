import { Component, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { RatingModule } from 'primeng/rating';
import { CommonModule } from '@angular/common';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormsModule } from '@angular/forms';
import { constants } from '../../../../../../../shared/constants/constants';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { FloatLabelModule } from 'primeng/floatlabel';
import { MultiSelectModule } from 'primeng/multiselect';
import { UserService } from '../../../../../../../shared/services/user.service';
import { UserClientVm } from '../../../../../../../store/user/user.interface';
import { ChipsModule } from 'primeng/chips';
import { ChipModule } from 'primeng/chip';
import { HelperService } from '../../../../../../../shared/services/helper.service';
import { DocumentService } from '../../../../../../../shared/services/document-service';
import { MessagesService } from '../../../../../../../shared/services/messages-service.service';
import { ToastService } from '../../../../../../../shared/services/toast.service';

@Component({
  selector: 'app-send-mail',
  standalone: true,
  imports: [CommonModule, DialogModule, ButtonModule, DynamicDialogModule, RatingModule, FormsModule, OverlayPanelModule, TooltipModule, FloatLabelModule, MultiSelectModule, ChipsModule, ChipModule],
  templateUrl: './send-mail.component.html',
  styleUrl: './send-mail.component.scss',
})
export class SendMailModalComponent implements OnInit {
  user!: UserClientVm;
  
  subject: string = '';
  emailContent: string = '';
  emailInfo: any = {};
  selectedProjectOwners: any;
  ccEmails: Array<string> = [];
  ccEmail = '';
  ccEmailError = '';
  fileLabel = 'No File Selected';
  isSubjectChanged = false;
  attachment: any = {}


  constructor(
    private rateProjectRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private userService: UserService,
    private helperService: HelperService,
    private documentService: DocumentService,
    private messageService: MessagesService,
    private toastService: ToastService
  ){

  }

  ngOnInit() {
    this.user = this.userService.getSessionUser();
    this.emailInfo = this.config.data;
    this.selectedProjectOwners = this.emailInfo.projectOwners.filter((owner: any) => owner.email === this.user.email);
    this.emailContent = `Hello ${this.emailInfo?.recipient}`
    if(!this.selectedProjectOwners || !this.selectedProjectOwners.length){
      const currentUserObject = {
        email: this.user.email
      };
      this.emailInfo.projectOwners.unshift(currentUserObject);
      this.selectedProjectOwners.push(currentUserObject);
    }
  }

  validateEmailAddress(minimumReq: number) {
    const regex = constants.emailRegex;
    let invalidCount = 0;

    if (minimumReq === 0 && this.ccEmails.length === 0) {
        return true;
    }

    this.ccEmails.forEach((item: any) => {
        if (!regex.test(item)) {
            invalidCount++;
        }
    });
    if (invalidCount > 0) {
        return false;
    }
    if (this.ccEmails.length <= 0) {
        return false;
    }
    return true;
  };

  validateSubmission(): boolean{

    if(!this.selectedProjectOwners.length){
      return false;
    }

    if(!this.subject){
      return false;
    }

    if(!this.validateEmailAddress(0)){
      return false;
    }

    if(this.attachment.sizeLimitExceeded){
      return false;
    }

    return true
  }

  onUploadChange(event: any){
    const files = Array.from(event.target.files);
    if (files.length) {
      let data = {
        sizeInBytes: 0,
        names: ''
      };

      files.forEach((file: any) => {
        data.sizeInBytes += file.size;
        data.names += file.name + '\n';
      });

      const sizeInMbs = Number(this.helperService.bytesToMb(data.sizeInBytes) || 0);

      if (sizeInMbs <= constants.emailAttachmentsLimitInMbs) {
        this.attachment.sizeLimitExceeded = false;
        this.attachment.files = files;
      } else {
        this.attachment.sizeLimitExceeded = true;
      }
    }
  }

  onSubjectChange(){
    this.isSubjectChanged = true;
  }

  async onSubmit(){
    if(!this.subject){
      this.isSubjectChanged = true;
    }
    if(!this.validateSubmission()){
      return;
    }
    let emailList = '';

    this.selectedProjectOwners.forEach((item: any) => {
      if (this.selectedProjectOwners.length > 1) {
          emailList += item.email + ',';
      } else {
          emailList += item.email;
      }
    });

    const replyToAdresses = this.selectedProjectOwners.map((emailItem: any) => {
        return emailItem.email;
    });

    const mailToLink = encodeURI('mailto:' + emailList + '?subject=RE: ' + this.subject);

    const con = '<br/><b>If you wish to contact us, please reply to this message or click <a style="text-decoration: none;" href="' + mailToLink + '" target="_top">Here.</a>';

    const ccAddresses = this.ccEmails.map((email) => {
        return email;
    });

    const postData: any = {
      type: '',
      content: this.emailContent,
      subject: this.subject,
      replyTo: replyToAdresses,
      to: '',
      project: this.emailInfo.projectId,
      cc: ccAddresses
    };

    switch (this.emailInfo.emailType) {
      case constants.emailType.project:
          postData.type = constants.emailType.project;
          postData.to = this.emailInfo.projectId;
          break;
      case constants.emailType.role:
          postData.type = constants.emailType.role;
          postData.to = this.emailInfo.roleId;
          break;
      default:
          postData.type = constants.emailType.individual;
          postData.to = this.emailInfo.reviewerId;
    }

    if (this.attachment.files && this.attachment.files.length > 0) {
      const option = {
        documentType: 'EMAIL_ATTACHMENT',
        projectId: this.emailInfo.projectId,
        isClientEmailUpload: true
      };
      this.documentService.uploadDocumentMultiple(this.attachment.files, option).subscribe({
        next: async (response) => {
          postData.documentIds = response.documentIds;
          postData.content = postData.content += con;
          await this.messageService.sendEmail(postData);
          this.toastService.showSuccess('Mail sent successfully');
          this.rateProjectRef.close(true);
        },
        error: (error) => {
          this.toastService.showError('Error attaching file')
        }
      })
    } else {
      postData.content = postData.content += con;
      await this.messageService.sendEmail(postData);
      this.toastService.showSuccess('Mail sent successfully');
      this.rateProjectRef.close(true);

    }
  }

}
