<div header="confirm" class="align-items-center">
    <p class="block mb-5 font-medium" style="font-size: 18px;">{{config.data.heading}}</p>
    
    <div class="col-12 p-0 mb-1">
        <p class="m-0 text-xsm text-black-alpha">{{config.data.textAreaHeading}} <span class="required">*</span></p>
    </div>
    <div class="flex align-items-center gap-3 mb-3">
        
        <textarea rows="3" [value]="endMessage" (input)="onValueChange($event)"  pInputText id="end-reason" class="flex-auto" autocomplete="off" ></textarea>
    </div>
   
    <div class="flex justify-content-end gap-2">
        <p-button label="Cancel" severity="secondary" (click)="closeDialog()" />
        <p-button [disabled]="!endMessage" severity="success" label="Approve" (click)="approve()" />
        <p-button [disabled]="!endMessage"  severity="danger" label="Deny" (click)="deny()" />
    </div>
</div>
