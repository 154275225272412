<div class="grid xl:w-12 w-full pt-4">
    <div class="lg:col-6 col-12">
        <ul class="list-none h-100 w-100 flex flex-column gap-3 p-3 m-0 p-0 wrap-edit-dashboard">
            <li class="cursor-pointer py-5 px-6 border-round-md text-xl font-bold uppercase flex flex-grow-1 align-items-center flex-nowrap card-body " (click)="goto('/reviewer/profile/general')">
                <span class="material-symbols-outlined">psychology</span>
                <span class="left-margin">General</span>
            </li>
        </ul>
    </div>

    <div class="lg:col-6 col-12">
        <ul class="list-none h-100 w-100 flex flex-column gap-3 p-3 m-0 p-0 wrap-edit-dashboard">
            <li class="cursor-pointer py-5 px-6 border-round-md text-xl font-bold uppercase flex flex-grow-1 align-items-center flex-nowrap card-body" (click)="goto('/reviewer/profile/education')">
                <span class="material-symbols-outlined">school</span>
                <span class="left-margin">Education</span>
            </li>
        </ul>
    </div>

    <div class="lg:col-6 col-12">
        <ul class="list-none h-100 w-100 flex flex-column gap-3 p-3 m-0 p-0 wrap-edit-dashboard">
            <li class="cursor-pointer py-5 px-6 border-round-md text-xl font-bold uppercase flex flex-grow-1 align-items-center flex-nowrap card-body" (click)="goto('/reviewer/profile/resume')">
                <span class="material-symbols-outlined">file_present</span>
                <span class="left-margin">Resume & Certifications</span>
            </li>
        </ul>
    </div>

    @if(credentials && credentials.length){
        <div class="lg:col-6 col-12">
            <ul class="list-none h-100 w-100 flex flex-column gap-3 p-3 m-0 p-0 wrap-edit-dashboard">
                <li class="cursor-pointer py-5 px-6 border-round-md text-xl font-bold uppercase flex flex-grow-1 align-items-center flex-nowrap card-body" (click)="goto('/reviewer/profile/credentials')">
                    <span class="material-symbols-outlined">password</span>
                    <span class="left-margin">Credentials</span>
                </li>
            </ul>
        </div>
    }

    @if(skillsAndExperience && skillsAndExperience.length){
        <div class="lg:col-6 col-12">
            <ul class="list-none h-100 w-100 flex flex-column gap-3 p-3 m-0 p-0 wrap-edit-dashboard">
                <li class="cursor-pointer py-5 px-6 border-round-md text-xl font-bold uppercase flex flex-grow-1 align-items-center flex-nowrap card-body" (click)="goto('/reviewer/profile/skills')">
                    <span class="material-symbols-outlined">settings_accessibility</span>
                    <span class="left-margin">Skills</span>
                </li>
            </ul>
        </div>
    }
</div>
