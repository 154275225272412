import { Component, OnInit } from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { constants } from '../../../../shared/constants/constants';
@Component({
  selector: 'app-role-completed-modal',
  standalone: true,
  imports: [ButtonModule, RadioButtonModule, DynamicDialogModule],
  templateUrl: './role-completed-modal.component.html',
  styleUrl: './role-completed-modal.component.scss'
})
export class RoleCompletedModalComponent implements OnInit {
  roleEndedInfo: any = {
    position: {},
    project: {},
    role: {}
  }
  presumptiveStatus = constants.presumptiveStatus;

  reasonTitle = '';
  endMessage = '';
  firmName = '';
  autoQuit = false;
  roleStarted = false;
  hasTsheet = false;
  constructor(private roleEndedModalRef: DynamicDialogRef, public config: DynamicDialogConfig) { }
  
  ngOnInit(): void {
    this.roleEndedInfo = this.config.data;
    this.hasTsheet = this.roleEndedInfo.position.tsheets && !!this.roleEndedInfo.position.tsheets.isEnabled;
    this.firmName = this.roleEndedInfo.position.firm && this.roleEndedInfo.position.firm.name || '';
    this.autoQuit = this.roleEndedInfo.position.quit && !this.roleEndedInfo.position.endedAcknowledgement;
    this.reasonTitle = `Message from ${this.firmName}`;
    this.endMessage = this.roleEndedInfo.position.endMessage || '';

  }

  confirm(isConfirm: boolean) {
    this.roleEndedModalRef.close(isConfirm);
  }
}
