import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectListComponent } from './project-listing/project-listing.component';
import { DetailsComponent } from './details/details.component';
import { CreateComponent } from './create/create.component';
const routes: Routes = [
  { path: '', redirectTo: 'started', pathMatch: 'full' },

      {
        path: 'started',
        component: ProjectListComponent,
      },
      {
        path: 'started/:projectId/details',
        component: DetailsComponent
      },
      {
        path: 'published',
        component: ProjectListComponent
      },
      {
        path: 'published/:projectId/details',
        component: DetailsComponent
      },
      {
        path: 'unpublished',
        component: ProjectListComponent
      },
      {
        path: 'unpublished/:projectId/details',
        component: DetailsComponent
      },
      {
        path: 'archived',
        component: ProjectListComponent
      },
      {
        path: 'archived/:projectId/details',
        component: DetailsComponent
      },
      {
        path: 'create',
        component: CreateComponent
      },
      {
        path: ':projectId/details',
        component: DetailsComponent
      },
      {
        path: ':projectId/edit',
        component: CreateComponent
      },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectRoutingModule { }
