<div>
    <form [formGroup]="timesheetEditorForm">
        <div class="grid mt-2">
            <div class="col-12 lg:col-12 xl:lg:col-6 pb-3 pt-0 pl-0 pr-0 xl:pr-2">
                @if(selectedRole && selectedRole.value){

                <div class="w-full mb-3">
                    <p-dropdown inputId="role" formControlName="selectedRole" [options]="roles" optionLabel="name"
                        placeholder="Select Role" />
                </div>
                }
                <div class="grid grid-nogutter">
                    <div class="p-0 pt-6 col-12 xl:col-12 lg:col-12">
                        <span class="h6">Time In</span>
                    </div>
                    <div class="p-0 pt-6 col-12 xl:col-6 lg:col-6">

                        <p-floatLabel>
                            <p-calendar formControlName="start_date" inputId="birth_date" placeholder="mm/dd/yy" />
                        </p-floatLabel>
                    </div>
                    <div class="p-0 mb-1 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-4 lg:pl-4">
                        <p-calendar inputId="calendar-timeonly" formControlName="start_time" [timeOnly]="true"
                            hourFormat="12" inline="true" styleClass="slider-style" />
                    </div>
                </div>
                @if(timesheetEditorForm && !timesheetEditorForm.value['isCurrentlyWorking']){
                <div class="grid grid-nogutter">
                    <div class="p-0 pt-6 col-12 xl:col-12 lg:col-12">
                        <span class="h6">Time Out</span>
                    </div>
                    <div class="p-0 pt-6 col-12 xl:col-6 lg:col-6">

                        <p-floatLabel>
                            <p-calendar formControlName="end_date" inputId="birth_date" placeholder="mm/dd/yy" />
                        </p-floatLabel>
                    </div>
                    <div class="p-0 mb-1 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-4 lg:pl-4">
                        <p-calendar inputId="calendar-timeonly" formControlName="end_time" [timeOnly]="true"
                            hourFormat="12" inline="true" styleClass="slider-style" />
                    </div>
                </div>
                }
                <div>
                    <div class="switch-w-label pt-2 mt-2 flex gap-2">
                        <label for="stfProject" class="line-height-2">Currently Working?</label>
                        <p-inputSwitch formControlName="isCurrentlyWorking" class="mpl-switch" inputId="stfProject"
                            ariaLabel="My Projects" />
                    </div>

                </div>

                <div class="pt-3">
                    <p-floatLabel>
                        <textarea id="notes" formControlName="notes" type="text" rows="5" pInputTextarea></textarea>
                        <label for="notes">Notes</label>
                    </p-floatLabel>
                </div>

                <div class="flex justify-content-end mt-3">
                    <p-button (click)="cancel(false)" class="mr-2" label="CANCEL" [outlined]="true" [rounded]="true"
                        size="large" />
                    <p-button label="SUBMIT" size="large" [rounded]="true" (onClick)="onSubmit()" />
                </div>
            </div>
        </div>

    </form>
</div>