import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class EditTemplateService {

    documentInfo: any = {};


    constructor() {}

    getDocumentInfoByKey(key: string){
        return this.documentInfo[key];
    }

    setDocumentInfoByKey(key: string, value: any){
         this.documentInfo[key] = value;
    }

    setDocumentInfo(value: any){
        this.documentInfo = value;
    }

    getDocumentInfo(){
        return this.documentInfo;
    }

}