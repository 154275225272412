import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import moment from 'moment';
import { constants } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(private http: HttpBaseService) { }

  isValidDate(dateString: string) {
    // First check for the pattern
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
      return false;
    }

    // Parse the date parts to integers
    let parts = dateString.split("/");
    let day = parseInt(parts[1], 10);
    let month = parseInt(parts[0], 10);
    let year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month === 0 || month > 12) {
        return false;
    }

    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
        monthLength[1] = 29;
    }

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  };

  isGreaterThanCurrentDate(dateString: string) {

    let currentDate = new Date();
    let inputDate = new Date(dateString);
    currentDate.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0);

    let isGreaterThanCurrentDate = false;
    if (inputDate >= currentDate) {
      isGreaterThanCurrentDate = true;
    }
    return isGreaterThanCurrentDate;
  };

  isLessThenEqualTo(dateOneString: string, dateTwoString: string){
    let isLessThan = false;
    let dateOne = new Date(dateOneString);
    let dateTwo = new Date(dateTwoString);

    dateOne.setHours(0, 0, 0, 0);
    dateTwo.setHours(0, 0, 0, 0);

    if(dateOne <= dateTwo){
        isLessThan = true;
    }
    return isLessThan;
  };

  isWithinSevenDaysOfCurrentDate(dateString: string) {

    let currentDate = new Date();
    let currentDatePlusSeven = new Date(currentDate);
    let inputDate = new Date(dateString);

    currentDatePlusSeven.setDate(currentDate.getDate() + constants.daysDiffrenceBetweenDate);
    currentDatePlusSeven.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0);


    let isWithinSevenDaysOfCurrentDate = false;
    if (inputDate >= currentDate && inputDate <= currentDatePlusSeven) {
      isWithinSevenDaysOfCurrentDate = true;
    }

    return isWithinSevenDaysOfCurrentDate;
  };

  isRoleStartWithinSevenDaysOfProjectStartDate(roleStartDateString: string, projectStartDate: string) {
      let projectStartDatePlusSeven = moment.utc(projectStartDate).add(constants.daysDiffrenceBetweenDate,'days');
      if(moment.utc(roleStartDateString,"MM/DD/YYYY").isSameOrAfter(moment.utc(projectStartDate),'day') &&
          moment.utc(roleStartDateString,"MM/DD/YYYY").isSameOrBefore(projectStartDatePlusSeven,'day') ){
            return true;
      }
      return false;
  };

  isWithinSevenDaysOfCurrentDate_inputDateObj(inputDateObject: string) {
    let currentDate = moment(new Date()).utc().startOf('day');
    let currentDatePlusSeven = moment(currentDate).add(constants.daysDiffrenceBetweenDate, 'days');
    let inputDate = moment(new Date(inputDateObject)).utc();

    let isWithinSevenDaysOfCurrentDate = false;
    if (inputDate >= currentDate && inputDate <= currentDatePlusSeven) {
      isWithinSevenDaysOfCurrentDate = true;
    }
    return isWithinSevenDaysOfCurrentDate;
  };
}
