
<!-- <p-dialog 
    header="Group Message" 
    [(visible)]="visible" 
    [modal]="true" 
    [breakpoints]="{'575px': '90vw' }" 
    [style]="{ width: '660px' }" 
    [draggable]="false" 
    [resizable]="false">
    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <span class="font-bold white-space-nowrap">
                Bulk Message
            </span>
        </div>
    </ng-template> -->
    <div class="grid grid-nogutter">
        <div class="col-12 mb-3">
            <!-- <p-floatLabel>
                <span styleClass="w-full" >'aarebba'</span>
                <label for="float-label">Send To</label>
            </p-floatLabel> -->
            <span class="font-normal">{{sendToHeading}}</span>
        </div>
        <!-- <div class="col-12 mb-3">
            <p-floatLabel>
                <p-chips id="chips" styleClass="w-full"/>
                <label for="chips">Users</label>
            </p-floatLabel>
        </div> -->
        <div class="col-12 mb-3">
            <p-floatLabel>
                <p-dropdown [options]="messageTemplates" (onChange)="onTemplateSelect($event)" optionLabel="subject" inputId="selectTemplate"
                     styleClass="w-full" />
                <label for="float-label">Select Template</label>
            </p-floatLabel>
        </div>
        <div class="col-12 mb-3">
            <p-floatLabel>
                <input pInputText [(ngModel)]="templateMessageSubject" id="subject" styleClass="w-full" />
                <label for="subject">Subject</label>
            </p-floatLabel>
        </div>
        <div class="col-12">
            <textarea rows="5" [(ngModel)]="templateMessageContent" cols="30" pInputTextarea placeholder="Write Message..." class="p-inputtextarea p-inputtext p-component p-element"></textarea>
        </div>
        <div class="footer">
            <p-button label="Send Message" (click)="sendNewMessage()" size="large" [rounded]="true" />
        </div>
    </div>
    <!-- <ng-template pTemplate="footer">
        <p-button label="Send Message" size="large" [rounded]="true" />
    </ng-template> -->
<!-- </p-dialog> -->