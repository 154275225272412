import { Component, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { RatingModule } from 'primeng/rating';
import { CommonModule } from '@angular/common';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-rate-project',
  standalone: true,
  imports: [CommonModule, DialogModule, ButtonModule, DynamicDialogModule, RatingModule, FormsModule],
  templateUrl: './rate-project-modal.component.html',
  styleUrl: './rate-project-modal.component.scss',
})
export class RateProjectComponent implements OnInit {
  visible: boolean = false;
  dummy!: any[];
  ratingInfo: any = {
    project: {},
    role: {}
  }

  rating = 3;

  constructor(private rateProjectRef: DynamicDialogRef, public config: DynamicDialogConfig){}

  ngOnInit() {
    this.ratingInfo = this.config.data;
  }

  onConfirm(isConfirm: boolean): void{
    this.rateProjectRef.close({isConfirm, rating: this.rating});
  }
}
