import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportsRoutingModule } from './reports-routing.module';

import { ReportService } from './reports.service';
import { HttpBaseService } from '../../../../shared/services/http-base.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ReportsRoutingModule
  ],
  providers: [
    HttpBaseService,
    ReportService
  ]
})
export class ReportsModule { }
