import { filter, Observable, take } from 'rxjs';
import { inject } from '@angular/core';
import { SearchMarketService } from '../../../../shared/services/search-market.service';
import { UserService } from '../../../../shared/services/user.service';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

export const SearchMarketResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<any> => 
  {

  const userService = inject(UserService);
  const searchMarketService = inject(SearchMarketService)
  const user = userService.getSessionUser();
  return searchMarketService.getAllFirmSearchMarket(user.firm._id)
  .pipe(
    filter<any>((searchMarket: any) => !!searchMarket),
    take(1)
  );
}