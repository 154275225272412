import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GeneralComponent } from './general/general.component';
import { EducationModule } from './education/education.module';
import { CredentialsModule } from './credentials/credentials.module';
import { SkillsComponent } from './skills/skills.component';
import { ResumeComponent } from './resume/resume.component';
import { ResumeGeneratorComponent } from './resume-generator/resume-generator.component';
import { EditProfileMainComponent } from './edit-profile-main/edit-profile-main.component';

const routes: Routes = [
  {
    path: 'main',
    component: EditProfileMainComponent
  },
  {
    path: '',
    redirectTo: 'general',
    pathMatch: 'full',
  },
  {
    path: 'general',
    component: GeneralComponent
  },
  {
    path: 'education',
    loadChildren: () => EducationModule
  },
  {
    path: 'credentials',
    loadChildren: () => CredentialsModule
  },
  {
    path: 'skills',
    component: SkillsComponent
  },
  {
    path: 'resume',
    component: ResumeComponent
  },
  {
    path: 'resume-generator',
    component: ResumeGeneratorComponent
  },
  {
    path: 'mobile',
    loadComponent: () =>
      import('./mobile/mobile.component').then(
        (c) => c.MobileComponent
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfileRoutingModule {}
