<div class="grid grid-nogutter mb-3">
  <div class="col-12 md:col-6 sm:col-12 sm:mb-2">
    <h6 class="m-0">{{ title }}</h6>
  </div>
</div>
<div class="projects-data">
  <div class="grid grid-nogutter">
    <div class="col-12 p-0">
      <p-table
        styleClass="p-datatable-sm p-datatable-sm--normal"
        [value]="dynamicCriteria"
        [paginator]="true"
        [rows]="10"
        [tableStyle]="{ 'min-width': '50rem' }"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
        autoLayout="true"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>Sr. No.</th>
            <th pSortableColumn="name">
              Criteria Name <p-sortIcon fiesortMode="multiple" ld="name" />
            </th>
            <th pSortableColumn="status">
              Status
            </th>
            <th style="width: 8%">Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-criteria let-rowIndex="rowIndex">
          <tr>
            <td>{{ rowIndex + 1 }}</td>
            <td>{{ criteria.name }}</td>
            <td>
              @if(!criteria.isArchived){
                <p-tag value="Active" [rounded]="true" class="pillActive"/>
              }
              @else {
                <p-tag value="Inactive" [rounded]="true" class="pillInactive"  />
              }
            </td>
            <td>
              <div class="wrap-actions flex align-items-center gap-2">
                <p-button
                  pTooltip="Edit" 
                  tooltipPosition="top"
                  [rounded]="true"
                  [text]="true"
                  icon="true"
                  (click)="goToEdit($event, criteria)"
                >
                  <span class="material-symbols-outlined"> edit </span>
                </p-button>
                @if(criteria?.firm) {
                  <p-button
                  [pTooltip]="criteria.isArchived? 'Activate': 'Deactivate'"
                  tooltipPosition="top"
                  (click)="onArchive($event, criteria)"
                  [rounded]="true"
                  [text]="true"
                  icon="true"
                  >
                  <span class="material-symbols-outlined"> archive </span>
                </p-button>
                <p-button
                  pTooltip="Delete" 
                  tooltipPosition="top"
                  [rounded]="true"
                  [text]="true"
                  icon="true"
                  (click)="onDelete($event, criteria)"
                >
                  <span class="red material-symbols-outlined"> delete </span>
                </p-button>
                }
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
