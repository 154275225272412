import { Component, Input, OnInit } from '@angular/core';
import { TableModule , TableRowCollapseEvent, TableRowExpandEvent } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { ProjectService } from '../../../../../../shared/services/project.service';
import { CommonModule } from '@angular/common';
import { ResponseProject } from './response.interface';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmationDialogService } from '../../../../../../shared/services/confirmation-dialog.service';
import { OfferService } from '../../../../../../shared/services/offers.service';
import { ToastService } from '../../../../../../shared/services/toast.service';
import { ReviewerDetailDialogService } from '../../../../../../shared/services/reviewer-detail-dialog.service';
import { UserClientVm } from '../../../../../../store/user/user.interface';
import { UserService } from '../../../../../../shared/services/user.service';
import { DialogService } from 'primeng/dynamicdialog';
import { OfferCancelConfirmationModalComponent } from './cancel-confirmation-modal/cancel-confirmation-modal.component';
import moment from 'moment';
import { CancelOfferModalComponent } from './cancel-modal/cancel-modal.component';
@Component({
  selector: 'app-response-tab',
  standalone: true,
  imports: [TableModule, ButtonModule, IconFieldModule, InputIconModule, CommonModule, TooltipModule],
  templateUrl: './response-tab.component.html',
  styleUrl: './response-tab.component.scss',
})
export class ResponseTabComponent implements OnInit {
  expandedRows = {};
  @Input() projectId!: string | null; 
  responseData: ResponseProject = [];
  user!: UserClientVm;

  constructor(private projectService: ProjectService, private offerService: OfferService, 
    private toastService: ToastService,
    private confirmationDialogService: ConfirmationDialogService,
    private reviewerDetailDialogService: ReviewerDetailDialogService, 
    private userService: UserService,
    public dialogService: DialogService) { }
  
  ngOnInit() {
    this.user = this.userService.getSessionUser();
    this.getProjectResponse();
  }
  
  onRowExpand(event: TableRowExpandEvent) { 
    this.getProjectResponse();
  }
  
  onRowCollapse(event: TableRowCollapseEvent) { }
  
  getProjectResponse(): void {
    if (this.projectId)
      this.projectService.getProjectResponse(this.projectId).subscribe({
        next: (resp: ResponseProject) => {
          this.responseData = resp;
          this.responseData.forEach((d) => {
            d.disableCancelOfferButton = true;
            d.isArchived = d.status === 'ARCHIVE';
            d.offers.lateOfferAcceptedReviewers = d.lateOfferAcceptedReviewers;
            d.offerList = [d.offers];
            d.offersCount = d.offers.viewed.length + d.offers.unViewed.length + d.offers.lateOfferAcceptedReviewers.length + d.offers.rejected.length;
            if (d.offers.viewed.length > 0 || d.offers.unViewed.length > 0) {
              let dispathched = d.offers.unViewed.filter(uv => {
                return uv.status === 'DISPATCHED';
              })
              let viewed = d.offers.viewed.filter(v => {
                return v.status === 'VIEWED';
              })
              if (dispathched.length || viewed.length) {
                d.disableCancelOfferButton = false;
              }
            }
          })
        }
      })
  }

  cancelOffer($event: Event, offer: any): void {
    const confirmModalRef = this.dialogService.open(CancelOfferModalComponent, {
      header: 'Cancel Offer',
      width: '50vw',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      },
      data: offer
    });

    confirmModalRef.onClose.subscribe(async (data: any) => {
      if(data){
        offer.reviewerEmail = offer.reviewer.email;
        offer.reviewer = offer.reviewer._id;
        await this.offerService.cancelOffer(offer);
        this.toastService.showSuccess('Offer Cancelled');
        this.getProjectResponse();
      }
    });
  }

  cancelAllOutstandingOffer($event: Event, selectedResponse: any): void {
    if(selectedResponse.disableCancelOfferButton){
      return;
    }
    const confirmModalRef = this.dialogService.open(OfferCancelConfirmationModalComponent, {
      header: 'Cancel All Offers',
      width: '50vw',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      },
      data: {
        role: selectedResponse
      }
    });

    confirmModalRef.onClose.subscribe(async (offerCancelInfo: any) => {
      if(offerCancelInfo && offerCancelInfo.isConfirm){
        try {
          await this.offerService.cancelAllOutstandingOffers(this.projectId || '', selectedResponse._id || '');
          this.toastService.showSuccess('Outstanding offers cancelled successfully')
        } catch (error) {
          this.toastService.showError('Error while cancelling outstanding offers.');
        }
        this.getProjectResponse();
      }
    });
  }

  getCancelButtonTooltip(selectedResponse: any): string{
    if(!selectedResponse.offerCancelledDates || !selectedResponse.offerCancelledDates.length){
      return 'Cancel Offer';
    }

    return `Last outstanding offers cancelled at: ${moment(selectedResponse.offerCancelledDates[selectedResponse.offerCancelledDates.length -1]).format('MM/DD/yyyy h:mm A')}`;
  }

  openReviewerDialog(id:string){
    this.reviewerDetailDialogService.openDialog(id);
  }

  downloadCSV(offers: any, roleName: string) {
    let fileData: Array<any> = [
      'FirstName,LastName,Email,Status\r\n'
    ];
    if (offers.viewed && offers.viewed.length) {
      offers.viewed.forEach((r: any) => {
        const d = r.reviewer.firstName + ',' + r.reviewer.lastName + ',' + r.reviewer.email + ',Viewed\r\n';
        fileData.push(d);
      });
    }
    if (offers.unViewed && offers.unViewed.length) {
      offers.unViewed.forEach((r: any) => {
        const d = r.reviewer.firstName + ',' + r.reviewer.lastName + ',' + r.reviewer.email + ',Unviewed\n';
        fileData.push(d);
      });
    }
    if (offers.rejected && offers.rejected.length) {
      fileData[0] = [
        'FirstName,LastName,Email,Status,Reason\r\n'
      ];
      offers.rejected.forEach((r: any) => {
        const d = r.reviewer.firstName + ',' + r.reviewer.lastName + ',' + r.reviewer.email + ',Rejected/Conflicted,' + (r.rejectReason || 'N/A') + '\r\n';
        fileData.push(d);
      });
    }
    if (offers.viewed.length || offers.unViewed.length || offers.rejected.length) {
      const blob = new Blob(fileData, { type: "text/plain;charset=utf-8" });
      window.saveAs(blob, roleName + "-offer-responses.csv");
    }
    else {
      this.toastService.showError('No Data available');
    }
  }
}
