<div class="w-full view-document-modal">
    <div class="flex flex-column gap-4">
        <div>
            <h4 class="h6 m-0">
                <b>Project Documents</b>
            </h4>
            <b>Please download, complete, and upload all of the following required documents prior to the start of your role.</b>
        </div>
        <div class="col-12 grid">
            <div class="list-none m-0 contact-list completed-wrapper">
                @for(key of documentKey(); track key) {
                    <div class="px-4 hover:bg-primary-50 hover:text-primary-900 flex list-item" >
                        <div style="display: flex; align-items: center;">
                            {{documentInfo?.position?.docs?.blank[key].docTitle}}
                        </div>
                        <p-button
                            [rounded]="true"
                            [text]="true"
                            icon="true"
                            (onClick)="getDocumentPathById(key)"
                            pTooltip="Download"
                            tooltipPosition="top">
                            <span class="material-symbols-outlined">download</span>
                        </p-button>
                        
                        <div class="px-4 hover:bg-primary-50 hover:text-primary-900 flex list-item download-wrapper" >
                            <!-- @if(documentInfo?.position?.docs?.filled && documentInfo?.position?.docs?.filled[user?._id || ''] && documentInfo?.position?.docs?.filled[user?._id || ''][key]){
                                <div class="px-4 hover:bg-primary-50 hover:text-primary-900 flex list-item" >
                                    
                                    
                                    
                                </div>
                            } -->
                            @if(documentInfo?.position?.docs?.blank[key]?.isUploading){
                                <div class="flex" ng-show="doc.isUploading">
                                    <div style="display: flex; align-items: center;">
                                        Uploading
                                    </div>
                                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem; padding: 10px;"></i>
                                </div>
                            }
                            @if(!documentInfo?.position?.docs?.blank[key]?.isUploading && (!documentInfo?.position?.docs?.filled?.[user?._id || '']?.[key])){
                                <div class="flex" ng-if="!vm.selectedPosition.docs.filled[vm.user.userId][docId]" ng-show="!doc.isUploading">
                                    
                                    <p-button
                                        [rounded]="true"
                                        [text]="true"
                                        icon="true"
                                        (onClick)="fileUpload.click()"
                                        pTooltip="Upload"
                                        tooltipPosition="top">
                                        <i class="pi pi-upload" style="font-size: 1rem;font-weight: bold;"></i>
                                    </p-button>
                                    <span  class="fa fa-upload">
                                        <input
                                            type="file"
                                            #fileUpload
                                            style="display: none"
                                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx,.txt,.csv"
                                            (change)="uploadDocument($event, documentInfo?.position?.docs?.blank[key]._id, documentInfo?.position?.docs?.blank[key])"
                                        />
                                    </span>
                                    <p-button
                                        [text]="true"
                                        icon="true"
                                        tooltipPosition="top" class="download-wrapper">
                                        <i class="pi pi-check-circle" style="color: gray;font-weight: bold; margin-top: 5px;"></i>
                                    </p-button>
                                
                                </div>
                            }
    
                            @if(documentInfo?.position?.docs?.filled?.[user?._id || '']?.[key]){
                                <div class="flex" ng-if="vm.selectedPosition.docs.filled[vm.user.userId][docId]">
                                    <p-button
                                        [text]="true"
                                        icon="true"
                                        (onClick)="getDocumentPathById(documentInfo?.position?.docs?.filled[user?._id || ''][key]._id)"
                                        [pTooltip]="'Download ' + documentInfo?.position?.docs?.filled[user?._id || ''][key].title"
                                        tooltipPosition="top" class="download-wrapper">
            
                                        <span class="material-symbols-outlined">download</span>
                                    </p-button>
                                    <p-button [rounded]="true" [text]="true" icon="true" (onClick)="deleteDocument(documentInfo?.position?.docs?.filled?.[user?._id || '']?.[key]._id)">
                                        <span class="red material-symbols-outlined">
                                            delete
                                        </span>
                                    </p-button>
                                    <p-button
                                        [text]="true"
                                        icon="true"
                                        tooltipPosition="top" class="download-wrapper">
                                        <i class="pi pi-check-circle" style="    color: green; font-weight: bold; margin-top: 5px;"></i>
                                    </p-button>
                                </div>
                            }
                        </div>
                    </div>
                    <p class="instructions-wrapper">
                        {{documentInfo?.position?.docs?.blank[key].userTitle}}
                    </p>
                    
                    <p-divider />
                }
            </div>
        </div>
    </div>
</div>
