import {SessionUser} from './user.interface';
import {createReducer, on, ActionReducer, INIT, UPDATE} from '@ngrx/store';
import {setSessionUser,removeSessionUser} from './user.action'

export const initialSessionUser : SessionUser|{} = {} ;

export const sessionUserReducer = createReducer(
    initialSessionUser,
    on(setSessionUser,(newUserObject:SessionUser|{},OldUserObject:SessionUser|{})=>{
        return {...OldUserObject,...newUserObject};
    }),
    on(removeSessionUser,(newUserObject:SessionUser|{},OldUserObject:SessionUser|{})=>{
      return {};
  })
)
export const metaReducerLocalStorage = (reducer: ActionReducer<any>): ActionReducer<any> => {
    return (state, action) => {
      if (action.type === INIT || action.type == UPDATE) {
        const storageValue = localStorage.getItem("state");
        if (storageValue) {
          try {
            return JSON.parse(storageValue);
          } catch {
            localStorage.removeItem("state");
          }
        }
      }
      const nextState = reducer(state, action);
      localStorage.setItem("state", JSON.stringify(nextState));
      return nextState;
    };
  };