<div class="align-items-center">

    @if(roleEndedInfo.position.presumptive && roleEndedInfo.position.presumptive.status === presumptiveStatus.DENIED){
        <div class="col-12 mb-3 p-0">
            Placement Update
        </div>
    }

    @if(!roleEndedInfo.position.presumptive || (roleEndedInfo.position.presumptive && roleEndedInfo.position.presumptive.status !== presumptiveStatus.DENIED)){
        <div class="col-12 mb-3 p-0">
            Role Ended
        </div>
    }

    @if(roleEndedInfo.position.presumptive && roleEndedInfo.position.presumptive.status === presumptiveStatus.DENIED){
        <div class="col-12 mb-3 p-0">
            <p>Thank you for submitting your profile for project: <b>{{roleEndedInfo?.project?.codeName}}</b>. Unfortunately the client will not be needing you on this project at this time.</p>
        </div>
    }
    @if(!roleEndedInfo.position.presumptive || (roleEndedInfo.position.presumptive && roleEndedInfo.position.presumptive.status !== presumptiveStatus.DENIED)){
        <div class="col-12 mb-3 p-0">
            <p>Your role: <b>{{roleEndedInfo?.role?.roleType}}</b> on project <b>{{roleEndedInfo?.project?.codeName}}</b> has been cancelled. Please click the "OK" button to begin receiving offers for new projects.</p>
        </div>
    }

    @if(endMessage.length){
        <div class="col-12 mb-3 p-0">
            <p><b> {{reasonTitle}} </b> <br /> {{endMessage}}</p>
        </div>
    }

    <div class="flex justify-content-end gap-2">
        <p-button label="OK" (click)="confirm(true)" />
    </div>
</div>
