import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { UserService } from './user.service';
import { HttpBaseService } from './http-base.service';


@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(private http: HttpBaseService, private userService:UserService) {
  }

  // Helper function to get userId from cookies
  private getUserId(): string {
    return this.userService.getSessionUser()._id;
  }

  // Create a message
  createMessage(msgInfo: any): Observable<any> {
    const userId = this.getUserId();
    return this.http.post(`my/messages?sessionUserId=${userId}`, msgInfo);
  }

  // Get all messages
  getAllMessages(ignoreLoader: boolean = false): Observable<any> {
    const userId = this.getUserId();
    const params = new HttpParams().set('ignoreLoader', ignoreLoader.toString());
    return this.http.get(`my/messages?sessionUserId=${userId}`, { params });
  }

  // Get paginated messages
  getMessagesPaginated(offset: number, limit: number): Observable<any> {
    const userId = this.getUserId();
    return this.http.get(`my/messages`, {
      params: new HttpParams()
        .set('sessionUserId', userId)
        .set('offset', offset.toString())
        .set('limit', limit.toString())
    });
  }

  // Get message by ID
  getMessageById(msgId: string): Observable<any> {
    return this.http.get(`my/messages/${msgId}`);
  }

  // Delete message by ID
  deleteMessageById(msgId: string): Observable<any> {
    const userId = this.getUserId();
    return this.http.delete(`my/messages/${msgId}?sessionUserId=${userId}`);
  }

  // Update message by ID
  updateMessageById(msgInfo: any, msgId: string): Observable<any> {
    return this.http.put(`my/messages/${msgId}`, msgInfo);
  }

 
  // Send email
  sendEmail(postData: any): Promise<any> {
    const userId = this.getUserId();
    return lastValueFrom(this.http.post(`my/messages/sendemail?sessionUserId=${userId}`, postData));
  }

  // Create message template
  createMessageTemplate(template: any): Observable<any> {
    const userId = this.getUserId();
    return this.http.post(`chatMessageTemplate?sessionUserId=${userId}`, template);
  }

  // Update message template
  updateMessageTemplate(id: string, template: any): Observable<any> {
    return this.http.put(`chatMessageTemplate/${id}`, template);
  }

  // Delete message template
  deleteMessageTemplate(msgId: string): Observable<any> {
    return this.http.delete(`chatMessageTemplate/${msgId}`);
  }

  // Get all message templates
  getAllMessageTemplates(): Observable<any> {
    const userId = this.getUserId();
    return this.http.get(`chatMessageTemplate?sessionUserId=${userId}`);
  }

  // Mark my messages as read
  markMyMessagesRead(firmId: string): Observable<any> {
    const userId = this.getUserId();
    return this.http.put(`my/readAllMessages`, { userId, firmId });
  }

  // Mark firm users' messages as read
  markFirmUsersMessagesRead(projectId: string, roleId: string, firmId: string, discussionId: string): Observable<any> {
    const userId = this.getUserId();
    return this.http.put(`my/readMessageForFirmUsers`, { userId, projectId, roleId, firmId, discussionId });
  }
  getReviewerUnReadMessages(firmId: string): Observable<any> {
    return this.http.get(`firebaseCloud/getReviewerCount?firmId=${firmId}`);
  }

sendMessage(data: any): Observable<any> {
  return this.http.post(`firebaseCloud/sendMessage`, data);
}
}
