import { Component, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DocumentService } from '../../../../../../../shared/services/document-service';
import { DividerModule } from 'primeng/divider';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-view-documents-modal',
  standalone: true,
  imports: [ButtonModule,FormsModule, RadioButtonModule, DropdownModule, DividerModule, TooltipModule],
  templateUrl: './view-reviewer-documents-modal.component.html',
  styleUrl: './view-reviewer-documents-modal.component.scss'
})
export class ViewReviewerDocumentsModalComponent implements OnInit {
  selectedRole : any;
  reviewerInfo: any = {
  }; 
  constructor(
    public config: DynamicDialogConfig, 
    private documentService: DocumentService, 
  ) {}

  ngOnInit(): void {
    this.reviewerInfo = {...this.config?.data};
  }

  getDocumentPathById(docId: string){
    window.open(this.documentService.getDocumentPathById(docId) || '', '_blank');
  }

  documentKey(): Array<string>{
    return Object.keys(this.reviewerInfo.docs.blank);
  }
}
