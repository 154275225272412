<div class="grid grid-nogutter">
    <div class="p-0 col-12 mb-5">
        <h3 class="m-0 h4 mb-2">Candidates Report</h3>
    </div>
    <div class="pt-0 pb-3 lg:pb-0 pl-0 xl:col-3 lg:col-3 col-12 lg:pr-3 xl:pr-3 pr-0">
        <p-multiSelect [options]="projects" [(ngModel)]="selectedProjects" optionLabel="codeName" placeholder="Project" (onChange)="onProjectSelectionChange($event)" [ngClass]="{'ng-dirty ng-invalid': showProjectSelectionError}"/>
        
        @if(showProjectSelectionError){
            <span class="p-error pl-1 text-xs">This is required</span>
        }
    </div>
    <div class="pt-0 pb-3 lg:pb-0 pl-0 xl:col-3 lg:col-3 col-12 pr-0">
        <p-multiSelect [options]="roles" [(ngModel)]="selectedRoles" optionLabel="roleType" placeholder="Role" (onChange)="onRolesSelectionChange($event)" />
    </div>
    <div class="pt-0 xl:pt-0 lg:pt-3 pb-3 lg:pb-0 pl-3 xl:col-3 lg:col-3 col-12 lg:pr-3 xl:pr-0 pr-0">
        <div class="switch-w-label pt-3 pt-0  flex gap-2">
            <p-inputSwitch [(ngModel)]="includePendingReviewers"
            class="mpl-switch" inputId="stfProject" ariaLabel="My Projects" />
            <label for="stfProject" class="line-height-2 white-space-normal text-sm">Include Pending Candidates</label>
        </div>
    </div>
    <div class="pt-0 xl:pt-0 lg:pt-3 pb-3 lg:pb-0 pl-3 xl:col-3 lg:col-3 col-12 lg:pr-3 xl:pr-0 pr-0 flex justify-content-end gap-2">
        <p-button label="SEARCH" [rounded]="true" size="large" (click)="getReviewers()"/>
        <p-button label="EXPORT" [rounded]="true" class="material-symbols-outlined" icon="true" (click)="op.toggle($event)" >
            <i class="material-symbols-outlined">
                arrow_downward
            </i>
        </p-button>
        <p-button label="SHARE" [outlined]="true" [rounded]="true" size="large" (click)="displayShareReportOption()"/>
    </div>
    <div class="col-12 mt-3">
        @if(shareReport){
        <div class="grid grid-nogutter">
                <div class="col-12 lg:col-11 xl:col-11">
                    <p-chips [(ngModel)]="emails" styleClass="flex w-full"/>
                    @if(!checkEmailListsAreValid()){
                        <span class="p-error pl-1 text-xs block">Invalid email address</span>
                    }
                </div>
                <div class="col-12 lg:col-1 xl:col-1 pl-3 flex justify-content-end pt-2 xl:pt-0 lg:pt-0">
                    <p-button label="SEND" [rounded]="true" size="large" (click)="sendMail()"/>
                </div>
        </div>
        }
    </div>

    <div class="p-0 col-12 mt-4">
        <p-table styleClass="p-datatable-sm p-datatable-sm--normal" [value]="reviewersList" [paginator]="true" [rows]="2" [tableStyle]="{ 'min-width': '50rem' }"
            [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
            autoLayout="true" [rows]="noOfRows">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="code">
                        First Name <p-sortIcon field="code" />
                    </th>
                    <th pSortableColumn="name">
                        Last Name <p-sortIcon field="name" />
                    </th>
                    <th pSortableColumn="date">
                        Email <p-sortIcon field="date" />
                    </th>
                    <th pSortableColumn="hours">
                        Primary Phone <p-sortIcon field="hours" />
                    </th>
                    <th pSortableColumn="timezone">
                        Project <p-sortIcon field="timezone" />
                    </th>
                    <th pSortableColumn="role" >
                        Role <p-sortIcon field="role" />
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-reviewer>
                <tr>
                    <td>{{ reviewer.firstName }}</td>
                    <td>{{ reviewer.lastName }}</td>
                    <td>{{ reviewer.email }}</td>
                    <td>{{ reviewer.phone }}</td>
                    <td>{{ reviewer.project }}</td>
                    <td>{{ reviewer?.role?.roleType }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-overlayPanel #op>
    <div class="wrap-list">
        <ul class="m-0 list-none cursor-pointer">
            <li (click)="onExportCSV()" class="mb-2">Export CSV</li>
            <li (click)="onExportPDF()">Export PDF</li>
        </ul>
    </div>
</p-overlayPanel>


