import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { SearchReviewerQueryParams} from '../../modules/client/dashboard/people/people.interface';
import { UserService } from './user.service';
import { HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { TalentPool } from '../interfaces/talent-pool.interface';

@Injectable({
  providedIn: 'root'
})
export class TalentPoolService {

  constructor(private http: HttpBaseService, private userService: UserService) {

  }

  getPoolsList(): Promise<Array<TalentPool>> {
    const user = this.userService.getSessionUser();

    const params = new HttpParams()
    .set('firmId', user.firm._id,) // need to bind it
    .set('noCache', new Date().getTime());
    const url = `talentPool/getPoolsList`
    return lastValueFrom(this.http.get(url, { params }));
  }

}
