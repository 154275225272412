import {Reviewer} from './reviewer.interface';
import {createReducer, on, ActionReducer, INIT, UPDATE} from '@ngrx/store';
import {removeReviewer, setReviewer} from './reviewer.action'

export const initialReviewer : Reviewer|{} = {} ;

export const reviewerReducer = createReducer(
    initialReviewer,
    on(setReviewer,(OldUserObject:Reviewer|{},newUserObject:Reviewer|{})=>{
        return newUserObject;
    }),
    on(removeReviewer,(newUserObject:Reviewer|{},OldUserObject:Reviewer|{})=>{
      return {};
    })
)

export const metaReducerLocalStorage = (reducer: ActionReducer<any>): ActionReducer<any> => {
    return (state, action) => {
      if (action.type === INIT || action.type == UPDATE) {
        const storageValue = localStorage.getItem("state");
        if (storageValue) {
          try {
            return JSON.parse(storageValue);
          } catch {
            localStorage.removeItem("state");
          }
        }
      }
      const nextState = reducer(state, action);
      localStorage.setItem("state", JSON.stringify(nextState));
      return nextState;
    };
  };