<div class="grid grid-nogutter mb-3">
  <div class="col-12 md:col-6 sm:col-12 sm:mb-2">
    <h1 class="h4 m-0">Job Types</h1>
  </div>
  <div
    class="col-12 md:col-6 sm:col-12 flex md:justify-content-end justify-content-between mt-3 md:mt-0 align-items-start sm:align-items-center md:gap-4 gap-0"
  >
    <p-button
      label="CREATE"
      [rounded]="true"
      icon="pi pi-plus"
      size="large"
      (onClick)="showCreateJobTypeModal($event)"
    />
  </div>
</div>
<div class="projects-data">
  <div class="grid grid-nogutter">
    <div class="col-12 p-0">
      <p-table
        styleClass="p-datatable-sm p-datatable-sm--normal"
        [value]="jobTypes"
        [paginator]="true"
        [rows]="5"
        [tableStyle]="{ 'min-width': '50rem' }"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
        autoLayout="true"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>Sr. No.</th>
            <th pSortableColumn="name">
              Name <p-sortIcon sortMode="multiple" field="name" />
            </th>
            <th pSortableColumn="description">
              Description <p-sortIcon sortMode="multiple" field="description" />
            </th>
            <th pSortableColumn="status">
              Status
            </th>
            <th style="width: 8%">Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-jobType let-rowIndex="rowIndex">
          <tr>
            <td>{{ rowIndex + 1 }}</td>
            <td>{{ jobType.name }}</td>
            <td>{{ jobType.description }}</td>
            <td>
              @if(!jobType.isArchived) {
                <p-tag value="Active" [rounded]="true" class="pillActive" />
              }
              @else {
                <p-tag value="Inactive" [rounded]="true" class="pillInactive" />
              }
            </td>
            <td>
              <div class="wrap-actions flex align-items-center gap-2">
                <p-button
                  pTooltip="Edit" 
                  tooltipPosition="top"
                  [rounded]="true"
                  [text]="true"
                  icon="true"
                  (click)="showEditJobTypeModal($event, jobType)"
                >
                  <span class="material-symbols-outlined"> edit </span>
                </p-button>
                <p-button
                [pTooltip]="!jobType.isArchived? 'Deactivate': 'Activate'"
                tooltipPosition="top"
                (click)="onArchive($event, jobType)"
                [rounded]="true"
                [text]="true"
                icon="true"
                >
                <span class="material-symbols-outlined"> archive </span>
              </p-button>
              <p-button
                pTooltip="Delete" 
                tooltipPosition="top"
                [rounded]="true"
                [text]="true"
                icon="true"
                (click)="onDelete($event, jobType)"
              >
                <span class="red material-symbols-outlined"> delete </span>
              </p-button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
