import { Component, OnInit } from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { constants } from '../../../../shared/constants/constants';
@Component({
  selector: 'app-role-ended-modal',
  standalone: true,
  imports: [ButtonModule, RadioButtonModule, DynamicDialogModule],
  templateUrl: './role-ended-modal.component.html',
  styleUrl: './role-ended-modal.component.scss'
})
export class RoleEndedModalComponent implements OnInit {
  roleEndedInfo: any = {
    position: {},
    project: {},
    role: {}
  }
  presumptiveStatus = constants.presumptiveStatus;
  firmName ='';
  reasonTitle = '';
  endMessage = '';
  constructor(private roleEndedModalRef: DynamicDialogRef, public config: DynamicDialogConfig) { }
  
  ngOnInit(): void {
    this.roleEndedInfo = this.config.data;
    this.firmName = this.roleEndedInfo.position.firm && this.roleEndedInfo.position.firm.name || ''
    this.reasonTitle = `Message from ${this.firmName}`;
    this.endMessage = this.roleEndedInfo.position.endMessage || '';
  }

  confirm(isConfirm: boolean) {
    this.roleEndedModalRef.close(isConfirm);
  }
}
