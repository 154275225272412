import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectRoutingModule } from './project-routing.module';
import { PipesModule } from '../../../../shared/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    ProjectRoutingModule,
    PipesModule
  ]
})
export class ProjectModule { }
