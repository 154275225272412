<div class="align-items-center cancel-confirmation-container">
    <div class="col-12 mb-3 p-0">
        <div class="w-full flex flex-column gap-3 pb-3">
            <div class="align-items-center flex justify-content-between">
                <div class="flex align-items-center gap-4">
                    <p> You are about to cancel all outstanding offers on
                        {{cancelOfferInfo.role.roleType}} remove all
                        {{cancelOfferInfo.role.unfilledPositions}} unfilled positions
                    </p>
                </div>
            </div>
            <div class="flex gap-1">
                Are you sure you want to continue?
            </div>            
        </div>
    </div>
    <div class="flex justify-content-end gap-2">
        <p-button label="Yes" [size]="'large'" styleClass="teal" (click)="onConfirm(true)" />
        <p-button label="No"  [size]="'large'" styleClass="red-background" (click)="onConfirm(false)" />
    </div>
</div>
