import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReviewerDashboardComponent } from './reviewer.component';
import { DashboardReviewerComponent } from './reviewer-dashboard/reviewer-dashboard.component';
import { ProfileModule } from './profile/profile.module';
import { MessagesComponent } from './messages/messages.component';
import { PastProjectsComponent } from './past-projects/past-projects.component';
import { ReviewerSettingComponent } from './reviewer-setting/reviewer-setting.component';
import { InvoiceGeneratorComponent } from './invoice-generator/invoice-generator.component';

const routes: Routes = [
  {
    path: '',
    component: ReviewerDashboardComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },

      {
        path: 'dashboard',
        component: DashboardReviewerComponent
      },
      {
        path: 'profile',
        loadChildren: () => ProfileModule
      },
      {
        path: 'messages',
        component: MessagesComponent
      },
      {
        path: 'past-projects',
        component: PastProjectsComponent
      },
      {
        path: 'settings',
        component: ReviewerSettingComponent
      },
      {
        path: 'timekeeping',
        component: InvoiceGeneratorComponent
      },
      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReviewerRoutingModule { }
