<div class="w-full view-docusign-document-modal">
    <div class="grid grid-nogutter">
        <div class="col-12 p-0 flex justify-content-between align-items-center mt-3">
            <p>Do you want to permanently add this document to the template or only for this project?</p>
        </div>
    </div>
    <p-divider />
    <div style="display: flex;">
        <span style="margin-left: auto;">
            <p-button label="NO" size="large" [rounded]="true"
            (onClick)="closeDialog()" />
            <p-button label="YES" size="large" [rounded]="true"
                (onClick)="closeDialog(true)" />
        </span>
    </div>
</div>
