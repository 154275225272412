import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { RouterOutlet } from '@angular/router';
import { CommonModule, JsonPipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from '../../shared/services/storage.service';
import { PingService } from '../../shared/services/ping.service';
@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [HeaderComponent, SidenavComponent, RouterOutlet, CommonModule],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent implements OnInit {

  constructor(private cookieService:CookieService, private storageService: StorageService, 
    private pingService:PingService) { }

  isMobile = this.cookieService.get('isMobile');
  isSideNavOpen!: boolean;

  ngOnInit(): void {
    this.isSideNavOpen = JSON.parse(this.isMobile)? false: this.storageService.getSideNavToggle();
    this.pingService.startPinging();
  }

  onToggleSideNav(): void {
    this.isSideNavOpen = !this.isSideNavOpen;
    if(!JSON.parse(this.isMobile)){
      this.storageService.setSideNavToggle(this.isSideNavOpen);
    }
  }

  ngOnDestroy(): void{
    this.pingService.stopPinging();
  }
}
